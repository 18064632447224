import React from "react";

import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import Breadcrumb, { BreadcrumbItem } from "@coninsa-s2/breadcrumb";
import Heading from "@coninsa-s2/heading";

export default function RentAdvance() {
  const breadcrumbItems = [
    {
      label: "Inicio",
      url: "/terminos-y-condiciones",
    },
    {
      label: "Términos y condiciones arrendamientos",
      url: "/terminos-y-condiciones/arrendamientos",
    },
    {
      label: "Anticipo de renta",
    },
  ];

  return (
    <Section>
      <Container>
        <div className="mb-8">
          <Breadcrumb>
            {breadcrumbItems.map((item) => (
              <BreadcrumbItem>
                {item.url && <a href={item.url}>{item.label}</a>}
                {!item.url && item.label}
              </BreadcrumbItem>
            ))}
          </Breadcrumb>
        </div>
        <h3 className="mb-7">
          <span className="text-lg text-s2-dark-blue lg:text-[22px]">
            Términos y condiciones{" "}
          </span>
          <Heading size="2xl">Anticipo de renta</Heading>
        </h3>

        <div className="prose">
          <p>
            El Adelanto de Cánones de arrendamiento es un producto financiero
            que realiza Avanto Tech S.A.S. en alianza con Coninsa Inmobiliaria,
            que trae hasta 6 de sus cánones de arrendamiento futuros a valor
            presente. Descontando de allí los gastos mensuales recurrentes que
            tiene el inmueble como, por ejemplo, cuotas de administración de
            propiedad horizontal (en caso de que sean pagadas por la
            inmobiliaria) y comisión de administración de la inmobiliaria.
          </p>

          <div>
            <h4>Requisitos:</h4>
            <ul>
              <li>
                Tener un contrato de arrendamiento vigente a través de Coninsa
                Inmobiliaria.
              </li>
              <li>
                Tener un contrato de arrendamiento vigente a través de Coninsa
                Inmobiliaria.
              </li>
              <li>
                Si el inmueble tiene hipoteca o leasing, este debe estar al día
                en los pagos de la obligación.
              </li>
              <li>
                El contrato de arrendamiento debe tener más de un (1) mes
                transcurrido desde su inicio.
              </li>
              <li>El inmueble debe estar ubicado en zona urbana.</li>
              <li>
                La solicitud deberá estar en estado aprobado en el proceso de
                evaluación de Avanto Tech S.A.S.
              </li>
              <li>No se consultará centrales de riesgo.</li>
              <li>No se exigirá ningún papel impreso.</li>
              <li>
                El proceso será 100% digital a través de la plataforma Mi
                Coninsa Virtual.
              </li>
            </ul>
          </div>

          <div>
            <h4>Costos:</h4>
            <ul>
              <li>
                El Adelanto de Cánones de arrendamiento tiene un costo variable
                representado por una tasa de descuento mensual, que será
                informada en el momento de la solicitud.
              </li>
              <li>
                Una garantía del 1.5% + IVA que cubre diferentes riesgos de la
                operación, los cuales no están cubiertos por el seguro de
                arrendamiento.
              </li>
              <li>
                Opcionalmente podrá contratar un servicio de Asistencia de Hogar
                para protegerse de incurrir en costos adicionales por
                reparaciones del inmueble arrendado.
              </li>
            </ul>
          </div>

          <div>
            <h4>Vigencia y cambios:</h4>
            <ul>
              <li>Vigencia: aplica a partir del 01 de febrero del 2021.</li>
              <li>
                Coninsa Inmobiliaria en cualquier momento puede cambiar los
                términos y condiciones del producto sin previo aviso.
              </li>
              <li>
                Coninsa Inmobiliaria puede suspender temporal o definitivamente
                el producto sin previo aviso.
              </li>
            </ul>
          </div>

          <div>
            <h4>Condiciones y restricciones:</h4>
            <ul>
              <li>
                Aplica para contratos de arriendo de vivienda de mínimo doce
                (12) meses.
              </li>
              <li>
                Los cánones de arrendamiento serán transferidos por Coninsa a
                Avanto por el número de meses que fueron adelantados, luego de
                ello, se retomará el pago directo por parte de Coninsa al
                Propietario, una vez se hayan cobrado los cánones que cubran el
                desembolso realizado.
              </li>
              <li>
                Si el contrato de arrendamiento termina anticipadamente y el
                inmueble vuelve a ser arrendado por Coninsa Inmobiliaria se
                continuarán reteniendo los cánones hasta cobrar los cánones
                faltantes y los intereses de mora causados a la tasa de mora
                vigente. En caso de no volver a arrendarlo a través de Coninsa
                Inmobiliaria, el Propietario será responsable de restituir los
                montos pendientes, incluyendo los intereses de mora.
              </li>
              <li>
                El cliente Propietario debe firmar el pagaré en blanco con carta
                de instrucción, el cual se hará efectivo solo en caso de un
                siniestro en el contrato de arrendamiento.
              </li>
              <li>
                El cliente Propietario debe firmar contrato de sesión de flujos,
                en donde acepta ceder los cánones de arrendamiento mientras
                tenga un adelanto de canon activo.
              </li>
              <li>
                El cliente Propietario debe firmar autorización en contrato de
                mandato, con el cual autoriza a Coninsa Inmobiliaria para
                consignarle los cánones adelantados a Avanto Tech S.A.S. por el
                transcurso del adelanto.
              </li>
              <li>
                El cliente Propietario debe firmar apertura de garantía, con la
                cual se cubre el riesgo operativo del adelanto de cánones.
              </li>
            </ul>
          </div>
        </div>
      </Container>
    </Section>
  );
}
