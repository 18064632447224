import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "@coninsa-s2/slider/styles.css";

import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import * as BubbleCard from "@coninsa-s2/bubble-card";
import SpeechBubble from "@coninsa-s2/speech-bubble";
import IconButton from "@coninsa-s2/icon-button";

import humanRightsBanner from "@coninsa-site/sustainability/assets/images/corporate-governance/human-rights-banner.png";
import humanRightsMobileBanner from "@coninsa-site/sustainability/assets/images/corporate-governance/human-rights-mobile-banner.png";
import LeftArrowIcon from "@coninsa-site/sustainability/assets/icons/left-arrow.inline.svg";

import corporateGovernanceImage from "@coninsa-site/sustainability/assets/images/corporate-governance/corporate-governance.png";
import anticorruptionPracticesImage from "@coninsa-site/sustainability/assets/images/corporate-governance/anticorruption-practices.png";

const data = [
  {
    id: "ps-01",
    url: "/corporativo/sostenibilidad/gobierno-corporativo/gobierno-corporativo",
    image: corporateGovernanceImage,
    title: "Gobierno corporativo",
  },
  {
    id: "ps-02",
    url: "/corporativo/sostenibilidad/gobierno-corporativo/practicas-anticorrupcion",
    image: anticorruptionPracticesImage,
    title: "Prácticas anticorrupción",
  },
];

export default function HumanRightsPage() {
  const sliderOptions = {
    arrows: false,
    gap: 12,
    perPage: 2,
    padding: { left: "10rem", right: "10rem" },
    pagination: false,
    breakpoints: {
      640: {
        arrows: false,
        gap: 12,
        perPage: 1,
        padding: { left: 0, right: "7rem" },
        pagination: true,
      },
    },
  };

  return (
    <div>
      <Section tint="teal" className="mb-5 !py-5 lg:mb-8">
        <Container>
          <div className="items-center gap-4 lg:flex">
            <IconButton
              href="/corporativo/sostenibilidad/gobierno-corporativo"
              className="mb-5 lg:mb-0"
            >
              <LeftArrowIcon />
              Regresar
            </IconButton>
            <div className="text-2xl font-bold text-white">
              Gobierno corporativo
            </div>
          </div>
        </Container>
      </Section>
      <Container className="mb-10">
        <div className="">
          <div className="flex justify-center">
            <img
              src={humanRightsMobileBanner}
              alt=""
              className="absolute block lg:hidden"
            />
          </div>

          <img
            src={humanRightsBanner}
            alt=""
            className="absolute hidden lg:block"
          />

          <div className="relative z-10 pt-56 lg:pt-10">
            <Container>
              <h1 className="text-2xl font-extrabold text-white lg:w-[430px] lg:text-4xl">
                Derechos{" "}
                <span className="block text-center font-handwritting text-7xl font-medium text-s2-dark-blue lg:text-center lg:text-8xl">
                  Humanos
                </span>
              </h1>
            </Container>
          </div>
        </div>
      </Container>

      <Section tint="ligther-gray">
        <Container className="mb-6">
          <div className="grid-cols-12 justify-between gap-8 lg:grid">
            <div className="col-span-7 mb-6">
              <div className="prose mt-8 text-balance rounded-[4px] border-l-4 border-s2-lime pl-4 text-s2-gray-600 lg:mt-0 lg:border-l-8 lg:pl-5 lg:text-lg">
                <p>
                  En Coninsa, nos comprometemos firmemente a promover y proteger
                  los derechos humanos de nuestros colaboradores y clientes.
                  Para lograr este propósito, hemos implementado políticas,
                  procedimientos, manuales y regulaciones que integran los
                  principios y derechos mínimos establecidos en la declaración
                  internacional de derechos humanos. El objetivo principal es
                  orientar nuestras acciones, prevenir y mitigar posibles
                  consecuencias negativas derivadas de la falta de consideración
                  hacia estos derechos. Este compromiso se traduce en la
                  aplicación de procedimientos inclusivos desde la contratación
                  de candidatos, incorporando cláusulas garantistas y
                  protectoras del trabajador basadas en principios como
                  favorabilidad, remuneración mínima vital y móvil proporcional
                  al trabajo en términos de cantidad y calidad.
                </p>
                <p>
                  Así mismo, garantizamos la estabilidad laboral, la seguridad
                  social, el descanso necesario y condiciones laborales dignas y
                  justas. Adicionalmente, nuestro reglamento interno de trabajo
                  se fundamenta en principios esenciales como la protección al
                  trabajo, la igualdad de los trabajadores ante la ley, mínimos
                  derechos y garantías, principio de buena fe, estabilidad
                  laboral, derecho a la defensa y debido proceso, entre otros.
                </p>
              </div>
            </div>
            <div className="col-span-5 self-start">
              <div className="mx-12 rounded-t-2xl bg-s2-mint px-5 py-5 text-center text-xl font-bold text-white lg:mx-24 lg:px-10 lg:py-8 lg:text-2xl">
                Nuestros derechos
              </div>
              <div className="prose mb-4 rounded-lg bg-white p-7 text-s2-gray-600 shadow-[0px_3px_6px_#00000029]">
                <p className="font-bold lg:text-lg">
                  Promovemos la igualdad, protegemos la identidad de género,
                  mostramos tolerancia a las creencias religiosas y aseguramos
                  la libertad de expresión. A través del comité de convivencia,
                  fomentamos una sana conducta, liderazgo desde un enfoque de
                  disciplina positiva y la capacitación para nutrir ambientes
                  laborales saludables.
                </p>
              </div>
            </div>
          </div>
        </Container>
      </Section>

      <Section className="relative -mt-16 lg:-mt-20">
        <Container>
          <SpeechBubble
            tint="mint"
            className="mb-12 px-9 py-2 text-xl font-bold lg:px-10 lg:text-2xl"
          >
            Gobierno
          </SpeechBubble>
          <div className="items-center justify-between gap-4 lg:flex">
            <h2 className="font-display text-4xl font-extralight uppercase text-s2-teal lg:text-5xl">
              Prácticas{" "}
              <span className="block font-medium text-s2-dark-blue">
                sostenibles
              </span>
            </h2>

            <Splide
              options={sliderOptions}
              className="s2-slider s2-slider--lg mt-5"
            >
              {data.map((item) => (
                <SplideSlide key={item.id}>
                  <BubbleCard.Root image={item.image} className="h-64 lg:h-72">
                    <BubbleCard.Content className="min-h-24 bg-s2-lime">
                      <h3 className="text-center text-lg font-bold leading-5 text-white lg:text-[22px]">
                        {item.title}
                      </h3>
                    </BubbleCard.Content>
                    <BubbleCard.Anchor href={item.url} />
                  </BubbleCard.Root>
                </SplideSlide>
              ))}
            </Splide>
          </div>
        </Container>
      </Section>
    </div>
  );
}
