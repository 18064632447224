import React, { useContext } from "react";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "@coninsa-s2/slider/styles.css";

import Heading from "@coninsa-s2/heading";
import Button from "@coninsa-s2/button";
import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import ProjectCard from "@coninsa-s2/project-card";
import Tag from "@coninsa-s2/tag";

import {
  CurrencyContext,
  CurrencySelectors,
  getFormattedCurrencyValue,
} from "@coninsa-s2/currency";

import { getProjectStateData } from "../../utils";

export default function ProjectCards({ projects = [] }) {
  const currency = useContext(CurrencyContext);

  const sliderOptions = {
    arrowPath:
      "M9.392,28.344h21.7l-7.467,7.467a1.392,1.392,0,1,0,1.969,1.968l9.843-9.843h0a1.4,1.4,0,0,0,.173-.212c.023-.035.04-.073.06-.11a1.394,1.394,0,0,0,.068-.13,1.238,1.238,0,0,0,.045-.144c.011-.039.026-.076.034-.115a1.391,1.391,0,0,0,.027-.273h0s0,0,0,0a1.4,1.4,0,0,0-.027-.269c-.008-.042-.024-.08-.036-.121a1.292,1.292,0,0,0-.043-.138,1.441,1.441,0,0,0-.072-.138c-.019-.034-.034-.069-.055-.1a1.371,1.371,0,0,0-.174-.214L25.59,16.124a1.392,1.392,0,1,0-1.969,1.969l7.467,7.467H9.392a1.392,1.392,0,1,0,0,2.784Z",
    arrows: true,
    gap: 0,
    padding: { left: 0, right: "160px" },
    perPage: 3,
    perMove: 1,
    pagination: false,
    breakpoints: {
      640: {
        arrows: false,
        gap: 0,
        perPage: 1,
        padding: { left: 0, right: "1.5rem" },
        pagination: true,
      },
    },
  };

  return (
    <Section tint="ligther-gray">
      <Container>
        <div className="mb-8 flex flex-col gap-6 md:justify-between lg:flex-row">
          <div className="max-w-2xl">
            <Heading tint="teal" size="4xl" className="mb-4" condensed>
              <span className="is-highlighted">Proyectos nuevos </span>
              para comprar
            </Heading>

            <p className="mb-4">
              Te presentamos nuestro portafolio de proyectos nuevos para
              comprar. <br />
              <strong>
                Encontrarás proyectos VIS y No VIS hechos para vivir o invertir.
              </strong>
            </p>

            <Button variant="inverted" href="/proyectos/nuevo" target="_blank">
              Todos los proyectos
            </Button>
          </div>

          <CurrencySelectors
            align="right"
            className="self-end md:max-w-md md:items-end"
          />
        </div>

        <Splide options={sliderOptions} className="s2-slider s2-slider--lg">
          {projects.map((item) => {
            const stateCode = item?.state;
            const state = getProjectStateData(stateCode);
            const isVis = item?.isVis;
            const isRenov = item?.isRenov;

            const areaElement = () => (
              <>
                <strong>
                  Desde {item?.areaFrom} m<sup>2</sup> hasta {item?.areaTo} m
                  <sup>2</sup>
                </strong>
                <br />
                <span className="text-sm">Áreas totales construidas</span>
              </>
            );

            const tagsElement = () => (
              <>
                {state && <Tag tint={state.tint}>{state.name}</Tag>}
                {isVis && <Tag tint="senary">VIS</Tag>}
                {isRenov && <Tag tint="senary">VIS Renov</Tag>}
              </>
            );

            const formattedPrice = getFormattedCurrencyValue(
              item?.price,
              currency
            );

            return (
              <SplideSlide key={item.id}>
                <div className="h-full px-2 pb-4 pt-2">
                  <ProjectCard
                    title={item.name}
                    code={item.code}
                    price={formattedPrice}
                    location={item.location}
                    url={item.url}
                    imageUrl={item.imageUrl}
                    logoUrl={item?.brandUrl}
                    description={item?.description}
                    area={areaElement}
                    tags={tagsElement}
                  />
                </div>
              </SplideSlide>
            );
          })}
        </Splide>
      </Container>
    </Section>
  );
}
