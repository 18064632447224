export function getQueryString(name, search = null) {
  const searchParams = new URLSearchParams(search ?? window.location.search);
  return searchParams.get(name);
}

export function setQueryString(name, value, search = null) {
  let searchParams = new URLSearchParams(search ?? window.location.search);
  searchParams.set(name, value)

  const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
  window.history.pushState(null, '', newUrl)
}

export function removeQueryString(name, search = null) {
  let searchParams = new URLSearchParams(search ?? window.location.search);
  searchParams.delete(name)

  const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
  window.history.pushState(null, '', newUrl)
}
