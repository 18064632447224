import React from "react";

import Banner from "@coninsa-s2/banner";
import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import * as BubbleCard from "@coninsa-s2/bubble-card";
import IconButton from "@coninsa-s2/icon-button";

import reportBanner from "@coninsa-site/sustainability/assets/images/reports/report-banner.png";
import reportMobileBanner from "@coninsa-site/sustainability/assets/images/reports/report-mobile-banner.png";
import reportImageOne from "@coninsa-site/sustainability/assets/images/reports/report-image-one.png";
import reportImageTwo from "@coninsa-site/sustainability/assets/images/reports/report-image-two.png";
import reportImageThree from "@coninsa-site/sustainability/assets/images/reports/report-image-three.png";
import reportImageFour from "@coninsa-site/sustainability/assets/images/reports/report-image-four.png";
import LeftArrowIcon from "@coninsa-site/sustainability/assets/icons/left-arrow.inline.svg";

const data = [
  {
    id: "r-01",
    url: "https://d2xt7650da4t25.cloudfront.net/s3fs-public/2025-03/INFORME%20DE%20SOSTENIBILIDAD%202024_WEB.pdf?VersionId=xqIoHesw0PrlU3EV6ILq3IzcqdvB0FdQ",
    image: reportImageFour,
    title: "Informe de sostenibilidad 2024",
  },
  {
    id: "r-02",
    url: "https://d2xt7650da4t25.cloudfront.net/s3fs-public/2024-03/informe-de-sostenibilidad-coninsa-2023.pdf?VersionId=WQer81IdAlNcvPV8NQqBtITCya0dYGqN",
    image: reportImageOne,
    title: "Informe de sostenibilidad 2023",
  },
  {
    id: "r-03",
    url: "https://d2xt7650da4t25.cloudfront.net/s3fs-public/2023-04/Informe-de-sostenibilidad-2022.pdf",
    image: reportImageTwo,
    title: "Informe de sostenibilidad 2022",
  },
  {
    id: "r-04",
    url: "https://d2xt7650da4t25.cloudfront.net/s3fs-public/2025-03/Informe%20Sostenibilidad%202021Versio%CC%81n8.pdf?VersionId=VyQpCKabULbu0XkQ.E5njY8SWK5JRofz",
    image: reportImageThree,
    title: "Informe de sostenibilidad 2021",
  },
];

export default function SustainabilityReportsPage() {
  return (
    <div>
      <Banner imageUrl={reportBanner} imageUrlMobile={reportMobileBanner}>
        <Container>
          <IconButton
            href="/corporativo/sostenibilidad"
            className="relative -mt-4 mb-4 lg:mb-8"
          >
            <LeftArrowIcon />
            Regresar a sostenibilidad
          </IconButton>
          <h1 className="text-2xl font-extrabold text-white lg:text-5xl">
            Reportes de{" "}
            <span className="block font-handwritting text-6xl font-medium text-s2-dark-blue lg:text-9xl">
              Sostenibilidad
            </span>
          </h1>
        </Container>
      </Banner>

      <Section>
        <Container>
          <div className="mb-10 text-balance text-center text-s2-gray-600 lg:mb-16 lg:text-lg">
            Conoce nuestros{" "}
            <span className="font-bold">reportes de sostenibilidad</span> y
            explora nuestras acciones, logros, metas y retos en la construcción
            de un futuro más sostenible. ¡Acompáñanos en este viaje hacia un
            mundo mejor!
          </div>

          <div className="flex flex-col items-center justify-center gap-4 lg:flex-row">
            {data.map((item) => (
              <BubbleCard.Root key={item.id} image={item.image} className="">
                <BubbleCard.Content className="bg-s2-teal">
                  <h2 className="text-balance text-xl font-bold leading-none">
                    {item.title}
                  </h2>
                </BubbleCard.Content>
                <BubbleCard.Anchor href={item.url} target="_blank" />
              </BubbleCard.Root>
            ))}
          </div>
        </Container>
      </Section>
    </div>
  );
}
