import React from "react";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";

import { arrowPath } from "@coninsa-s2/slider";
import Heading from "@coninsa-s2/heading";
import Button from "@coninsa-s2/button";
import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import PropertyCard from "@coninsa-s2/property-card";
import Tag from "@coninsa-s2/tag";
import Slider from "@coninsa-ui/react-slider";

import { getPropertyStateData } from "@coninsa-site/home/src/utils";

function getServiceType(type) {
  const types = {
    AR: "arriendo",
  };

  return types[type] ?? null;
}

export default function PropertyCards({ properties = [] }) {
  const sliderOptions = {
    arrowPath: arrowPath,
    arrows: true,
    gap: 0,
    padding: { left: 0, right: "160px" },
    perPage: 3,
    perMove: 1,
    pagination: false,
    breakpoints: {
      640: {
        arrows: false,
        gap: 0,
        perPage: 1,
        padding: { left: 0, right: "1.5rem" },
        pagination: true,
      },
    },
  };

  return (
    <Section tint="white">
      <Container>
        <div className="md:flex justify-between gap-4 mb-8 items-center">
          <Heading as="h2" tint="teal" size="4xl" condensed className="mb-3">
            Conoce nuestros <br />
            <span className="is-highlighted">inmuebles destacados</span>
          </Heading>

          <div className="flex gap-5">
            <Button href="/arrendamientos/comercio" target="_blank">
              Para comercio
            </Button>
            <Button href="/arrendamientos/vivienda" target="_blank">
              Para vivienda
            </Button>
          </div>
        </div>

        <Splide options={sliderOptions} className="s2-slider s2-slider--lg">
          {properties?.map((item) => {
            const stateCode = item?.state;
            const state = getPropertyStateData(stateCode);
            const title = [
              item?.propertyType,
              getServiceType(item?.serviceType),
              item?.location,
            ]
              .filter((str) => Boolean(str))
              .join(" en ");

            const tagsElement = () => (
              <>
                <Tag tint="gray">{item?.propertyType}</Tag>
                {state && <Tag tint={state.tint}>{state.name}</Tag>}
              </>
            );

            const formattedPrice = `$${item.price.toLocaleString("es-CO")}`;

            return (
              <SplideSlide key={item.id}>
                <div key={item.id} className="px-2 pt-2 pb-4 h-full">
                  <PropertyCard
                    title={title}
                    code={item.code}
                    price={formattedPrice}
                    area={item.area}
                    bedrooms={item?.bedrooms}
                    bathrooms={item?.bathrooms}
                    url={item.url}
                    images={item?.images}
                    tags={tagsElement}
                  />
                </div>
              </SplideSlide>
            );
          })}
        </Splide>
      </Container>
    </Section>
  );
}
