import React from "react";
import { graphql } from "gatsby";
import Layout from "layout/default/Layout";

import SustainabilityReportsPage from "@coninsa-site/sustainability/src/pages/sustainability-reports";

function SustainabilityReports({data}) {
  return (
    <Layout
      headerAds={data?.drupal?.ads?.header}
      popupAds={data?.drupal?.ads?.popUp}
      footerAds={data?.drupal?.ads?.footer}
    >
      <SustainabilityReportsPage />
    </Layout>
  );
}

export const query = graphql`
  query sustainabilityReportsPageQuery {
    drupal {
      ads: getAdsByUrl(path: "/corporativo/sostenibilidad/reporte-de-sostenibilidad") {
        header {
          id: nid
          image: fieldImage {
            url
          }
          url: fieldUrl {
            uri
            title
          }
        }
        popUp {
          id: nid
          image: fieldImage {
            url
          }
          url: fieldUrl {
            uri
            title
          }
        }
        footer {
          id: nid
          image: fieldImage {
            url
          }
          url: fieldUrl {
            uri
            title
          }
        }
      }
    }
  }
`;

export default SustainabilityReports;
