import {
  ALL_OPTION_VALUE,
  EMPTY_STRING_VALUE,
} from "@coninsa-site/project/src/constants";
import {
  buildPrettyFacetPaths,
  getActiveFilters,
} from "@coninsa-site/project/src/utils";

import { SEARCH_BUY_PROPERTIES_QUERY } from "./queries";

export function generateSearchTextArray(text) {
  const input = text.split(' ');
  const output = [];

  for (let i = 0; i < input.length; i++) {

    if (parseInt(input[i]) > 0 && isNaN(input[i + 1])) {
      if (input[i + 1]) {
        output.push(input[i] + ' ' + input[i + 1]);
      } else {
        output.push(input[i]);
      }

      i++;
    } else {
      output.push(input[i]);
    }
  }

  return output;
}

const baseUrl = "/venta-de-usados/inmuebles";
const breadcrumbItems = [
  {
    label: "Inicio",
    url: "/",
  },
  {
    label: "Venta de usados",
    url: "/venta-de-usados",
  },
  {
    label: "Inmuebles",
  },
];
const title = "Venta de Inmuebles Usados";
const loadMoreLabel = "Cargar más inmuebles";
const searchQuery = SEARCH_BUY_PROPERTIES_QUERY;

const configs = {
  text: {
    pretty: "text",
    facetName: "text",
    fieldName: "text",
    defaultValue: EMPTY_STRING_VALUE,
    useLabel: false,
    translate: (t) => t,
    type: "query",
  },
  property_type: {
    pretty: "tipo-de-inmueble",
    facetName: "property_type",
    fieldName: "property_type",
    defaultValue: ALL_OPTION_VALUE,
    useLabel: false,
    translate: (t) => t,
    type: "pathname",
  },
  city_string: {
    pretty: "ciudad",
    facetName: "city_string",
    fieldName: "city_string",
    defaultValue: ALL_OPTION_VALUE,
    useLabel: false,
    translate: (t) => t,
    type: "pathname",
  },
};

const initialValues = {
  // Full search
  text: "",

  // Price
  field_lease_value_from: "",
  field_lease_value_to: "",

  field_area_from: "",
  field_area_to: "",

  city_string: ALL_OPTION_VALUE,
  property_type: ALL_OPTION_VALUE,
  field_bedrooms: ALL_OPTION_VALUE,
  field_bathrooms: ALL_OPTION_VALUE,
  field_garages: ALL_OPTION_VALUE,
  field_stratum: ALL_OPTION_VALUE,
};

const getSearchQuery = (data) => {
  let fullSearch = "";
  let andConditions = [];

  if (data.text?.length >= 3) {
    fullSearch = generateSearchTextArray(data.text);
  }

  if (data.city_string !== ALL_OPTION_VALUE) {
    andConditions = andConditions.concat({
      name: "city_string",
      value: data.city_string,
    });
  }

  if (data.property_type !== ALL_OPTION_VALUE) {
    andConditions = andConditions.concat({
      name: "property_type",
      value: data.property_type,
    });
  }

  // Price range
  if (data.field_lease_value_from !== "") {
    andConditions = andConditions.concat({
      name: "sale_value",
      value: data.field_lease_value_from.replaceAll(".", ""),
      operator: ">=",
    });
  }

  if (data.field_lease_value_to !== "") {
    andConditions = andConditions.concat({
      name: "sale_value",
      value: data.field_lease_value_to.replaceAll(".", ""),
      operator: "<=",
    });
  }

  // Bedrooms
  if (data.field_bedrooms !== ALL_OPTION_VALUE && data.field_bedrooms > 0) {
    andConditions = andConditions.concat({
      name: "field_bedrooms",
      value: data.field_bedrooms,
      operator: ">=",
    });
  }

  if (data.field_bathrooms !== ALL_OPTION_VALUE && data.field_bathrooms > 0) {
    andConditions = andConditions.concat({
      name: "field_bathrooms",
      value: data.field_bathrooms,
      operator: ">=",
    });
  }

  if (data.field_garages !== ALL_OPTION_VALUE && data.field_garages > 0) {
    andConditions = andConditions.concat({
      name: "field_garages",
      value: data.field_garages,
      operator: ">=",
    });
  }

  if (data.field_stratum !== ALL_OPTION_VALUE && data.field_stratum > 0) {
    andConditions = andConditions.concat({
      name: "field_stratum",
      value: data.field_stratum,
      operator: "=",
    });
  }

  if (data.field_area_from !== "") {
    andConditions = andConditions.concat({
      name: "field_area",
      value: data.field_area_from.replaceAll(".", ""),
      operator: ">=",
    });
  }

  if (data.field_area_to !== "") {
    andConditions = andConditions.concat({
      name: "field_area",
      value: data.field_area_to.replaceAll(".", ""),
      operator: "<=",
    });
  }

  return {
    text: fullSearch,
    and_conditions: andConditions,
  };
};

const getPrettyPaths = (data) => {
  return buildPrettyFacetPaths(baseUrl, configs, data);
};

const getActiveFacets = (data) => {
  return getActiveFilters(baseUrl, configs, data);
};

const defaultSortFields = [
  {
    field: "field_scratch_prices",
    value: "ASC",
  },
];

const sortFields = {
  lower_price: {
    field: "sale_value",
    value: "ASC",
  },
  higher_price: {
    field: "sale_value",
    value: "DESC",
  },
  smaller_area: {
    field: "field_area",
    value: "ASC",
  },
  larger_area: {
    field: "field_area",
    value: "DESC",
  },
  property_type: {
    field: "property_type",
    value: "ASC",
  },
};

const sortOptions = [
  { value: "relevance", label: "Relevancia" },
  { value: "property_type", label: "Tipo de inmueble" },
  { value: "lower_price", label: "Menor precio" },
  { value: "higher_price", label: "Mayor precio" },
  { value: "smaller_area", label: "Menor área" },
  { value: "larger_area", label: "Mayor área" },
];

export default {
  breadcrumbItems,
  title,
  loadMoreLabel,

  baseUrl,
  searchQuery,
  configs,
  initialValues,
  getSearchQuery,
  getPrettyPaths,
  getActiveFacets,

  sort: {
    defaultFields: defaultSortFields,
    fields: sortFields,
    options: sortOptions,
  },
};
