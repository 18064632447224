import React from "react";
import { cn } from "@coninsa-s2/utils";

import "./styles.css";

interface SpeechBubbleProps {
  children: React.ReactNode;
  tint?: "teal" | "dark-blue" | "blue" | "lime" | "green" | "mint" | "yellow";
  size?: "small" | "medium" | "large";

  className?: string;
}

const SpeechBubble: React.FC<SpeechBubbleProps> = ({
  tint = "dark-blue",
  size = "small",
  children,

  className,
  ...props
}) => {
  const dynamicClasses = {
    [`s2-speech-bubble--${tint}`]: tint,
    [`s2-speech-bubble--${size}`]: size,
  };

  return (
    <div
      className={cn("s2-speech-bubble", "py-1 px-4", dynamicClasses, className)}
      {...props}
    >
      {children}
    </div>
  );
};

export default SpeechBubble;
