import React from "react";
import clsx from "clsx";

import Tag from "@coninsa-s2/tag";
import Title from "@coninsa-s2/title";
import Button from "@coninsa-s2/button";
import FeatureItem from "@coninsa-s2/feature-item";

import "./styles.css";

function ProjectCard({
  code,
  price,
  location,
  imageUrl,
  description,
  title,
  logoUrl,
  url,
  area: AreaElement,
  tags: TagsElement,
  className,
  style = {},
}) {
  return (
    <div className={clsx("s2-project-card", className)} style={style}>
      <div className="s2-project-card__media">
        <img src={imageUrl} alt="Picture" loading="lazy" height={260} />
        <div className="s2-project-card__tags">
          <TagsElement />
        </div>
      </div>

      <div className="s2-project-card__content">
        <Tag
          variant="rounded"
          className="s2-project-card__code absolute -top-[14px]"
        >
          Código: {code}
        </Tag>

        <img className="s2-project-card__logo" src={logoUrl} alt="Picture" />

        <div className="s2-project-card__body">
          <Title tint="black" size="lg">
            {title}
          </Title>

          <ul className="s2-project-card__features">
            <li>
              <span>Desde</span>
              <p className="s2-project-card__price">{price}</p>
            </li>
            {location && (
              <li>
                <FeatureItem icon="pin">
                  <strong>{location}</strong>
                </FeatureItem>
              </li>
            )}
            <li>
              <FeatureItem icon="area" className="leading-5">
                <AreaElement />
              </FeatureItem>
            </li>
          </ul>
        </div>

        <div className="s2-project-card__footer">
          {description && (
            <p className="s2-project-card__description">{description}</p>
          )}

          <Button href={url} target="_blank" inverted={true} size="fluid">
            Ver proyecto
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ProjectCard;
