import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "@coninsa-s2/slider/styles.css";

import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import * as BubbleCard from "@coninsa-s2/bubble-card";
import SpeechBubble from "@coninsa-s2/speech-bubble";
import IconButton from "@coninsa-s2/icon-button";

import employeeWellbeingBanner from "@coninsa-site/sustainability/assets/images/social/employee-wellbeing-banner.png";
import employeeWellbeingMobileBanner from "@coninsa-site/sustainability/assets/images/social/employee-wellbeing-mobile-banner.png";
import heartIcon from "@coninsa-site/sustainability/assets/images/social/heart.svg";
import clockIcon from "@coninsa-site/sustainability/assets/images/social/clock.svg";
import stethoscopeIcon from "@coninsa-site/sustainability/assets/images/social/stethoscope.svg";
import squareCapIcon from "@coninsa-site/sustainability/assets/images/social/square-shaped-cap.svg";
import keychainIcon from "@coninsa-site/sustainability/assets/images/social/keychain.svg";
import mcsIcon from "@coninsa-site/sustainability/assets/images/social/mcs.svg";
import tiIcon from "@coninsa-site/sustainability/assets/images/social/ti.svg";
import ediIcon from "@coninsa-site/sustainability/assets/images/social/edi.svg";
import sstIcon from "@coninsa-site/sustainability/assets/images/social/sst.svg";
import LeftArrowIcon from "@coninsa-site/sustainability/assets/icons/left-arrow.inline.svg";

import contractorsImage from "@coninsa-site/sustainability/assets/images/social/contractors.png";
import diversityImage from "@coninsa-site/sustainability/assets/images/social/diversity.png";
import alliesImage from "@coninsa-site/sustainability/assets/images/social/allies.png";
import customerExperienceImage from "@coninsa-site/sustainability/assets/images/social/customer-experience.png";

const benefits = [
  {
    id: "benefit-1",
    icon: heartIcon,
    label: "Calidad de vida",
  },
  {
    id: "benefit-2",
    icon: clockIcon,
    label: "Tiempo",
  },
  {
    id: "benefit-3",
    icon: stethoscopeIcon,
    label: "Salud",
  },
  {
    id: "benefit-4",
    icon: squareCapIcon,
    label: "Educación",
  },
  {
    id: "benefit-5",
    icon: keychainIcon,
    label: "Descuentos",
  },
];

const data = [
  {
    id: "ps-01",
    url: "/corporativo/sostenibilidad/sostenibilidad-social/desarrollo-de-contratistas-y-proveedores",
    image: contractorsImage,
    title: "Desarrollo de Contratistas y Proveedores",
  },
  {
    id: "ps-02",
    url: "/corporativo/sostenibilidad/sostenibilidad-social/gestion-de-aliados",
    image: alliesImage,
    title: "Gestión de Aliados",
  },
  {
    id: "ps-03",
    url: "/corporativo/sostenibilidad/sostenibilidad-social/diversidad-e-inclusion",
    image: diversityImage,
    title: "Diversidad e Inclusión",
  },
  {
    id: "ps-04",
    url: "/corporativo/sostenibilidad/sostenibilidad-social/experiencia-del-cliente",
    image: customerExperienceImage,
    title: "Experiencia del Cliente",
  },
];

export default function EmployeeWellbeingPage() {
  const sliderOptions = {
    arrows: false,
    gap: 12,
    perPage: 3,
    padding: { left: 0, right: "70px" },
    pagination: false,
    breakpoints: {
      640: {
        arrows: false,
        gap: 12,
        perPage: 1,
        padding: { left: 0, right: "5rem" },
        pagination: true,
      },
    },
  };

  return (
    <div>
      <Section tint="dark-blue" className="mb-5 !py-5 lg:mb-8">
        <Container>
          <div className="items-center gap-4 lg:flex">
            <IconButton
              href="/corporativo/sostenibilidad/sostenibilidad-social"
              className="mb-5 lg:mb-0"
            >
              <LeftArrowIcon />
              Regresar
            </IconButton>
            <div className="text-2xl font-bold text-white">
              Sostenibilidad Social
            </div>
          </div>
        </Container>
      </Section>
      <Container className="mb-10">
        <div className="">
          <div className="flex justify-center">
            <img
              src={employeeWellbeingMobileBanner}
              alt=""
              className="absolute block lg:hidden"
            />
          </div>

          <img
            src={employeeWellbeingBanner}
            alt=""
            className="absolute hidden lg:block"
          />

          <div className="relative z-10 pt-56 lg:pt-10">
            <Container>
              <h1 className="text-2xl font-extrabold text-white lg:w-[430px] lg:text-4xl">
                Bienestar del{" "}
                <span className="block text-center font-handwritting text-6xl font-medium text-s2-lime lg:text-end lg:text-8xl">
                  Coladorador
                </span>
              </h1>
            </Container>
          </div>
        </div>
      </Container>

      <Section tint="ligther-gray">
        <Container>
          <div className="grid-cols-12 justify-between gap-8 lg:grid">
            <div className="col-span-7 mb-6">
              <div className="prose mt-8 text-balance rounded-[4px] border-l-4 border-s2-lime pl-4 text-s2-gray-600 lg:mt-0 lg:border-l-8 lg:pl-5 lg:text-lg">
                <p>
                  Nuestro propósito superior es el principio rector de nuestra
                  cultura, actuando como fuente inspiradora que guía de manera
                  consciente y coherente todas las iniciativas con el fin de
                  garantizar la ejecución efectiva de la estrategia y la
                  sostenibilidad de la compañía. En este capítulo, detallaremos
                  las acciones claves que hemos definido y llevado a cabo para
                  alcanzar con éxito nuestros objetivos vinculados al componente
                  social.
                </p>
                <p>
                  En Coninsa reconocemos que nuestros colaboradores son el
                  activo más valioso. Por eso, nos esforzamos por ofrecerles una
                  serie de beneficios y programas diseñados para mejorar su
                  calidad de vida y promover su bienestar en todos los aspectos.
                  Desde un ambiente de trabajo seguro y saludable hasta
                  programas de desarrollo profesional y personal, nos
                  comprometemos a proporcionar a nuestros colaboradores las
                  herramientas y el apoyo que necesitan para crecer y prosperar
                  tanto dentro como fuera del trabajo.
                </p>
              </div>
            </div>
            <div className="col-span-5 self-start rounded-xl shadow-[0px_3px_6px_#00000029]">
              <div className="text-balance rounded-t-xl bg-s2-mint px-6 py-5 text-center text-lg font-bold text-white lg:text-xl">
                Algunos de nuestros beneficios del programa + Para ti son:
              </div>
              <div className="rounded-b-xl bg-white px-4 py-3 lg:px-6 lg:py-8">
                <div className="grid-cols-3 gap-4 lg:grid">
                  {benefits.map((benefit) => (
                    <div
                      key={benefit.id}
                      className="flex flex-row items-center text-center lg:flex-col"
                    >
                      <img src={benefit.icon} alt="Benefit icon" />
                      <div className="text-xl font-bold !leading-6 text-s2-gray-600 lg:text-2xl">
                        {benefit.label}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Section>

      <Section>
        <Container className="mb-6">
          <h2 className="mb-5 font-display text-4xl font-extralight uppercase text-s2-teal lg:mb-16 lg:text-5xl">
            Nuestros{" "}
            <span className="block font-medium text-s2-dark-blue">
              programas bandera
            </span>
          </h2>
          <div className="grid-cols-1 divide-y-2 lg:grid">
            <div className="grid-cols-12 items-center gap-4 pb-6 lg:grid lg:pb-12">
              <h3 className="col-span-4">
                <span className="hidden">Mi casa soñada</span>
                <img src={mcsIcon} alt="MCS" />
              </h3>
              <div className="prose col-span-8 text-s2-gray-600 lg:text-lg">
                <p>
                  Contamos con el programa{" "}
                  <span className="font-bold">Mi casa soñada</span> el cual está
                  orientado a apoyar a las familias más vulnerables que tienen
                  necesidades de mejorar las condiciones de su vivienda, este
                  programa fue creado en el{" "}
                  <span className="font-bold">año 2021</span> y hasta la fecha
                  hemos mejorado la calidad de vida{" "}
                  <span className="font-bold">
                    de 25 familias y se ha invertido $52.500.000
                  </span>
                </p>
              </div>
            </div>
            <div className="flex grid-cols-12 flex-col-reverse items-start gap-4 py-6 lg:grid lg:py-12">
              <div className="prose col-span-8 text-s2-gray-600 lg:text-lg">
                <p>
                  En Coninsa, valoramos y resaltamos los resultados
                  excepcionales que van en consonancia con nuestros valores
                  corporativos y pilares de comportamiento. Con el objetivo de
                  fortalecer nuestra cultura,{" "}
                  <span className="font-bold">
                    reconocemos a aquellos talentos que alcanzan logros
                    extraordinarios
                  </span>{" "}
                  e inspiran a otros a afrontar desafíos, siendo auténticos
                  reflejos de nuestra identidad corporativa.
                </p>
                <p>
                  Mediante nuestro programa de reconocimiento “Talentos
                  Inspiradores” y “Talentos Extraordinarios”, impulsamos
                  iniciativas que nos conduzcan a reflejar nuestra excelencia y
                  afianzar nuestro propósito superior; de esta manera, buscamos
                  motivar a los demás a replicar este comportamiento en toda la
                  organización.
                </p>
                <p className="font-bold">
                  Este programa se creó en el año 2015, a través de todo este
                  tiempo ha reconocido a 1.179 colaboradores y se ha invertido
                  hasta el momento $342.336.979.
                </p>
              </div>
              <h3 className="col-span-4">
                <span className="hidden">Talentos inspiradores</span>
                <img src={tiIcon} alt="TI" />
              </h3>
            </div>
            <div className="grid-cols-12 items-center gap-4 py-6 lg:grid lg:py-12">
              <h3 className="col-span-4">
                <span className="hidden">Edi</span>
                <img src={ediIcon} alt="Edi" />
              </h3>
              <div className="prose col-span-8 text-s2-gray-600 lg:text-lg">
                <p>
                  En Coninsa estamos comprometidos con el trabajo genuino y
                  determinado en función de valorar la diversidad, fomentar la
                  inclusión, y por fortalecer un entorno equitativo de trabajo
                  donde todos se sientan valorados y respetados por sus
                  capacidades y contribución.{" "}
                  <span className="font-bold">
                    Ofrecemos oportunidades de formación y desarrollo
                  </span>{" "}
                  para que nuestros colaboradores puedan alcanzar todo su
                  potencial, y fomentamos una cultura de colaboración y apoyo
                  mutuo.
                </p>
                <p>
                  Es así como <span className="font-bold">EDI</span> llega como
                  la plataforma que nos permitirá visibilizar con mayor fuerza
                  todas las acciones definidas para fomentar la equidad, la
                  diversidad y la inclusión.
                </p>
              </div>
            </div>
            <div className="flex grid-cols-12 flex-col-reverse items-start gap-4 pt-6 lg:grid lg:pt-12">
              <div className="prose col-span-8 text-s2-gray-600 lg:text-lg">
                <div className="font-bold">Nuestro Sistema SST</div>
                <p>
                  De manera decidida trabajos en la mejora de las condiciones de
                  trabajo mediante la implementación del Sistema de Gestión en
                  Seguridad y Salud en el Trabajo. Más allá de simplemente
                  cumplir con la normativa nacional, nuestro objetivo es crear
                  entornos laborales seguros y prevenir patologías. Nos hemos
                  comprometido a gestionar comportamientos alineados con la
                  norma ISO 45001, asegurando así un estándar de excelencia en
                  seguridad y salud ocupacional para todos nuestros
                  colaboradores.
                </p>
              </div>
              <h3 className="col-span-4">
                <span className="hidden">Seguridad y salud en el trabajo</span>
                <img src={sstIcon} alt="SST" />
              </h3>
            </div>
          </div>
        </Container>
      </Section>

      <Section className="relative -mt-14 lg:-mt-20">
        <Container>
          <SpeechBubble
            tint="mint"
            className="mb-12 px-9 py-2 text-xl font-bold lg:px-10 lg:text-2xl"
          >
            Social
          </SpeechBubble>
          <div className="items-center justify-between gap-4 lg:flex">
            <h2 className="font-display text-4xl font-extralight uppercase text-s2-teal lg:text-5xl">
              Prácticas{" "}
              <span className="block font-medium text-s2-dark-blue">
                sostenibles
              </span>
            </h2>

            <Splide
              options={sliderOptions}
              className="s2-slider s2-slider--lg mt-5"
            >
              {data.map((item) => (
                <SplideSlide key={item.id}>
                  <BubbleCard.Root image={item.image} className="h-64 lg:h-72">
                    <BubbleCard.Content className="min-h-24 bg-s2-teal">
                      <h3 className="text-center text-lg font-bold text-white lg:text-xl">
                        {item.title}
                      </h3>
                    </BubbleCard.Content>
                    <BubbleCard.Anchor href={item.url} />
                  </BubbleCard.Root>
                </SplideSlide>
              ))}
            </Splide>
          </div>
        </Container>
      </Section>
    </div>
  );
}
