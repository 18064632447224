import React from "react";

import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import Breadcrumb, { BreadcrumbItem } from "@coninsa-s2/breadcrumb";
import * as Accordion from "@coninsa-s2/accordion";
import Title from "@coninsa-s2/title";

import buildingIcon from "../../assets/icons/building.svg";
import pdfIcon from "../../assets/icons/pdf.svg";

import termDocuments2024 from "../../assets/documents/project-2024.js";

export default function Project() {
  const breadcrumbItems = [
    {
      label: "Inicio",
      url: "/terminos-y-condiciones",
    },
    {
      label: "Términos y condiciones proyectos",
    },
  ];

  return (
    <Section>
      <Container>
        <div className="mb-8">
          <Breadcrumb>
            {breadcrumbItems.map((item) => (
              <BreadcrumbItem>
                {item.url && <a href={item.url}>{item.label}</a>}
                {!item.url && item.label}
              </BreadcrumbItem>
            ))}
          </Breadcrumb>
        </div>

        <div className="flex items-center gap-4">
          <img src={buildingIcon} alt="building icon" />
          <h2>
            <span className="text-lg text-s2-dark-blue lg:text-[22px]">
              Términos y condiciones{" "}
            </span>
            <Title size="2xl" tint="teal" weight="bold">
              Proyectos
            </Title>
          </h2>
        </div>

        <div>
          <Accordion.Root type="single" defaultValue="item-1" collapsible>
            <Accordion.Item value="item-1">
              <Accordion.Trigger>
                <div className="font-display text-2xl font-medium">
                  Año 2024
                </div>
              </Accordion.Trigger>
              <Accordion.Content>
                <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
                  {termDocuments2024.map((item) => (
                    <a
                      key={item?.id}
                      href={item?.url}
                      target="_blank"
                      className="flex items-center gap-2 rounded-lg bg-white px-2 py-3 shadow hover:shadow-md lg:gap-4 lg:px-5 lg:py-4"
                    >
                      <img
                        src={item?.icon}
                        className="h-16 w-14 lg:h-auto lg:w-auto"
                      />
                      <div>
                        <p className="text-sm font-bold text-s2-dark-blue lg:text-base">
                          {item?.label}
                        </p>
                      </div>
                    </a>
                  ))}
                </div>
              </Accordion.Content>
            </Accordion.Item>
            <Accordion.Item value="item-2">
              <Accordion.Trigger>
                <div className="font-display text-2xl font-medium">
                  Año 2023
                </div>
              </Accordion.Trigger>
              <Accordion.Content>
                <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
                  <a
                    href="https://d2xt7650da4t25.cloudfront.net/s3fs-public/2023-03/Feria%20-%20T%C3%A9rminos%20y%20condiciones%20Expoinmobiliaria%202023.docx.pdf"
                    target="_blank"
                    className="flex items-center gap-2 rounded-lg bg-white px-2 py-3 shadow hover:shadow-md lg:gap-4 lg:px-5 lg:py-4"
                  >
                    <img
                      src={pdfIcon}
                      className="h-16 w-14 lg:h-auto lg:w-auto"
                    />
                    <div>
                      <p className="text-sm font-bold text-s2-dark-blue lg:text-base">
                        Expoinmobiliaria
                      </p>
                    </div>
                  </a>
                </div>
              </Accordion.Content>
            </Accordion.Item>
          </Accordion.Root>
        </div>
      </Container>
    </Section>
  );
}
