import React from "react";

import Section from "@coninsa-s2/section";
import Hero from "@coninsa-s2/hero";
import Heading from "@coninsa-s2/heading";
import Button from "@coninsa-s2/button";
import Container from "@coninsa-s2/container";
import heroImage from "./images/hero-min.png";

export default function () {
  return (
    <Section tint="ligther-gray">
      <Container>
        <Hero>
          <Hero.Media>
            <a
              href="https://www.youtube.com/watch?v=dqTdix8_hhM"
              target="_blank"
            >
              <img src={heroImage} alt="Placeholder" />
            </a>
          </Hero.Media>
          <Hero.Content>
            <Heading as="h2" size="4xl" tint="teal" condensed>
              <span className="is-highlighted">Somos el resultado </span>
              <br />
              De un equipo de Colaboradores,
            </Heading>
            <p>
              que inspirados en las necesidades de nuestros Clientes, hacemos
              posible el{" "}
              <strong>sueño de miles de familias y empresarios</strong>, porque
              sabemos que el bienestar es verdadero solo cuando la funcionalidad
              y el diseño van de la mano.
            </p>
            <Button href="/somos-coninsa" target="_blank">
              Conoce más
            </Button>
          </Hero.Content>
        </Hero>
      </Container>
    </Section>
  );
}
