import React from "react";

import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import * as Tabs from "@coninsa-s2/tabs";
import IconButton from "@coninsa-s2/icon-button";

import pdfIcon from "@coninsa-site/sustainability/assets/images/social/pdf.svg";
import sheetIcon from "@coninsa-site/sustainability/assets/images/social/sheet.svg";
import LeftArrowIcon from "@coninsa-site/sustainability/assets/icons/left-arrow.inline.svg";

export default function ContractorCompaniesPage() {
  return (
    <div>
      <Section tint="dark-blue" className="!py-5">
        <Container>
          <div className="items-center gap-4 lg:flex">
            <IconButton
              href="/corporativo/sostenibilidad/sostenibilidad-social/desarrollo-de-contratistas-y-proveedores"
              className="mb-5 lg:mb-0"
            >
              <LeftArrowIcon />
              Regresar
            </IconButton>
          </div>
        </Container>
      </Section>

      <Section tint="ligther-gray !pt-0">
        <Container>
          <div className="mb-10 items-center gap-4 rounded-b-xl bg-s2-lime px-8 py-5 text-white lg:flex lg:w-[700px] lg:px-10 lg:py-8">
            <img
              src={sheetIcon}
              alt="Sheet icon"
              className="mb-2 h-16 w-16 rounded-full bg-white object-contain p-3 shadow-[0px_3px_6px_#00000029] lg:mb-4 lg:h-auto lg:w-auto lg:p-4"
            />
            <div>
              <h1>
                <div className="text-xl lg:text-2xl">
                  Requisitos generales para{" "}
                </div>
                <div className="text-2xl font-bold lg:text-3xl">
                  Las Empresas Contratistas
                </div>
              </h1>
            </div>
          </div>

          <Tabs.Root
            defaultValue="tab1"
            orientation="vertical"
            className="data-[orientation=vertical]:gap-0"
          >
            <Tabs.List aria-label="Manage your account">
              <Tabs.Trigger value="tab1">
                Régimen jurídico en{" "}
                <span className="inline text-lg font-bold leading-none md:block lg:text-xl">
                  seguridad y salud en el trabajo
                </span>
              </Tabs.Trigger>
              <Tabs.Trigger value="tab2">
                Régimen jurídico en{" "}
                <span className="inline text-lg font-bold leading-none md:block lg:text-xl">
                  ambiental
                </span>
              </Tabs.Trigger>
            </Tabs.List>

            <Tabs.Content value="tab1">
              <h2 className="mb-4 text-xl font-bold text-s2-dark-blue lg:text-2xl">
                Régimen jurídico en seguridad y salud en el trabajo
              </h2>
              <div className="prose">
                <p>
                  El Contratista es responsable de todos los eventos que puedan
                  causar afectación al ambiente que le sean imputables a él, a
                  sus trabajadores, sus dependientes o sus contratistas. Para
                  evitar tales eventos, el contratista adoptará todas las
                  medidas preventivas oportunas que dictan las buenas prácticas
                  de gestión ambiental y la normatividad legal vigente, en
                  especial las relativas a evitar vertimientos contaminantes en
                  el sistema de alcantarillado y/o cuerpos de agua, emisiones
                  contaminantes a la atmósfera y el abandono o quema de
                  cualquier tipo de residuos.
                </p>
                <p>
                  En caso de presentarse un accidente o incidente de carácter
                  ambiental debe informarse inmediatamente al residente
                  ambiental, residente o inspector SSTA y director de obra con
                  el fin de que sea atendido con las medidas establecidas por la
                  empresa y reportado en el formato respectivo. El contratista
                  debe adoptar las medidas oportunas para el estricto
                  cumplimiento de la legislación ambiental vigente que sea de
                  aplicación al trabajo realizado.
                </p>
                <p>
                  Ante un incumplimiento de estas condiciones, Coninsa podrá
                  proceder a la suspensión temporal o definitiva del trabajo,
                  labor o servicio corriendo las pérdidas consiguientes a cargo
                  del contratista. El contratista antes de iniciar los trabajos
                  obra, labor o servicios debe identificar los riesgos
                  ambientales de cada actividad y definir los controles que se
                  van a implementar para mitigarlos o eliminarlos. Esta
                  información debe ser enviada al residente ambiental y al
                  residente o inspector SSTA para su visto bueno y seguimiento.
                </p>
              </div>
            </Tabs.Content>
            <Tabs.Content value="tab2">
              <h2 className="mb-4 text-xl font-bold text-s2-dark-blue lg:text-2xl">
                Régimen jurídico en ambiental
              </h2>
              <div className="prose">
                <p>
                  El Contratista es responsable de todos los eventos que puedan
                  causar afectación al ambiente que le sean imputables a él, a
                  sus trabajadores, sus dependientes o sus contratistas. Para
                  evitar tales eventos, el contratista adoptará todas las
                  medidas preventivas oportunas que dictan las buenas prácticas
                  de gestión ambiental y la normatividad legal vigente, en
                  especial las relativas a evitar vertimientos contaminantes en
                  el sistema de alcantarillado y/o cuerpos de agua, emisiones
                  contaminantes a la atmósfera y el abandono o quema de
                  cualquier tipo de residuos.
                </p>
                <p>
                  En caso de presentarse un accidente o incidente de carácter
                  ambiental debe informarse inmediatamente al residente
                  ambiental, residente o inspector SSTA y director de obra con
                  el fin de que sea atendido con las medidas establecidas por la
                  empresa y reportado en el formato respectivo. El contratista
                  debe adoptar las medidas oportunas para el estricto
                  cumplimiento de la legislación ambiental vigente que sea de
                  aplicación al trabajo realizado.
                </p>
                <p>
                  Ante un incumplimiento de estas condiciones, Coninsa podrá
                  proceder a la suspensión temporal o definitiva del trabajo,
                  labor o servicio corriendo las pérdidas consiguientes a cargo
                  del contratista. El contratista antes de iniciar los trabajos
                  obra, labor o servicios debe identificar los riesgos
                  ambientales de cada actividad y definir los controles que se
                  van a implementar para mitigarlos o eliminarlos. Esta
                  información debe ser enviada al residente ambiental y al
                  residente o inspector SSTA para su visto bueno y seguimiento.
                </p>
              </div>
            </Tabs.Content>
          </Tabs.Root>
        </Container>
      </Section>

      <Section className="bg-gradient-to-b from-s2-white from-50% to-s2-dark-blue to-50% lg:bg-gradient-to-r lg:from-55% lg:to-45%">
        <Container>
          <div className="grid-cols-12 gap-6 lg:grid">
            <div className="prose col-span-7 text-balance pb-20 text-s2-gray-600 lg:pb-0 lg:text-lg">
              <p>
                Los contratistas, proveedores, contratistas independientes y
                empresas de servicios temporales y demás actores de la cadena de
                abastecimiento, son parte fundamental en la administración y
                funcionamiento de Coninsa.
              </p>
              <p className="font-bold">
                {" "}
                Son un grupo de interés conexo para el cumplimiento de nuestros
                valores, comportamientos, estrategias y metas.
              </p>
            </div>
            <div className="col-span-5">
              <div className="mb-4 text-balance text-white">
                Con este manual, queremos aportarle beneficios a esa búsqueda
                integral de{" "}
                <span className="font-bold">“Construimos Bienestar”</span>.
              </div>
              <a
                className="flex gap-4 rounded-lg bg-white p-6 text-s2-dark-blue shadow-[0px_3px_6px_#00000029] hover:bg-s2-mint hover:text-white lg:inline-flex"
                href="#"
              >
                <img src={pdfIcon} alt="PDF icon" />
                <div>
                  <div className="mb-2 text-balance text-lg !leading-5 lg:text-xl">
                    Manual de{" "}
                    <span className="block font-bold">
                      Gestión de Contratistas
                    </span>
                  </div>
                  <p className="text-s2-gray-600">Archivo PDF - 1200 Kb</p>
                </div>
              </a>
            </div>
          </div>
        </Container>
      </Section>
    </div>
  );
}
