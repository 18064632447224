import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "@coninsa-s2/slider/styles.css";

import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import * as BubbleCard from "@coninsa-s2/bubble-card";
import SpeechBubble from "@coninsa-s2/speech-bubble";
import IconButton from "@coninsa-s2/icon-button";

import anticorruptionPracticesBanner from "@coninsa-site/sustainability/assets/images/corporate-governance/anticorruption-practices-banner.png";
import anticorruptionPracticesMobileBanner from "@coninsa-site/sustainability/assets/images/corporate-governance/anticorruption-practices-mobile-banner.png";
import LeftArrowIcon from "@coninsa-site/sustainability/assets/icons/left-arrow.inline.svg";

import corporateGovernanceImage from "@coninsa-site/sustainability/assets/images/corporate-governance/corporate-governance.png";
import humanRightsImage from "@coninsa-site/sustainability/assets/images/corporate-governance/human-rights.png";

const data = [
  {
    id: "ps-01",
    url: "/corporativo/sostenibilidad/gobierno-corporativo/practicas-anticorrupcion",
    image: corporateGovernanceImage,
    title: "Gobierno corporativo",
  },
  {
    id: "ps-02",
    url: "/corporativo/sostenibilidad/gobierno-corporativo/derechos-humanos",
    image: humanRightsImage,
    title: "Derechos humanos",
  },
];

export default function AnticorruptionPracticesPage() {
  const sliderOptions = {
    arrows: false,
    gap: 12,
    perPage: 2,
    padding: { left: "10rem", right: "10rem" },
    pagination: false,
    breakpoints: {
      640: {
        arrows: false,
        gap: 12,
        perPage: 1,
        padding: { left: 0, right: "6rem" },
        pagination: true,
      },
    },
  };

  return (
    <div>
      <Section tint="teal" className="mb-5 !py-5 lg:mb-8">
        <Container>
          <div className="items-center gap-4 lg:flex">
            <IconButton
              href="/corporativo/sostenibilidad/gobierno-corporativo"
              className="mb-5 lg:mb-0"
            >
              <LeftArrowIcon />
              Regresar
            </IconButton>
            <div className="text-2xl font-bold text-white">
              Gobierno corporativo
            </div>
          </div>
        </Container>
      </Section>
      <Container className="mb-10">
        <div className="">
          <div className="flex justify-center">
            <img
              src={anticorruptionPracticesMobileBanner}
              alt=""
              className="absolute block lg:hidden"
            />
          </div>

          <img
            src={anticorruptionPracticesBanner}
            alt=""
            className="absolute hidden lg:block"
          />

          <div className="relative z-10 pt-56 lg:pt-10">
            <Container>
              <h1 className="text-2xl font-extrabold text-white lg:w-[430px] lg:text-center lg:text-4xl">
                Prácticas{" "}
                <span className="block text-center font-handwritting text-[54px] font-medium text-s2-dark-blue lg:text-end lg:text-8xl">
                  Anticorrupción
                </span>
              </h1>
            </Container>
          </div>
        </div>
      </Container>

      <Section tint="ligther-gray">
        <Container className="mb-6">
          <div className="grid-cols-12 justify-between gap-8 lg:grid">
            <div className="col-span-7 mb-6">
              <div className="prose mt-12 text-balance rounded-[4px] border-l-4 border-s2-lime pl-4 text-s2-gray-600 lg:mt-0 lg:border-l-8 lg:pl-5 lg:text-lg">
                <p>
                  Conscientes del impacto económico, social y ambiental
                  inherente al sector de la construcción y servicios
                  inmobiliarios, así como de las posibles implicaciones de
                  permitir el acceso de Contrapartes sin un adecuado autocontrol
                  y gestión del riesgo LA/FT/FPADM y de Corrupción y Soborno
                  Transnacional, nuestra empresa se compromete a operar dentro
                  de un marco de responsabilidad social, ética y legal.
                </p>
                <p>
                  Este compromiso se refleja en nuestro gobierno corporativo,
                  basado en valores de excelencia, confiabilidad, experiencia
                  comercial diferenciadora y trabajo en equipo. La Junta
                  Directiva, Accionistas, Administradores y Colaboradores
                  comprenden la necesidad de gestionar estos riesgos en cada
                  práctica y procedimiento, exigiendo el mismo estándar a
                  nuestras Contrapartes internas y externas. Todos los
                  involucrados con Coninsa, ya sean Accionistas, Socios,
                  Colaboradores, Clientes o Proveedores, deben cumplir con las
                  políticas y procedimientos establecidos en nuestro Sistema de
                  Administración del Riesgo de Lavado de Activos y Financiación
                  del Terrorismo (SAGRILAFT) y en el Programa de Transparencia y
                  Ética Empresarial (PTEE).
                </p>
              </div>
            </div>
            <div className="col-span-5 self-start">
              <div className="mx-12 rounded-t-2xl bg-s2-mint px-20 py-5 text-center text-xl font-bold text-white lg:mx-24 lg:px-28 lg:py-8 lg:text-2xl">
                Comités
              </div>
              <div className="prose mb-4 rounded-lg bg-white p-7 text-s2-gray-600 shadow-[0px_3px_6px_#00000029]">
                <p className="font-bold lg:text-lg">
                  Certificamos que ninguno de los Accionistas, Miembros de la
                  Junta Directiva, Representantes Legales o Colaboradores es una
                  persona expuesta públicamente ni administra o maneja fondos
                  públicos.
                </p>
              </div>
            </div>
          </div>
        </Container>
      </Section>

      <Section className="relative -mt-16 lg:-mt-20">
        <Container>
          <SpeechBubble
            tint="mint"
            className="mb-12 px-9 py-2 text-xl font-bold lg:px-10 lg:text-2xl"
          >
            Gobierno
          </SpeechBubble>
          <div className="items-center justify-between gap-4 lg:flex">
            <h2 className="font-display text-4xl font-extralight uppercase text-s2-teal lg:text-5xl">
              Prácticas{" "}
              <span className="block font-medium text-s2-dark-blue">
                sostenibles
              </span>
            </h2>

            <Splide
              options={sliderOptions}
              className="s2-slider s2-slider--lg mt-5"
            >
              {data.map((item) => (
                <SplideSlide key={item.id}>
                  <BubbleCard.Root image={item.image} className="h-64 lg:h-72">
                    <BubbleCard.Content className="min-h-24 bg-s2-lime">
                      <h3 className="text-center text-lg font-bold leading-5 text-white lg:text-[22px]">
                        {item.title}
                      </h3>
                    </BubbleCard.Content>
                    <BubbleCard.Anchor href={item.url} />
                  </BubbleCard.Root>
                </SplideSlide>
              ))}
            </Splide>
          </div>
        </Container>
      </Section>
    </div>
  );
}
