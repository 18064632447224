import React, { useEffect, useState } from "react";
import clsx from "clsx";

import Container from "@coninsa-s2/container";
import { dom } from "@coninsa-s2/utils";

import UserIcon from "../icons/user.inline.svg";
import WalletIcon from "../icons/wallet.inline.svg";
import ConinsaIcon from "../icons/coninsa.inline.svg";

import "./styles.css";

export function MenuLink({
  label,
  url,
  nolink = false,
  level = 0,
  links = [],
  handleClick,
}) {
  const hasChildren = links.length > 0;
  return (
    <>
      {nolink && (
        <div
          className={clsx("s2-link s2-link--heading", {
            "s2-link--has-chidlren": hasChildren,
          })}
          level={level}
        >
          {label}
          <span className="s2-link__underline"></span>
        </div>
      )}

      {!nolink && (
        <a
          href={url?.path}
          className={clsx("s2-link", { "s2-link--has-chidlren": hasChildren })}
          level={level}
        >
          {label}
          <span className="s2-link__underline"></span>
        </a>
      )}

      {handleClick && hasChildren && (
        <button
          className="s2-link s2-link--dropdown"
          aria-expanded="false"
          onClick={handleClick}
          level={level}
          aria-label="Ver más"
        >
          {label}

          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.747 8.976">
            <g
              id="flecha-hacia-abajo-para-navegar"
              transform="translate(-0.001 -97.141)"
            >
              <path
                id="Trazado_1248"
                data-name="Trazado 1248"
                d="M7.874,106.117a1.1,1.1,0,0,1-.78-.323L.324,99.023a1.1,1.1,0,0,1,1.56-1.56l5.991,5.991,5.991-5.991a1.1,1.1,0,0,1,1.559,1.56l-6.771,6.771A1.1,1.1,0,0,1,7.874,106.117Z"
                fill="currentColor"
              />
            </g>
          </svg>
        </button>
      )}
    </>
  );
}

export default function Header({ menuLinks = [] }) {
  const [expanded, setExpanded] = useState(false);

  const handleLinkClick = (e) => {
    e.preventDefault();

    const button = e.currentTarget;
    const isExpandedLink = button.getAttribute("aria-expanded") == "true";
    button.setAttribute("aria-expanded", !isExpandedLink);
  };

  useEffect(() => {
    dom.setPageScrolling(expanded);
  }, [expanded]);

  return (
    <header className="s2-header">
      <div className="s2-header__top">
        <Container>
          <ul>
            <li>
              <a href="https://miconinsavirtual.coninsa.co/pagar-factura">
                <WalletIcon className="s2-header__top-icon" />
                Pago de factura
              </a>
            </li>
            <li>
              <a href="https://miconinsavirtual.coninsa.co">
                <UserIcon className="s2-header__top-icon" />
                Soy cliente
              </a>
            </li>
          </ul>
        </Container>
      </div>

      <div className="s2-header__inner">
        <Container className="s2-header__inner__container">
          <div>
            <a href="https://www.coninsa.co" aria-label="Coninsa">
              <ConinsaIcon />
            </a>
          </div>

          <button
            className="s2-header__menu"
            aria-label="Menu"
            aria-expanded={expanded}
            onClick={() => setExpanded(!expanded)}
          >
            <svg
              className="s2-header__menu__icon--hamburger"
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 30 30"
            >
              <g
                id="Grupo_654"
                data-name="Grupo 654"
                transform="translate(-1840.76 -56.76)"
              >
                <g id="frame" transform="translate(1841 57)">
                  <rect
                    id="Rectángulo_4"
                    data-name="Rectángulo 4"
                    width="30"
                    height="30"
                    transform="translate(-0.24 -0.24)"
                    fill="none"
                  />
                </g>
                <g
                  id="_5402398_list_menu_options_settings_checklist_icon"
                  data-name="5402398_list_menu_options_settings_checklist_icon"
                  transform="translate(1843.474 61.96)"
                >
                  <path
                    id="Trazado_164"
                    data-name="Trazado 164"
                    d="M27.261,9.307H5.551A1.6,1.6,0,0,1,4,7.653,1.6,1.6,0,0,1,5.551,6H27.261a1.6,1.6,0,0,1,1.551,1.653A1.6,1.6,0,0,1,27.261,9.307Z"
                    transform="translate(-4 -6)"
                    fill="#223983"
                  />
                  <path
                    id="Trazado_165"
                    data-name="Trazado 165"
                    d="M27.261,14.307H5.551a1.657,1.657,0,0,1,0-3.307H27.261a1.657,1.657,0,0,1,0,3.307Z"
                    transform="translate(-4 -2.733)"
                    fill="#223983"
                  />
                  <path
                    id="Trazado_166"
                    data-name="Trazado 166"
                    d="M27.261,19.307H5.551a1.657,1.657,0,0,1,0-3.307H27.261a1.657,1.657,0,0,1,0,3.307Z"
                    transform="translate(-4 0.533)"
                    fill="#223983"
                  />
                </g>
              </g>
            </svg>

            <svg
              className="s2-header__menu__icon--close"
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 30 30"
            >
              <g
                id="Grupo_654"
                data-name="Grupo 654"
                transform="translate(-1840.76 -56.76)"
              >
                <g id="frame" transform="translate(1841 57)">
                  <rect
                    id="Rectángulo_4"
                    data-name="Rectángulo 4"
                    width="30"
                    height="30"
                    transform="translate(-0.24 -0.24)"
                    fill="none"
                  />
                </g>
                <path
                  id="cerrar"
                  d="M12.01,10.286l7.9-7.9a1.315,1.315,0,0,0-1.86-1.86l-7.9,7.9-7.9-7.9a1.315,1.315,0,0,0-1.86,1.86l7.9,7.9-7.9,7.9a1.315,1.315,0,1,0,1.86,1.86l7.9-7.9,7.9,7.9a1.315,1.315,0,0,0,1.86-1.86Zm0,0"
                  transform="translate(1845.609 61.595)"
                  fill="#272727"
                />
              </g>
            </svg>
          </button>

          <ul className="s2-header__links">
            {menuLinks.map((link, index) => (
              <li
                key={`m-link-${index}`}
                className={clsx("s2-header__item", {
                  "s2-header__item--has-children": link.links.length,
                  "s2-header__item--single": !link.columns,
                  "s2-header__item--mega": link.columns,
                })}
              >
                <MenuLink
                  label={link.label}
                  url={link?.url}
                  links={link.links}
                  nolink={link.nolink}
                  handleClick={handleLinkClick}
                  level={0}
                />

                {link.links.length > 0 && (
                  <ul
                    className="s2-header__sublinks"
                    level="0"
                    style={{ gridTemplateColumns: link.columns ?? "1fr" }}
                  >
                    {link.links.map((subLink, subIndex) => (
                      <li
                        key={`m-sublink-${index}-${subIndex}`}
                        className="s2-header__item"
                      >
                        <MenuLink
                          label={subLink.label}
                          url={subLink?.url}
                          links={subLink.links}
                          nolink={subLink.nolink}
                          handleClick={handleLinkClick}
                          level={1}
                        />

                        {subLink.links.length > 0 && (
                          <ul className="s2-header__sublinks" level="1">
                            {subLink.links.map((subSubLink, subSubIndex) => (
                              <li
                                key={`m-subsublink-${index}-${subIndex}-${subSubIndex}`}
                                className="s2-header__item"
                              >
                                <MenuLink
                                  label={subSubLink.label}
                                  url={subSubLink?.url}
                                  links={subSubLink.links}
                                  nolink={subSubLink.nolink}
                                  handleClick={handleLinkClick}
                                  level={2}
                                />
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </Container>
      </div>
    </header>
  );
}
