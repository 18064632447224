import React from "react";
import Layout from "layout/default/Layout";

import CircleEconomyPage from "@coninsa-site/sustainability/src/pages/sustainability-environmental/pages/circle-economy"

function CircleEconomy() {
  return (
    <Layout>
      <CircleEconomyPage />
    </Layout>
  )
}

export default CircleEconomy;
