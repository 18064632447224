import React from "react";
import { cn } from "@coninsa-s2/utils";
import "./styles.css";

interface IconButtonProps {
  children: React.ReactNode;
  href?: string;
  className?: string;
}

const IconButton: React.FC<IconButtonProps> = ({
  children,
  href,
  className,
  ...props
}) => {
  return (
    <a className={cn("s2-icon-button", className)} {...props} href={href}>
      {children}
    </a>
  );
};

export default IconButton;
