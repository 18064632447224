import React from "react";
import Layout from "layout/default/Layout";

import SustainabilityEnvironmentalPage from "@coninsa-site/sustainability/src/pages/sustainability-environmental/pages"

function SustainabilityEnvironmental() {
  return (
    <Layout>
      <SustainabilityEnvironmentalPage />
    </Layout>
  )
}

export default SustainabilityEnvironmental;
