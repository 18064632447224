import React from "react";
import Layout from "layout/default/Layout";

import ContractorSuppliersPage from "@coninsa-site/sustainability/src/pages/sustainability-social/pages/contractor-suppliers"

function ContractorSuppliers() {
  return (
    <Layout>
      <ContractorSuppliersPage />
    </Layout>
  )
}

export default ContractorSuppliers;
