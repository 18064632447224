import React from "react";
import Layout from "layout/default/Layout";

import DiversityInclusionPage from "@coninsa-site/sustainability/src/pages/sustainability-social/pages/diversity-inclusion"

function DiversityInclusion() {
  return (
    <Layout>
      <DiversityInclusionPage />
    </Layout>
  )
}

export default DiversityInclusion;
