import React from "react";
import Layout from "layout/default/Layout";

import CustomerExperiencePage from "@coninsa-site/sustainability/src/pages/sustainability-social/pages/customer-experience"

function CustomerExperience() {
  return (
    <Layout>
      <CustomerExperiencePage />
    </Layout>
  )
}

export default CustomerExperience;
