import React from "react";
import clsx from "clsx";

import Title from "@coninsa-s2/title";
import FeatureItem from "@coninsa-s2/feature-item";

import "./styles.css";

interface ProjectThumbnailCardProps {
  location: string;
  imageUrl: string;
  title: string;
  logoUrl: string;
  url: string;
  className?: string;
  style?: React.CSSProperties;
}

const ProjectThumbnailCard: React.FC<ProjectThumbnailCardProps> = ({
  location,
  imageUrl,
  title,
  logoUrl,
  url,

  className,
  style = {},
  ...props
}) => {
  const dynamicClasses = {
    [`s2-project-thumbnail-card--featured`]: true,
  };

  return (
    <a
      href={url}
      target="_blank"
      className={clsx("s2-project-thumbnail-card", dynamicClasses, className)}
      {...props}
    >
      <div className="s2-project-thumbnail-card__media">
        <img src={imageUrl} alt="Picture" loading="lazy" height={260} />
      </div>

      <div className="s2-project-thumbnail-card__content">
        <img
          className="s2-project-thumbnail-card__logo"
          src={logoUrl}
          alt="Picture"
        />

        <div className="s2-project-thumbnail-card__body">
          <Title tint="blue" size="lg">
            {title}
          </Title>

          {location && (
            <FeatureItem icon="pin">
              <strong>{location}</strong>
            </FeatureItem>
          )}
        </div>
      </div>
    </a>
  );
};

export default ProjectThumbnailCard;
