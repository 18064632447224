import React, { useEffect, useState, useContext } from "react";
import { useForm } from "react-hook-form";

import formStorage from "./storage/form";
import quotationStorage from "./storage/quotation";
import { bootCalculator } from "./proxy/calculator";

import {
  CurrencyContext,
  getFormattedCurrencyValue,
} from "@coninsa-s2/currency";

export default function StepFour({ backCallback, nextCallback }) {
  const form = formStorage.get();
  const quotation = quotationStorage.get();
  const calculator = bootCalculator({ form, quotation, applyVariables: true });
  const [credit, setCredit] = useState();

  const currency = useContext(CurrencyContext);

  const {
    register,
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: { period: 10 },
  });

  const period = watch("period");

  useEffect(() => {
    if (period) {
      const credit = calculator?.creditoHipoticario.find(
        (item) => item.index == period
      );
      setCredit(credit);
    }
  }, [period]);

  const onSubmit = (data) => {
    formStorage.update(data);

    if (nextCallback) {
      nextCallback();
    }
  };

  return (
    <div className="co-card">
      <form className="co-form" onSubmit={handleSubmit(onSubmit)}>
        <h3 className="co-heading-4">
          ¿Cómo quedarán las cuotas con el banco?
        </h3>
        <p className="co-quotation__description">
          Entre más años, más bajas las cuotas. (Valores aproximados calculados
          con una tasa del 1.35% mensual).
        </p>

        <div className="co-range">
          <input
            className="co-range__input"
            type="range"
            min={10}
            max={20}
            defaultValue={10}
            step="5"
            {...register("period")}
          />

          <div className="co-range__footer">
            <div className="co-range__label">
              <div className="co-range__label__value">{10} años</div>
            </div>

            <div className="co-range__label">
              <div className="co-range__label__value">{15} años</div>
            </div>

            <div className="co-range__label">
              <div className="co-range__label__value">{20} años</div>
            </div>
          </div>
        </div>

        <div className="co-summary">
          <div>
            <h3 className="co-summary__value">
              {getFormattedCurrencyValue(credit?.ingresosReq, currency)}
            </h3>
            <p className="co-summary__label">Ingresos familiares requeridos</p>
          </div>
          <div>
            <h3 className="co-summary__value">
              {getFormattedCurrencyValue(credit?.valorCuota, currency)}
            </h3>
            <p className="co-summary__label">Cuota mensual aproximada</p>
          </div>
          <div>
            <h3 className="co-summary__value">{credit?.index} Años</h3>
            <p className="co-summary__label">Plazo de pago estimado</p>
          </div>
          <div>
            <h3 className="co-summary__value">
              {getFormattedCurrencyValue(credit?.valorCredito, currency)}
            </h3>
            <p className="co-summary__label">Plazo de pago estimado</p>
          </div>
          <div>
            <h3 className="co-summary__value">
              {getFormattedCurrencyValue(credit?.valorCuotaUVR, currency)}
            </h3>
            <p className="co-summary__label">Cuota UVR aproximada mensual *</p>
          </div>
        </div>

        <p className="co-summary__note">
          *El valor de la primera cuota en UVR corresponde a un valor aproximado
          en caso de tomar el crédito en UVR. No obstante, el valor real
          dependerá del valor de la UVR al momento del pago de la misma.
        </p>

        <p className="co-summary__note">
          *Los valores de las cuotas del crédito hipotecario son una estimación
          basada en la tasa suministrada por la entidad bancaria el día de la
          cotización, esta podrá cambiar sin previo aviso. Esta simulación no
          incluye subsidio Fresh a la tasa de interés entregado por el programa
          Mi casa ya (para proyectos VIS).
        </p>

        <div className="form-actions flex justify-between gap-2">
          <button
            type="button"
            onClick={backCallback}
            className="co-button is-secondary is-large has-fixed-icon"
          >
            <i className="co-icon icon-arrow"></i>
            Regresar
          </button>
          <button
            className="co-button is-progress-default is-large"
            disabled={isSubmitting}
          >
            {!isSubmitting ? "Generar simulación" : "Enviando..."}
          </button>
        </div>
      </form>
    </div>
  );
}
