import React from "react";
import clsx from "clsx";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "@coninsa-s2/slider/styles.css";

import Tag from "@coninsa-s2/tag";
import FeatureItem from "@coninsa-s2/feature-item";
import Button from "@coninsa-s2/button";

import defaultImage from "./images/default-image.jpg";

import "./styles.css";

function PropertyCard({
  title,
  price,
  code,
  area,
  bedrooms,
  bathrooms,

  url,
  images = [],
  tags: TagsElement,
  className,
}) {
  const options = {
    arrowPath:
      "M9.392,28.344h21.7l-7.467,7.467a1.392,1.392,0,1,0,1.969,1.968l9.843-9.843h0a1.4,1.4,0,0,0,.173-.212c.023-.035.04-.073.06-.11a1.394,1.394,0,0,0,.068-.13,1.238,1.238,0,0,0,.045-.144c.011-.039.026-.076.034-.115a1.391,1.391,0,0,0,.027-.273h0s0,0,0,0a1.4,1.4,0,0,0-.027-.269c-.008-.042-.024-.08-.036-.121a1.292,1.292,0,0,0-.043-.138,1.441,1.441,0,0,0-.072-.138c-.019-.034-.034-.069-.055-.1a1.371,1.371,0,0,0-.174-.214L25.59,16.124a1.392,1.392,0,1,0-1.969,1.969l7.467,7.467H9.392a1.392,1.392,0,1,0,0,2.784Z",
    arrows: true,
    type: "fade",
    perPage: 1,
    interval: 10000,
  };

  return (
    <div className={clsx("s2-property-card", className)}>
      <div className="s2-property-card__media">
        {images?.length > 0 ? (
          <Splide className="s2-slider s2-slider--sm" options={options}>
            {images.map((url, index) => (
              <SplideSlide key={index}>
                <img src={url} alt="Imagen de inmueble" />
              </SplideSlide>
            ))}
          </Splide>
        ) : (
          <img src={defaultImage} alt="Imagen de inmueble" />
        )}
      </div>

      <div className="s2-property-card__content">
        <div className="s2-property-card__tags">
          <TagsElement />
        </div>

        <div className="s2-property-card__title">{title}</div>
        <p className="s2-property-card__price">{price}</p>

        <div className="s2-property-card__details">
          <ul className="s2-property-card__features">
            <li>
              <FeatureItem icon="area">
                <strong>
                  {area} m<sup>2</sup>
                </strong>
              </FeatureItem>
            </li>
            {parseInt(bedrooms) > 0 && (
              <li>
                <FeatureItem icon="bed" className="leading-5">
                  <strong>{bedrooms}</strong>
                </FeatureItem>
              </li>
            )}

            {parseInt(bathrooms) > 0 && (
              <li>
                <FeatureItem icon="bath" className="leading-5">
                  <strong>{bathrooms}</strong>
                </FeatureItem>
              </li>
            )}
          </ul>
          <Tag variant="rounded">Código: {code}</Tag>
        </div>

        <Button href={url} target="_blank" inverted={true} size="fluid">
          Ver inmueble
        </Button>
      </div>
    </div>
  );
}

export default PropertyCard;
