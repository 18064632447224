import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "@coninsa-s2/slider/styles.css";

import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import * as BubbleCard from "@coninsa-s2/bubble-card";
import SpeechBubble from "@coninsa-s2/speech-bubble";
import IconButton from "@coninsa-s2/icon-button";

import biodiversityBanner from "@coninsa-site/sustainability/assets/images/environmental/biodiversity-banner.png";
import biodiversityMobileBanner from "@coninsa-site/sustainability/assets/images/environmental/biodiversity-mobile-banner.png";
import homeImage from "@coninsa-site/sustainability/assets/images/environmental/home.png";
import mobilehomeImage from "@coninsa-site/sustainability/assets/images/environmental/mobile-home.png";
import climateChangeImage from "@coninsa-site/sustainability/assets/images/environmental/climate-change.png";
import economyImage from "@coninsa-site/sustainability/assets/images/environmental/economy.png";
import constructionImage from "@coninsa-site/sustainability/assets/images/environmental/construction.png";
import LeftArrowIcon from "@coninsa-site/sustainability/assets/icons/left-arrow.inline.svg";

const data = [
  {
    id: "bio-01",
    url: "/corporativo/sostenibilidad/sostenibilidad-ambiental/economia-circular",
    image: economyImage,
    title: "Economía Circular",
  },
  {
    id: "bio-02",
    url: "/corporativo/sostenibilidad/sostenibilidad-ambiental/construccion-sostenible",
    image: constructionImage,
    title: "Construcción sostenible",
  },
  {
    id: "bio-03",
    url: "/corporativo/sostenibilidad/sostenibilidad-ambiental/cambio-climatico",
    image: climateChangeImage,
    title: "Cambio climático",
  },
];

export default function BiodiversityPage() {
  const sliderOptions = {
    arrows: false,
    gap: 12,
    perPage: 3,
    pagination: false,
    breakpoints: {
      640: {
        arrows: false,
        gap: 12,
        perPage: 1,
        padding: { left: 0, right: "6rem" },
        pagination: true,
      },
    },
  };

  return (
    <div>
      <Section tint="green" className="mb-5 !py-5 lg:mb-8">
        <Container>
          <div className="items-center gap-4 lg:flex">
            <IconButton
              href="/corporativo/sostenibilidad/sostenibilidad-ambiental"
              className="mb-5 lg:mb-0"
            >
              <LeftArrowIcon />
              Regresar
            </IconButton>
            <div className="text-2xl font-bold text-white">
              Sostenibilidad ambiental
            </div>
          </div>
        </Container>
      </Section>
      <Container className="mb-10">
        <div className="">
          <div className="flex justify-center">
            <img
              src={biodiversityMobileBanner}
              alt=""
              className="absolute block lg:hidden"
            />
          </div>

          <img
            src={biodiversityBanner}
            alt=""
            className="absolute hidden lg:block"
          />

          <div className="relative z-10 pt-56 lg:pt-10">
            <Container>
              <h1 className="pt-8 text-center font-handwritting text-[54px] font-medium text-s2-lime lg:w-[470px] lg:text-8xl">
                Biodiversidad
              </h1>
            </Container>
          </div>
        </div>
      </Container>

      <Section tint="ligther-gray">
        <Container>
          <div className="grid-cols-12 justify-between gap-6 lg:grid">
            <div className="col-span-7 mb-6 w-auto">
              <div className="prose rounded-[4px] border-l-4 border-s2-lime pl-4 text-s2-gray-600 lg:border-l-8 lg:pl-5 lg:text-lg">
                <p>
                  La biodiversidad desempeña un papel esencial para mantener el
                  equilibrio de los ecosistemas que coexisten con nosotros,
                  brindándonos una serie de servicios ecosistémicos
                  fundamentales para el desarrollo de las ciudades. Sin embargo,
                  el desarrollo constructivo de proyectos puede alterar el
                  suelo, la flora y la fauna de un territorio, modificando las
                  condiciones hidrogeológicas del paisaje y las dinámicas
                  socioambientales.
                </p>
                <p>
                  En Coninsa, nos comprometemos a mitigar los impactos generados
                  por nuestras operaciones, priorizando la protección de la
                  biodiversidad en las áreas donde llevamos a cabo nuestras
                  actividades. Esto se logra mediante la implementación de
                  planes de protección del suelo y manejo de fauna, además del
                  cumplimiento de las obligaciones establecidas en los permisos
                  de aprovechamiento forestal, todo ello en estricta conformidad
                  con la normatividad ambiental vigente.
                </p>
                <p>
                  <span className="font-bold">
                    Cada una de nuestras obras cuenta con un plan de manejo
                    ambiental que incluye una ficha específica para la gestión
                    de la fauna y la vegetación.
                  </span>{" "}
                  En esta se detallan las medidas de prevención, mitigación y
                  compensación de los impactos ambientales identificados.
                  Evaluamos mensualmente su implementación a través de los
                  indicadores de cada proyecto.
                </p>
              </div>
            </div>
            <div className="col-span-5">
              <div className="h-72 lg:h-72">
                <div className="flex justify-center">
                  <img
                    src={mobilehomeImage}
                    alt="Coninsa green icon"
                    className="z-50 block lg:hidden"
                  />
                  <img
                    src={homeImage}
                    alt="Coninsa green icon"
                    className="z-50 hidden lg:block"
                  />
                </div>
              </div>
              <div className="relative mx-auto -mt-14 hidden w-[400px] gap-4 rounded-b-2xl bg-s2-mint px-5 pb-8 pt-16 text-lg font-normal text-white lg:block">
                Como un ejemplo de conservación, en uno de nuestros proyectos
                realizamos el rescate de unas abejas angelitas, que estaban
                presentes en uno de los individuos arbóreos. Nos hemos dedicado
                a su cuidado y preservación, facilitando su reproducción.
              </div>
            </div>
          </div>
        </Container>
        <div className="relative -mt-16 mb-6 flex gap-3 rounded-2xl bg-s2-mint px-8 pb-6 pt-20 text-lg text-white lg:hidden">
          Como un ejemplo de conservación, en uno de nuestros proyectos
          realizamos el rescate de unas abejas angelitas, que estaban presentes
          en uno de los individuos arbóreos. Nos hemos dedicado a su cuidado y
          preservación, facilitando su reproducción.
        </div>
      </Section>

      <Section className="relative -mt-16 lg:-mt-20">
        <Container>
          <SpeechBubble
            tint="mint"
            className="z-50 mb-12 px-9 py-2 text-xl font-bold lg:px-10 lg:text-2xl"
          >
            Ambiental
          </SpeechBubble>
          <div className="items-center justify-between gap-4 lg:flex">
            <h2 className="font-display text-4xl font-extralight uppercase text-s2-teal lg:text-5xl">
              Prácticas{" "}
              <span className="block font-medium text-s2-dark-blue">
                sostenibles
              </span>
            </h2>

            <Splide
              options={sliderOptions}
              className="s2-slider s2-slider--lg mt-5"
            >
              {data.map((item) => (
                <SplideSlide key={item.id}>
                  <BubbleCard.Root image={item.image} className="h-64 lg:h-80">
                    <BubbleCard.Content className="min-h-24">
                      <h3 className="text-center text-lg font-bold text-white lg:text-[22px]">
                        {item.title}
                      </h3>
                    </BubbleCard.Content>
                    <BubbleCard.Anchor href={item.url} />
                  </BubbleCard.Root>
                </SplideSlide>
              ))}
            </Splide>
          </div>
        </Container>
      </Section>
    </div>
  );
}
