import axios from "axios";
import { getNormalizerServiceType } from "./utils";

const getNormalizedBranchOffice = (region) => {
  let sucursal;
  let regional;

  switch (region) {
    case "Antiquia":
      sucursal = "ABR SABANETA";
      regional = "Medellín";
      break;
    case "Cundinamarca":
      sucursal = "ABR BOGOTA SANTA BARBARA";
      regional = "Bogotá";
      break;
    case "Atlántico":
      sucursal = "ABR BARRANQUILLA BAHIA";
      regional = "Barranquilla";
      break;
    case "Bolívar":
      sucursal = "ABR BARRANQUILLA BAHIA";
      regional = "Barranquilla";
      break;
    case "Magdalena":
      sucursal = "ABR BARRANQUILLA BAHIA";
      regional = "Barranquilla";
      break;
    default:
      sucursal = "ABR SABANETA";
      regional = "Medellín";
      break;
  }
  return [sucursal, regional];
};

const getNormalizedOwnerId = (id) => {
  let adviserPhone;
  let idOwner;

  switch (id) {
    case "Antioquia":
      adviserPhone = "3241000075";
      idOwner = "2528071000008997151";
      break;
    case "Atlántico":
      adviserPhone = "3241000089";
      idOwner = "2528071000008997151";
      break;
    case "Bolívar":
      adviserPhone = "3241000089";
      idOwner = "2528071000008997151";
      break;
    case "Magdalena":
      adviserPhone = "3241000089";
      idOwner = "2528071000008997151";
      break;
    case "Cundinamarca":
      adviserPhone = "3241000082";
      idOwner = "2528071000008997151";
      break;
    default:
      adviserPhone = "3241000075";
      idOwner = "2528071000094806674";
      break;
  }

  return [adviserPhone, idOwner];
};

function getZohoProspectId(response) {
  const status = response?.status;
  return status === 200 ? response?.data?.data?.[0]?.details?.id : null;
}

export function sendZohoInfo(property, data, type) {
  const { region, propertyType, serviceType, code, canon } = property;
  const location = getNormalizedBranchOffice(region);
  const ownerId = getNormalizedOwnerId(region);
  const phone = data.phone;

  const zohoInfo = {
    data: [
      {
        First_Name: data.name,
        Last_Name: data.last_name,
        Owner: ownerId[1],
        Email: data.email,
        Sucursal: location[0],
        Regional: location[1],
        Tipo_de_Inmueble_de_Inter_s: propertyType,
        Servicio_interes: getNormalizerServiceType(serviceType),
        Origen_de_Informaci_n: "Digital",
        Medio_Publicitario: "www.coninsa.co",
        Mobile: phone,
        Canon_Precio_desde: Math.floor(canon),
        Tipo_de_Canal: "Portales web (virtuales)",
        Tipo_de_Contacto: "Otros",
        log_integracion: code,
        Layout: { id: "2528071000003925032" },
      },
    ],
  };

  const email = data?.email;
  const leaseUrl = "https://api.coninsa.co/api/v2/zohocrm-create-lead";
  const saleUrl = `https://api.coninsa.co/api/v2/hubspot-create-contact/${email}`;
  const zohoKardexUrl = "https://api.coninsa.co/api/v2/zoho-kardex";

  const url = leaseUrl; //isLeasing ? leaseUrl : saleUrl
  const info = zohoInfo; //isLeasing ? zohoInfo : hubspotInfo
  const msg = `https://api.whatsapp.com/send?phone=57${phone}&text=Hola! Los vi en coninsa.co, me gustaría obtener más información. Inmueble: ${code}`;

  return axios
    .post(url, info)
    .then((response) => {
      // Link lease property with Zoho kardex.
      const prospectId = getZohoProspectId(response);

      axios.post(zohoKardexUrl, {
        id_inmueble: code,
        id_prospecto: prospectId,
        origin: "Leads",
      });

      switch (type) {
        case "THANKS_PAGE":
          window.location.replace(
            `/gracias?destination=${window.location.pathname}`
          );
          break;

        case "WHATSAPP_PAGE":
          window.open(msg, "_blank");
          break;
      }
    })
    .catch((error) => {
      console.log(error);
    });
}
