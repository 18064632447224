import React from "react";
import Layout from "layout/default/Layout";

import SustainabilityContributionPage from "@coninsa-site/sustainability/src/pages/sustainability-contribution";

function SustainabilityContribution() {
  return (
    <Layout>
      <SustainabilityContributionPage />
    </Layout>
  );
}

export default SustainabilityContribution;
