import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "@coninsa-s2/slider/styles.css";

import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import * as BubbleCard from "@coninsa-s2/bubble-card";
import SpeechBubble from "@coninsa-s2/speech-bubble";
import IconButton from "@coninsa-s2/icon-button";

import circleEconomyBanner from "@coninsa-site/sustainability/assets/images/environmental/circle-economy-banner.png";
import circleEconomyMobileBanner from "@coninsa-site/sustainability/assets/images/environmental/circle-economy-mobile-banner.png";
import solidWasteImage from "@coninsa-site/sustainability/assets/images/environmental/solid-waste.svg";
import mobileSolidWasteImage from "@coninsa-site/sustainability/assets/images/environmental/mobile-solid-waste.svg";
import climateChangeImage from "@coninsa-site/sustainability/assets/images/environmental/climate-change.png";
import biodiversityImage from "@coninsa-site/sustainability/assets/images/environmental/biodiversity.png";
import constructionImage from "@coninsa-site/sustainability/assets/images/environmental/construction.png";
import LeftArrowIcon from "@coninsa-site/sustainability/assets/icons/left-arrow.inline.svg";

const data = [
  {
    id: "ce-01",
    url: "/corporativo/sostenibilidad/sostenibilidad-ambiental/cambio-climatico",
    image: climateChangeImage,
    title: "Cambio climático",
  },
  {
    id: "ce-02",
    url: "/corporativo/sostenibilidad/sostenibilidad-ambiental/biodiversidad",
    image: biodiversityImage,
    title: "Biodiversidad",
  },
  {
    id: "ce-03",
    url: "/corporativo/sostenibilidad/sostenibilidad-ambiental/construccion-sostenible",
    image: constructionImage,
    title: "Construcción sostenible",
  },
];

export default function CircleEconomyPage() {
  const sliderOptions = {
    arrows: false,
    gap: 12,
    perPage: 3,
    pagination: false,
    breakpoints: {
      640: {
        arrows: false,
        gap: 12,
        perPage: 1,
        padding: { left: 0, right: "6rem" },
        pagination: true,
      },
    },
  };

  return (
    <div>
      <Section tint="green" className="mb-5 !py-5 lg:mb-8">
        <Container>
          <div className="items-center gap-4 lg:flex">
            <IconButton
              href="/corporativo/sostenibilidad/sostenibilidad-ambiental"
              className="mb-5 lg:mb-0"
            >
              <LeftArrowIcon />
              Regresar
            </IconButton>
            <div className="text-2xl font-bold text-white">
              Sostenibilidad ambiental
            </div>
          </div>
        </Container>
      </Section>
      <Container className="mb-10">
        <div className="">
          <div className="flex justify-center">
            <img
              src={circleEconomyMobileBanner}
              alt=""
              className="absolute block lg:hidden"
            />
          </div>

          <img
            src={circleEconomyBanner}
            alt=""
            className="absolute hidden lg:block"
          />

          <div className="relative z-10 pt-56 lg:pt-10">
            <Container>
              <h1 className="text-center text-3xl font-extrabold text-white lg:w-[470px] lg:text-4xl">
                Economía{" "}
                <span className="block font-handwritting text-7xl font-medium text-s2-lime lg:text-8xl">
                  Circular
                </span>
              </h1>
            </Container>
          </div>
        </div>
      </Container>

      <Section tint="ligther-gray">
        <Container>
          <div className="grid-cols-12 justify-between gap-6 lg:grid">
            <div className="col-span-7 mb-6 w-auto">
              <div className="prose mb-4 rounded-[4px] border-l-4 border-s2-lime pl-4 text-s2-gray-600 lg:mb-6 lg:border-l-8 lg:pl-5 lg:text-lg">
                <p>
                  En nuestro enfoque de economía circular integramos la{" "}
                  <span className="font-bold">
                    gestión del recurso hídrico, energético y de residuos{" "}
                  </span>{" "}
                  para promover la circularidad en nuestros procesos.
                </p>
                <p>
                  Adoptamos prácticas para la gestión eficiente del recurso
                  hídrico, tales como la{" "}
                  <span className="font-bold">
                    reutilización y recirculación del agua{" "}
                  </span>
                  en nuestros procesos constructivos y la implementación de
                  tecnologías de conservación del agua en nuestras
                  instalaciones. Además, buscamos reducir nuestra huella hídrica
                  mediante la captación y tratamiento de aguas lluvias para usos
                  no potables, como el lavado de llantas y la humectación de
                  carreteables.
                </p>
                <p>
                  En cuanto al recurso energético, nos comprometemos a optimizar
                  su uso mediante la implementación de medidas de{" "}
                  <span className="font-bold"> eficiencia energética </span>en
                  nuestras operaciones. Esto incluye la adopción de tecnologías
                  más eficientes, la optimización de procesos y la promoción de
                  prácticas de ahorro energético entre nuestros colaboradores.
                </p>
                <p>
                  Tenemos un enfoque integral para nuestros residuos, abarca
                  desde la reducción en la fuente hasta la valorización y el
                  reciclaje de estos. Implementamos programas de{" "}
                  <span className="font-bold">separación y reciclaje</span> en
                  nuestras instalaciones, así como alianzas con proveedores y
                  gestores de residuos para asegurar una correcta disposición
                  final y promover la economía circular en toda la cadena de
                  suministro.
                </p>
                <p>
                  Al integrar la gestión del recurso hídrico, energético y de
                  residuos en nuestro modelo de economía circular, estamos
                  avanzando hacia una operación más sostenible y resiliente,
                  contribuyendo así a la conservación del medio ambiente y al
                  desarrollo de una economía más circular y regenerativa.
                </p>
              </div>
            </div>
            <div className="col-span-5">
              <div className="relative z-50 h-[426px] rounded-lg bg-white p-6 text-center shadow-[0px_3px_6px_#00000029] lg:h-72">
                <div className="mb-4 text-center text-lg font-bold text-s2-teal lg:text-left">
                  Gestión de residuos sólidos (t)
                </div>
                <div className="flex justify-center">
                  <img
                    src={mobileSolidWasteImage}
                    alt="Coninsa green icon"
                    className="block lg:hidden"
                  />
                  <img
                    src={solidWasteImage}
                    alt="Coninsa green icon"
                    className="hidden lg:block"
                  />
                </div>
              </div>
              <div className="relative -mt-6 hidden rounded-b-2xl bg-s2-mint px-10 pb-4 pt-28 text-xl font-medium text-white lg:mx-auto lg:block lg:w-[400px] lg:p-10 lg:text-2xl">
                Para el año 2023 tenemos como meta aprovechar el 48% de los
                residuos generados en nuestros proyectos a nivel nacional,
                sobrepasando las metas establecidas por la normatividad
                ambiental.
              </div>
            </div>
          </div>
        </Container>
        <div className="relative -mt-14 mb-6 block rounded-2xl bg-s2-mint p-8 pt-20 text-xl font-medium text-white lg:hidden">
          Para el año 2023 tenemos como meta aprovechar el 48% de los residuos
          generados en nuestros proyectos a nivel nacional, sobrepasando las
          metas establecidas por la normatividad ambiental.
        </div>
      </Section>

      <Section className="relative -mt-16 lg:-mt-20">
        <Container>
          <SpeechBubble
            tint="mint"
            className="mb-12 px-9 py-2 text-xl font-bold lg:!py-2 lg:px-12 lg:text-2xl"
          >
            Ambiental
          </SpeechBubble>
          <div className="items-center justify-between gap-4 lg:flex">
            <h2 className="font-display text-4xl font-extralight uppercase text-s2-teal lg:text-5xl">
              Prácticas{" "}
              <span className="block font-medium text-s2-dark-blue">
                sostenibles
              </span>
            </h2>

            <Splide
              options={sliderOptions}
              className="s2-slider s2-slider--lg mt-5"
            >
              {data.map((item) => (
                <SplideSlide key={item.id}>
                  <BubbleCard.Root image={item.image} className="h-72 lg:h-80">
                    <BubbleCard.Content className="min-h-24">
                      <h3 className="text-center text-lg font-bold text-white lg:text-[22px]">
                        {item.title}
                      </h3>
                    </BubbleCard.Content>
                    <BubbleCard.Anchor href={item.url} />
                  </BubbleCard.Root>
                </SplideSlide>
              ))}
            </Splide>
          </div>
        </Container>
      </Section>
    </div>
  );
}
