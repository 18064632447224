import React from "react";

import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import FigureCard from "@coninsa-s2/figure-card";
import Title from "@coninsa-s2/title";

const leftInfo = [
  {
    id: "letf-item-1",
    tag: "Construido",
    title: "+13mm",
    desc: "de metros cuadrados",
  },
  {
    id: "letf-item-2",
    tag: "Administramos en renta",
    title: "+6.700",
    desc: "activos entre vivienda y comercio de terceros.",
  },
  {
    id: "letf-item-3",
    tag: "Operamos",
    title: "+130",
    desc: "Inmuebles en multifamily.",
  },
];

const rightInfo = [
  {
    id: "right-item-1",
    tag: "Entregado",
    title: "+26.000",
    desc: "viviendas en Antioquia, Cundinamarca y Atlántico",
  },
  {
    id: "right-item-2",
    tag: "Diseñado",
    title: "+490",
    desc: "proyectos a nivel nacional",
  },
];

function ConinsaTimeline() {
  return (
    <Section className="bg-gradient-to-t lg:bg-gradient-to-l from-10% from-s2-gray-50 via-20% via-lime-50 to-60% to-s2-gray-50">
      <Container>
        <div className="grid-cols-2 items-center gap-4 lg:grid">
          <div className="mb-8 rounded-sm border-l-4 border-s2-lime p-4 lg:mb-0 lg:p-8">
            <h2 className="mb-8 flex w-[346px] items-center gap-4 lg:mb-14 lg:gap-6">
              <div>
                <Title size="5xl" className="!font-extrabold">
                  Coninsa
                </Title>{" "}
                <div className="text-end text-xl !font-[100] text-s2-dark-blue lg:text-3xl">
                  a través del{" "}
                </div>
              </div>
              <div className="font-handwritting text-4xl text-s2-teal lg:text-6xl">
                tiempo
              </div>
            </h2>
            <div>
              <h3 className="mb-2 font-display text-4xl font-medium text-s2-teal lg:mb-4 lg:text-5xl">
                Desde 1972
              </h3>
              <Title size="lg" className="mb-3 leading-6">
                Trabajamos para que los retos más importantes de nuestros
                clientes se hagan realidad.
              </Title>
              <p>
                Son más de 50 años trabajando de la mano de nuestros clientes,
                colaboradores, proveedores, aliados, contratistas y demás
                públicos de interés. Actuamos con determinación, transparencia y
                respeto dando fe de los valores de nos han identificado.
              </p>
            </div>
          </div>

          <div className="grid grid-cols-2 items-center gap-3 lg:gap-5">
            <div className="inline-flex flex-col gap-3 lg:gap-5">
              {leftInfo.map((item) => (
                <FigureCard key={item?.id}>
                  <div className="text-sm font-bold leading-4 text-s2-dark-blue lg:text-xl">
                    {item?.tag}
                  </div>
                  <Title size="4xl" tint="lime" className="mb-2 !font-bold">
                    {item?.title}
                  </Title>
                  <div className="text-sm text-s2-gray-600 lg:text-xl lg:leading-5">
                    {item?.desc}
                  </div>
                </FigureCard>
              ))}
            </div>
            <div className="inline-flex flex-col gap-3 lg:gap-5">
              {rightInfo.map((item) => (
                <FigureCard key={item?.id}>
                  <div className="text-sm font-bold text-s2-dark-blue lg:text-xl">
                    {item?.tag}
                  </div>
                  <Title size="4xl" tint="lime" className="mb-2 !font-bold">
                    {item?.title}
                  </Title>
                  <div className="text-sm text-s2-gray-600 lg:text-xl lg:leading-5">
                    {item?.desc}
                  </div>
                </FigureCard>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </Section>
  );
}

export default ConinsaTimeline;
