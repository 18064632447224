import React from "react";

import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import Heading from "@coninsa-s2/heading";
import IconCard from "@coninsa-s2/icon-card";
import Shape from "@coninsa-s2/shape";
import Title from "@coninsa-s2/title";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "@coninsa-s2/slider/styles.css";

import barsIcon from "../assets/icons/bars.svg";
import checkIcon from "../assets/icons/check.svg";
import peopleIcon from "../assets/icons/people.svg";
import thumbUpIcon from "../assets/icons/thumb-up.svg";
import handshakeIcon from "../assets/icons/handshake.svg";
import leafIcon from "../assets/icons/leaf.svg";

const data = [
  {
    id: "w-01",
    icon: barsIcon,
    title: "Nuestra Calidad se basa en la Gestión por Resultados",
    desc: "Trabajamos de permanentemente en el mejoramiento de los procesos, implementando planes de control y ejecutando oportunamente acciones que permitan obtener los resultados deseados por el Cliente.",
  },
  {
    id: "w-02",
    icon: checkIcon,
    title: "Nuestra solidez financiera es sinónimo de respaldo",
    desc: "La viabilidad de nuestros productos, el acertado manejo de créditos, el pago cumplido de obligaciones y el respaldo patrimonial de la Compañía, nos permiten obtener mejores precios con proveedores y aprovechar diversas oportunidades de negocios en las que el respaldo financiero es muy importante.",
  },
  {
    id: "w-03",
    icon: peopleIcon,
    title: "Un equipo humano que trabaja con orientación al Cliente",
    desc: "Cada año generamos más 1.800 empleos para profesionales en todas las ramas del sector Inmobiliario, del Diseño y la Construcción, conformando un equipo calificado de alto desempeño.",
  },
  {
    id: "w-04",
    icon: thumbUpIcon,
    title: "Cumplimos lo que prometemos",
    desc: "Ejecutamos nuestras obras basados en el control y el seguimiento de las metas, a través de sistemas que nos permiten planificar, de manera ágil y oportuna, las actividades y recursos requeridos. Manejamos el presupuesto eficientemente logrando una relación de gana - gana con nuestros Clientes.",
  },
  {
    id: "w-05",
    icon: handshakeIcon,
    title: "Alianzas estratégicas que suman conocimiento",
    desc: "Tenemos múltiples alianzas con diferentes especialistas y empresas del sector, que por su experiencia en áreas complementarias, se suman al conocimiento que el Cliente necesita para que sus proyectos sean competitivos. Esto le genera valor e integralidad a nuestro portafolio de servicios.",
  },
  {
    id: "w-06",
    icon: leafIcon,
    title: "Comprometidos con el cuidado del medio ambiente",
    desc: "Y en la línea con los objetivos de desarrollo sostenible, para que futuras generaciones puedan disfrutar de un mejor planeta. Integramos los principios de la inversión responsable generando valor para nuestras partes interesadas.",
  },
];

function BuildingWellbeing() {
  const sliderOptions = {
    arrowPath:
      "M9.392,28.344h21.7l-7.467,7.467a1.392,1.392,0,1,0,1.969,1.968l9.843-9.843h0a1.4,1.4,0,0,0,.173-.212c.023-.035.04-.073.06-.11a1.394,1.394,0,0,0,.068-.13,1.238,1.238,0,0,0,.045-.144c.011-.039.026-.076.034-.115a1.391,1.391,0,0,0,.027-.273h0s0,0,0,0a1.4,1.4,0,0,0-.027-.269c-.008-.042-.024-.08-.036-.121a1.292,1.292,0,0,0-.043-.138,1.441,1.441,0,0,0-.072-.138c-.019-.034-.034-.069-.055-.1a1.371,1.371,0,0,0-.174-.214L25.59,16.124a1.392,1.392,0,1,0-1.969,1.969l7.467,7.467H9.392a1.392,1.392,0,1,0,0,2.784Z",
    arrows: true,
    gap: 0,
    padding: { left: 0, right: "240px" },
    perPage: 2,
    perMove: 1,
    pagination: false,
    breakpoints: {
      640: {
        arrows: false,
        gap: 0,
        perPage: 1,
        padding: { left: 0, right: "2.5rem" },
        pagination: true,
      },
    },
  };

  return (
    <Section tint="ligther-gray">
      <Container>
        <div className="mb-2 lg:mb-4 lg:text-center">
          <div className="mb-4 lg:mb-6">
            <Heading
              as="h2"
              size="4xl"
              tint="teal"
              condensed
              className="mb-2 !font-semibold"
            >
              Construimos Bienestar
            </Heading>
            <div className="mx-auto text-lg font-semibold leading-6 lg:w-[700px] lg:text-xl">
              Para perdurar en el tiempo, generando valor para los actores que
              interactúan en nuestro ecosistema.
            </div>
          </div>
          <p>
            Trabajando como ecosistema a través de{" "}
            <span className="font-semibold">
              nuestras ventajas competitivas.
            </span>
          </p>
        </div>
        <Splide
          options={sliderOptions}
          className="s2-slider s2-slider--light lg:px-24"
        >
          {data.map((item) => (
            <SplideSlide>
              <div className="h-full px-2 pb-8 pt-2 lg:pb-4">
                <IconCard key={item.id}>
                  <Shape>
                    <img
                      src={item.icon}
                      alt="Icon"
                      className="h-20 w-20 lg:h-24 lg:w-24"
                    />
                  </Shape>
                  <Title size="md">{item.title}</Title>
                  <div className="mt-3 text-xs lg:text-sm">{item.desc}</div>
                </IconCard>
              </div>
            </SplideSlide>
          ))}
        </Splide>
      </Container>
    </Section>
  );
}

export default BuildingWellbeing;
