import React from "react";
import Layout from "layout/default/Layout";

import BiodiversityPage from "@coninsa-site/sustainability/src/pages/sustainability-environmental/pages/biodiversity"

function Biodiversity() {
  return (
    <Layout>
      <BiodiversityPage />
    </Layout>
  )
}

export default Biodiversity;
