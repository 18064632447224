import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "@coninsa-s2/slider/styles.css";

import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import Heading from "@coninsa-s2/heading";
import ImageCard from "@coninsa-s2/image-card";
import Title from "@coninsa-s2/title";

import rec24Image from "../assets/images/rec-2024.png";
import rec23Image from "../assets/images/rec-2023.png";
import rec19FirstImage from "../assets/images/rec-2019-i.png";
import rec19SecondImage from "../assets/images/rec-2019-ii.png";
import rec19ThirdImage from "../assets/images/rec-2019-iii.png";
import rec16Image from "../assets/images/rec-2016.png";
import rec14Image from "../assets/images/rec-2014.png";
import rec13Image from "../assets/images/rec-2013.png";
import rec12FirstImage from "../assets/images/rec-2012-i.png";
import rec12SecondImage from "../assets/images/rec-2012-ii.png";
import rec11FirstImage from "../assets/images/rec-2011-i.png";
import rec11SecondImage from "../assets/images/rec-2011-ii.png";
import rec10FirstImage from "../assets/images/rec-2010-i.png";
import rec10SecondImage from "../assets/images/rec-2010-ii.png";
import rec10ThirdImage from "../assets/images/rec-2010-iii.png";
import rec07Image from "../assets/images/rec-2007.png";
import rec06Image from "../assets/images/rec-2006.png";
import rec05Image from "../assets/images/rec-2005.png";
import rec00FirstImage from "../assets/images/rec-2000-i.png";
import rec00SecondImage from "../assets/images/rec-2000-ii.png";
import rec98Image from "../assets/images/rec-1998.png";

const data = [
  {
    id: "rec-01",
    photo: rec24Image,
    year: "2024",
    desc: `En los premios de <strong>Responsabilidad Social Empresarial de Camacol Antioquia</strong> fuimos ganadores en la categoría <strong>“Mejor práctica ambiental”</strong> con nuestro proyecto Gobernanza ambiental y legal.`,
  },
  {
    id: "rec-02",
    photo: rec23Image,
    year: "2023",
    desc: `Obtuvimos el primer puesto en la categoría de diseño <strong>“Otros usos”</strong> en el premio <strong>EXCELENCIA BIM Colombia 2023</strong>, con nuestro proyecto: “Centro Administrativo de Envigado”.`,
  },
  {
    id: "rec-03",
    photo: rec19FirstImage,
    year: "2019",
    desc: `<strong>Institución Emblemática de Antioquia</strong>. Condecoración de La SOCIEDAD ANTIOQUEÑA DE INGENIEROS Y ARQUITECTOS (SAI)`,
  },
  {
    id: "rec-04",
    photo: rec19SecondImage,
    year: "2019",
    desc: `<strong>Mejor Experiencia Ambiental</strong>. Reconocimiento de CAMACOL capítulo ANTIOQUIA Y NACIONAL por el programa “RECIRCULACIÓN DE AGUA EN PROCESOS DE PRODUCCIÓN DE CONCRETO”`,
  },
  {
    id: "rec-05",
    photo: rec19ThirdImage,
    year: "2019",
    desc: `<strong>Mejor Práctica de Gestión Socio Laboral</strong> Reconocimiento de CAMACOL ANTIOQUIA por el programa “Sello de Liderazgo.”`,
  },
  {
    id: "rec-06",
    photo: rec16Image,
    year: "2016",
    desc: `<strong>Distinción Mérito Empresarial</strong> Entregado por la Federación Nacional de Lonjas de Propiedad Raíz -FEDELONJAS por constribuir al desarrollo y engrandecimiento del sector inmobiliario.`,
  },
  {
    id: "rec-07",
    photo: rec14Image,
    year: "2014",
    desc: `<trong>Inmobiliario del Año 2008 y 2014</strong> Reconocimiento concedido por la Federación Internacional de Profesionales Inmobiliarios - FIABCI en los años 2008 y 2014.`,
  },
  {
    id: "rec-08",
    photo: rec13Image,
    year: "2013",
    desc: `<strong>Premio Nacional a la Trayectoria Empresarial</strong> Otorgado por la Cámara Colombiana de la Infraestructura - CCI en el año 2013`,
  },
  {
    id: "rec-09",
    photo: rec12FirstImage,
    year: "2012",
    desc: `<strong>Medalla San Rafael Arcángel.</strong> Concedida por el Ejército Nacional de Colombia por el diseño arquitectónico del Centro de Rehabilitación Héroes de Paramillo en el año 2012.`,
  },
  {
    id: "rec-10",
    photo: rec12SecondImage,
    year: "2012",
    desc: `<strong>40 años de vida empresarial</strong> Reconocimiento de la Cámara Colombiana de la Construcción Regional Antioquia, año 2012.`,
  },
  {
    id: "rec-11",
    photo: rec11FirstImage,
    year: "2011",
    desc: `<strong>Premio a la excelencia inmobiliaria Edificio Continental Bogotá D.C.</strong> Entregado por la FIABCI en el año 2011. Categoría Renovación y Gran Ganador. Edificio Continental Bogotá D.C.`,
  },
  {
    id: "rec-12",
    photo: rec11SecondImage,
    year: "2011",
    desc: `<strong>Premio obras CEMEX</strong> Finalista en la XX Edición con la construcción del Bloque de Ingenierías de la Universidad EAFIT (Medellín) en el año 2011.`,
  },
  {
    id: "rec-13",
    photo: rec10FirstImage,
    year: "2010",
    desc: `<strong>Inmobiliario Distinguido</strong> Reconocimiento de la Lonja de Propiedad Raíz de Medellín y Antioquia en los años 1993, 1996 y 2010.`,
  },
  {
    id: "rec-14",
    photo: rec10SecondImage,
    year: "2010",
    desc: `<strong>Empresas públicas de Medellín</strong> Reconocimiento del Cliente por las Obras en la Hidroeléctica Porce III.`,
  },
  {
    id: "rec-15",
    photo: rec10ThirdImage,
    year: "2010",
    desc: `<strong>Mérito inmobiliario</strong> Otorgado por la Lonja de Propiedad Raíz de Medellín y Antioquia por los escenarios deportivos de los IX Juegos Suramericanos en el año 2010.`,
  },
  {
    id: "rec-16",
    photo: rec07Image,
    year: "2007",
    desc: `<strong>Premio Excelencia Inmobiliaria Ciudadela Suramérica La Estrella</strong> Otorgado por la FIABCI. Categoría Residencial. Ciudadela Suramérica La Estrella- Antioquia en el año 2007.`,
  },
  {
    id: "rec-17",
    photo: rec06Image,
    year: "2006",
    desc: `<strong>Premio Excelencia en Concreto</strong> Conferido por ASOCRETO. Categoría Arquitectura en Concreto. Centro de Distribución ARGOS Medellín.`,
  },
  {
    id: "rec-18",
    photo: rec05Image,
    year: "2005",
    desc: `<strong>Almacenes Éxito</strong> Reconocimiento del Cliente por obras Punto de Venta Pereira.`,
  },
  {
    id: "rec-19",
    photo: rec00FirstImage,
    year: "2000",
    desc: `<strong>Premio Nacional de Ingeniería</strong> Por la construcción de las obras civiles de la Planta de tratamiento de aguas residuales San Fernando, Itagüí - Antioquia.`,
  },
  {
    id: "rec-20",
    photo: rec00SecondImage,
    year: "2000",
    desc: `<strong>Premio Excelencia en Concreto</strong> Otorgado por ASOCRETO. Categoría Obras Civiles. Planta de tratamiento de aguas residuales San Fernando, Itagüí - Antioquia.`,
  },
  {
    id: "rec-21",
    photo: rec98Image,
    year: "1998",
    desc: `<strong>Premio Excelencia en Concreto</strong> Concedido por ASOCRETO. Categoría Edificaciones. Diseño y construcción del Edificio Inteligente Empresas Públicas de Medellín.`,
  },
];

export default function OurRecognitions() {
  const sliderOptions = {
    arrowPath:
      "M9.392,28.344h21.7l-7.467,7.467a1.392,1.392,0,1,0,1.969,1.968l9.843-9.843h0a1.4,1.4,0,0,0,.173-.212c.023-.035.04-.073.06-.11a1.394,1.394,0,0,0,.068-.13,1.238,1.238,0,0,0,.045-.144c.011-.039.026-.076.034-.115a1.391,1.391,0,0,0,.027-.273h0s0,0,0,0a1.4,1.4,0,0,0-.027-.269c-.008-.042-.024-.08-.036-.121a1.292,1.292,0,0,0-.043-.138,1.441,1.441,0,0,0-.072-.138c-.019-.034-.034-.069-.055-.1a1.371,1.371,0,0,0-.174-.214L25.59,16.124a1.392,1.392,0,1,0-1.969,1.969l7.467,7.467H9.392a1.392,1.392,0,1,0,0,2.784Z",
    arrows: true,
    gap: 0,
    padding: { left: 0, right: "100px" },
    perPage: 4,
    perMove: 1,
    pagination: false,
    breakpoints: {
      640: {
        arrows: false,
        gap: 0,
        perPage: 1,
        padding: { left: 0, right: "6rem" },
        pagination: true,
      },
    },
  };

  return (
    <Section tint="dark-blue">
      <Container className="pb-6 lg:pb-20">
        <Heading
          as="h2"
          size="4xl"
          className="mb-8 text-center !font-extrabold !text-s2-lime lg:mb-16"
        >
          Nuestros{" "}
          <span className="block font-handwritting text-5xl !font-medium !leading-7 text-white lg:text-8xl lg:!leading-10">
            Reconocimientos
          </span>
        </Heading>

        <Splide
          options={sliderOptions}
          className="s2-slider s2-slider--lg s2-slider--lg__arrows-right s2-slider--lighting"
        >
          {data.map((item) => (
            <SplideSlide>
              <div className="h-full px-2 pb-4 pt-2">
                <ImageCard key={item.id} image={item.photo} classic>
                  <Title tint="teal" size="lg" className="mb-3 !font-bold">
                    {item.year}
                  </Title>
                  <div dangerouslySetInnerHTML={{ __html: item.desc }} />
                </ImageCard>
              </div>
            </SplideSlide>
          ))}
        </Splide>
      </Container>
    </Section>
  );
}
