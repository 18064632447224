import React, { useContext } from "react";
import { useForm } from "react-hook-form";

import formStorage from "./storage/form";
import quotationStorage from "./storage/quotation";
import { bootCalculator } from "./proxy/calculator";
import ZohoApi, { T_zoho_detail } from "./api/zoho-api";

import {
  CurrencyContext,
  getFormattedCurrencyValue,
} from "@coninsa-s2/currency";

interface T_props {
  backCallback: () => void;

  zohoDetail: T_zoho_detail;
}

export default function StepFive({ backCallback, zohoDetail }: T_props) {
  const form = formStorage.get();
  const quotation = quotationStorage.get();
  const calculator = bootCalculator({ form, quotation, applyVariables: true });

  const currency = useContext(CurrencyContext);

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({});

  const onSubmit = async () => {
    const data = await calculator.generarPdf();
    const url = data["data"]["url_last"];

    // Call zoho API client
    ZohoApi(formStorage.get(), url, zohoDetail);

    window.open(url, "_blank");
  };

  const projectName = calculator?.selectProject[0]["nombre"];

  const units = calculator?.selectedUnits ?? [];

  const onReset = () => {
    formStorage.clean();

    if (backCallback) {
      backCallback();
    }
  };

  return (
    <div className="co-card">
      <form className="co-form" onSubmit={handleSubmit(onSubmit)}>
        <h3 className="co-heading-4 text-center">
          Inmueble simulado | {projectName}
        </h3>

        <table className="mx-auto w-full max-w-3xl">
          <tbody>
            {units.map((unit) => (
              <tr key={unit.idUnidad}>
                <td>
                  {unit.descTipoUnidad} | {unit.descipcionTipoUnidad}
                </td>
                <td className="text-end">
                  {getFormattedCurrencyValue(unit.valor, currency)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <table className="mx-auto mb-4 w-full max-w-3xl">
          <tbody>
            <tr>
              <td className="font-semibold">
                Separación ({calculator.porcentajeSeparacion.toFixed(2)} %)
              </td>
              <td className="text-end">
                {getFormattedCurrencyValue(
                  calculator.valorSeparacion,
                  currency
                )}
              </td>
            </tr>
            <tr>
              <td className="font-semibold">
                Cuota inicial ({calculator.porcentajeCuotaInicial.toFixed(2)} %)
              </td>
              <td className="text-end">
                {getFormattedCurrencyValue(calculator.cuotainicial, currency)}
              </td>
            </tr>
            <tr>
              <td className="font-semibold">
                Crédito hipotecario ({calculator.porcentajeCredito.toFixed(2)}{" "}
                %)
              </td>
              <td className="text-end">
                {getFormattedCurrencyValue(calculator.credito, currency)}
              </td>
            </tr>
            <tr>
              <td className="font-semibold">Total:</td>
              <td className="text-end">
                {getFormattedCurrencyValue(calculator.totalCalculado, currency)}
              </td>
            </tr>
          </tbody>
        </table>

        <div className="form-actions flex justify-center gap-2">
          <button
            className="co-button is-progress-default is-large"
            disabled={isSubmitting}
          >
            {!isSubmitting ? "Ver simulación" : "Generando..."}
          </button>

          <button onClick={onReset} className="co-button is-secondary is-large">
            Nueva simulación
          </button>
        </div>

        <p className="co-summary__note text-center">
          “El valor de la separación es de{" "}
          {getFormattedCurrencyValue(calculator.valorSeparacion, currency)} y
          está incluido en el precio del inmueble”
        </p>
      </form>
    </div>
  );
}
