import React from "react";
import Layout from "layout/default/Layout";

import SustainableConstructionPage from "@coninsa-site/sustainability/src/pages/sustainability-environmental/pages/sustainable-construction"

function SustainableConstruction() {
  return (
    <Layout>
      <SustainableConstructionPage />
    </Layout>
  )
}

export default SustainableConstruction;
