import React, { useEffect, useState } from "react";

import { bootCalculator } from "@coninsa-ui/quotation/src/proxy/calculator";
import { getProject } from "@coninsa-ui/quotation/src/api/zoho-api";

function normalizeUser() {
  const user = {
    id: "",
    primerNombre: "",
    segundoNombre: "",
    primerApellido: "",
    segundoApellido: "",
    cedula: "",
    telefono: "",
    email: "",
    type_document: "",
    module: "",
  };

  return user;
}

export default function PaymentPlan({ projectCode, propertyCode }) {
  const [calculator, setCalculator] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dataResponse = await getProject(projectCode);

        const selectedUnit = dataResponse.units.find(
          (unit) => unit.idInmuebleABR == propertyCode
        );

        if (selectedUnit) {
          const quotation = {
            other_units: dataResponse.other_units,
            project: dataResponse.project,
            credit_parameters: dataResponse.credit_parameters,
            unit: selectedUnit,
            quote_concepts: dataResponse.quote_concepts,
          };
          const form = { user: normalizeUser() };
          const calc = bootCalculator({ form, quotation });

          setCalculator(calc);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div>Loading ...</div>;
  }

  if (!calculator) {
    return null;
  }

  return (
    <div>
      <div className="mb-5 text-center text-xs font-semibold text-blue-900">
        Forma de Pago para la torre o etapa señalada
      </div>
      <div className="flex flex-col justify-around gap-2 md:flex-row">
        <div className="mb-2 flex justify-between border-b border-gray-200 pb-2 md:block md:border-none">
          <div className="text-sm text-gray-500 opacity-100 md:mb-1 md:text-xs">
            Cuota de separación
          </div>
          <p className="font-display font-medium text-gray-900">
            $ {parseInt(calculator?.valorSeparacion).toLocaleString()}
          </p>
        </div>
        <div className="mb-2 flex justify-between border-b border-gray-200 pb-2 md:block md:border-none">
          <div className="text-sm text-gray-500 opacity-100 md:mb-1 md:text-xs">
            {calculator?.nCuotasVariables} cuota(s) mensuales
          </div>
          <p className="font-display font-medium text-gray-900">
            $ {parseInt(calculator?.valorCuota).toLocaleString()}
          </p>
          {/* <p className="text-sm text-gray-500 md:mb-1 md:text-xs">
          Y una cuota diferida de <span className="font-semibold text-gray-900">${calculator?.cuotasDiferidas?.valorCuota}</span>
        </p> */}
        </div>
        <div className="mb-2 flex justify-between md:block">
          <div className="text-sm text-gray-500 opacity-100 md:mb-1 md:text-xs">
            Valor restante
          </div>
          <p className="font-display font-medium text-gray-900">
            $ {parseInt(calculator?.credito).toLocaleString()}
          </p>
        </div>
      </div>
      <p className="mb-3 text-justify text-xs">
        Esta información corresponde al plan de pagos sugerido para esta torre,
        que toma siempre el precio desde del inmueble de menor valor y que
        podría modificarse de acuerdo a sus necesidades.{" "}
        <strong>¡Cuéntenos su forma de pago predilecta!</strong>.
      </p>
    </div>
  );
}
