import React from "react";
import Layout from "layout/default/Layout";

import AllyManagementPage from "@coninsa-site/sustainability/src/pages/sustainability-social/pages/ally-management"

function AllyManagement() {
  return (
    <Layout>
      <AllyManagementPage />
    </Layout>
  )
}

export default AllyManagement;
