import React from "react";
import { clsx } from "clsx";
import "./styles.css";

function Title({
  size = "lg",
  tint = "blue",
  condensed = false,
  weight = "default",
  className,
  ...props
}) {
  const dynamicClasses = {
    [`s2-title--${size}`]: size,
    [`s2-title--${tint}`]: tint,
    "s2-title--condensed": condensed,
    [`s2-title--${weight}`]: weight,
  };

  return (
    <div className={clsx("s2-title", dynamicClasses, className)} {...props}>
      {props.children}
    </div>
  );
}

export default Title;
