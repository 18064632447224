import React from "react";
import { cn } from "@coninsa-s2/utils";

import AddIcon from "./images/add.inline.svg";
import "./styles.css";

interface BubbleCardProps {
  image: string;
  children: React.ReactNode;
  className?: string;
}

export const BubbleCardRoot: React.FC<BubbleCardProps> = ({
  image,
  children,
  className,
  ...props
}) => {
  return (
    <div className={cn("s2-bubble-card", className)} {...props}>
      <img src={image} alt="Imagen" className="s2-bubble-card__image" />
      {children}
    </div>
  );
};

const BubbleCardContent: React.FC<{
  children: React.ReactNode;
  className?: string;
}> = ({ children, className, ...props }) => {
  return (
    <div
      className={cn(
        "s2-bubble-card__content",
        "text-white bg-s2-dark-blue flex items-center flex-col justify-center",
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
};

export const BubbleCardAnchor: React.FC<{
  href: string;
  children: React.ReactNode;
  className?: string;
}> = ({ href, className, ...props }) => {
  return (
    <a
      href={href}
      className={cn("s2-bubble-card__anchor", "bg-s2-lime/65", className)}
      {...props}
    >
      <AddIcon />
    </a>
  );
};

export {
  BubbleCardRoot as Root,
  BubbleCardContent as Content,
  BubbleCardAnchor as Anchor,
};
