import React from "react";

import ProjectCard from "@coninsa-s2/project-card";
import Tag from "@coninsa-s2/tag";
import { getProjectStateData } from "@coninsa-site/home/src/utils";

const ProjectSearchCard: React.FC = ({
  id,
  price = 0,
  name,
  code,
  location,
  url,
  imageUrl,

  state: stateId,
  isVis = false,
  isRenov = false,
  areaFrom,
  areaTo,
  brandUrl,
  description,
  withoutPriceFormatting = false,
}: {
  withoutPriceFormatting?: boolean;
}) => {
  const state = getProjectStateData(stateId);

  const areaElement = () => (
    <>
      <strong>
        Desde {areaFrom} m<sup>2</sup> hasta {areaTo} m<sup>2</sup>
      </strong>
      <br />
      <span className="text-sm">Áreas totales construidas</span>
    </>
  );

  const tagsElement = () => (
    <>
      {state && <Tag tint={state.tint}>{state.name}</Tag>}
      {isVis && <Tag tint="senary">VIS</Tag>}
      {isRenov && <Tag tint="senary">VIS Renov</Tag>}
    </>
  );

  const formattedPrice = !withoutPriceFormatting
    ? `$${parseInt(price).toLocaleString("es-CO")}`
    : price;

  return (
    <ProjectCard
      title={name}
      code={code}
      price={formattedPrice}
      location={location}
      url={url}
      imageUrl={imageUrl}
      logoUrl={brandUrl}
      description={description}
      area={areaElement}
      tags={tagsElement}
    />
  );
};

export default ProjectSearchCard;
