import React, { useEffect, useRef } from "react";
import Text from "@coninsa-s2/text";
import Heading from "@coninsa-s2/heading";
import ContactCard from "@coninsa-s2/contact-card";
import Container from "@coninsa-s2/container";
import Button from "@coninsa-s2/button";
import Section from "@coninsa-s2/section";
import Cloud from "@coninsa-s2/cloud";

import { useCheckMobileScreen } from "@coninsa-s2/hooks";

import data from "../data";

import "./styles.css";

import webtrust from "../images/webtrust-min.png";
import webtrustBr from "../images/webtrust-br-min.png";

import FacebookIcon from "../icons/facebook.inline.svg";
import YoutubeIcon from "../icons/youtube.inline.svg";
import PinterestIcon from "../icons/pinterest.inline.svg";
import InstagramIcon from "../icons/instagram.inline.svg";
import LinkedinIcon from "../icons/linkedin.inline.svg";

export default function Footer() {
  const footer = useRef();
  const isMobile = useCheckMobileScreen();

  useEffect(() => {
    const summaryItems = footer.current.querySelectorAll("details summary");

    if (isMobile) {
      summaryItems.forEach((summary) => {
        summary.removeAttribute("tabindex");
        summary.parentElement.removeAttribute("open");
      });
    } else {
      summaryItems.forEach((summary) => {
        summary.setAttribute("tabindex", -1);
        summary.parentElement.setAttribute("open", "");
      });
    }
  }, [isMobile]);

  return (
    <footer className="s2-footer" ref={footer}>
      <Container className="s2-footer__cloud">
        <Cloud variant="line" tint="lime" />
      </Container>

      <Section tint="light-gray" className="s2-footer__main-section">
        <Container>
          <div className="footer-contact-info">
            <details open>
              <summary>
                <Heading as="h3" size="lg" weight="bold">
                  {data.contactPhones.title}
                </Heading>
              </summary>

              <ul className="footer-contact-info__phones">
                {data.contactPhones.phones.map((item, index) => (
                  <li key={index}>
                    <ContactCard
                      icon={item.icon}
                      label={item.location}
                      highlighted={item.phone}
                    />
                  </li>
                ))}
              </ul>
            </details>

            <details open>
              <summary>
                <Heading as="h3" size="lg" weight="bold">
                  {data.headquartersSchedules.title}
                </Heading>
              </summary>
              <div className="mb-4">
                <Text className="mb-8">
                  <ul className="footer-contact-info__schedules">
                    {data.headquartersSchedules.headquarters.map(
                      (item, index) => (
                        <li key={index}>
                          <h4>{item.type}</h4>

                          <ul className="footer-contact-info__schedules-types">
                            {item.schedules.map((schedule, index) => (
                              <li
                                key={index}
                                dangerouslySetInnerHTML={{ __html: schedule }}
                              />
                            ))}
                          </ul>
                        </li>
                      )
                    )}
                  </ul>
                </Text>

                <Button
                  inverted={true}
                  href="https://www.coninsa.co/servicio-al-cliente"
                  target="_blank"
                >
                  Ampliar información
                </Button>
              </div>
            </details>

            <div className="footer-contact-info__logo">
              <img src={webtrust} alt="Weetrust logo" className="w-28" />
              <img src={webtrustBr} alt="Weetrust logo" className="w-28" />
            </div>
          </div>
        </Container>
      </Section>

      <Section tint="gray" className="s2-footer__secondary-section">
        <Container>
          <div className="footer-formats">
            <details open>
              <summary>
                <Heading as="h3" size="lg" weight="bold">
                  {data.formats.title}
                </Heading>
              </summary>

              <Text>
                <ul className="footer-formats__instructions">
                  {data.formats.links.map((item, index) => (
                    <li key={index}>
                      <a href={item.url}>{item.title}</a>
                    </li>
                  ))}
                </ul>
              </Text>
            </details>

            <details open>
              <summary>
                <Heading as="h3" size="lg" weight="bold">
                  {data.legals.title}
                </Heading>
              </summary>
              <Text>
                <ul>
                  {data.legals.links.map((item, index) => (
                    <li key={index}>
                      <a href={item.url}>{item.title}</a>
                    </li>
                  ))}
                </ul>
              </Text>
            </details>
          </div>

          <div className="footer-contact-info__logo-mobile">
            <img src={webtrust} alt="Weetrust logo" className="w-24" />
            <img src={webtrustBr} alt="Weetrust logo" className="w-24" />
          </div>
        </Container>
      </Section>

      <div className="s2-footer-bottom">
        <Container className="s2-footer-bottom__container">
          <p className="s2-footer-bottom__copyright">
            Todos los derechos reservados Coninsa SAS © 2024 <br /> Para una
            mejor experiencia en este portal te recomendamos usar uno de estos
            exploradores: Chrome, Mozilla u Opera.
          </p>

          <div className="s2-footer-bottom__social-media">
            <a href="https://www.facebook.com/coninsa.co" target="_blank">
              <FacebookIcon />
            </a>
            <a
              href="https://www.youtube.com/channel/UCT1ws2k5A-OqXGeuFjsUqKg"
              target="_blank"
            >
              <YoutubeIcon />
            </a>
            <a href="https://www.pinterest.es/coninsa_co/" target="_blank">
              <PinterestIcon />
            </a>
            <a href="https://www.instagram.com/coninsa.co" target="_blank">
              <InstagramIcon />
            </a>
            <a href="https://www.linkedin.com/company/coninsa" target="_blank">
              <LinkedinIcon />
            </a>
          </div>
        </Container>
      </div>
    </footer>
  );
}
