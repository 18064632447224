import React from "react";
import Layout from "layout/default/Layout"

import { LandingPage } from "@coninsa-site/sustainability";

function Landing() {
  return (
    <Layout>
      <LandingPage />
    </Layout>
  )
}

export default Landing;
