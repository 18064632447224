import React, { useState } from "react";
import PropTypes from "prop-types";

import { Icon } from "ui";

function FloatingWhatsappBtn({ path }) {
  const [open, setOpen] = useState(false);
  const showDialog = () => setOpen(true);

  return (
    <div>
      {open && (
        <div className="fixed bottom-[82px] right-3 z-50 flex flex-col gap-2 lg:bottom-8 lg:right-[90px]">
          <a
            href="https://api.whatsapp.com/send?phone=573123636333&text=Hola!"
            target="_blank"
            className="rounded-full bg-coninsa-green-500 px-4 py-2 text-center text-sm font-bold text-white shadow-lg hover:bg-coninsa-green-900 lg:px-6 lg:text-base"
          >
            Proyectos Nuevos
          </a>
          <a
            href="https://api.whatsapp.com/send?phone=573014726883&text=Hola!"
            target="_blank"
            className="rounded-full bg-coninsa-green-300 px-4 py-2 text-center text-sm font-bold text-white shadow-lg hover:bg-coninsa-green-900 lg:px-6 lg:text-base"
          >
            Arrendamientos
          </a>
        </div>
      )}
      <div className="fixed bottom-3 right-3 z-50">
        <button
          className="flex items-center gap-1 rounded-[42px] bg-coninsa-green-900 p-3 text-white no-underline hover:bg-coninsa-green-500"
          onClick={() => setOpen(!open)}
        >
          <Icon
            name="fa-whatsapp"
            sx={{
              fontSize: ["40px", "50px"],
              verticalAlign: "middle",
              flexShrink: 0,
            }}
          />
        </button>
      </div>
    </div>
  );
}

FloatingWhatsappBtn.propTypes = {
  path: PropTypes.string.isRequired,
};

export default FloatingWhatsappBtn;
