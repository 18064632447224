import React from "react";

import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import ImageCard from "@coninsa-s2/image-card";

import ForAbrUsers from "./for-abr-users";
import ForCbrUsers from "./for-cbr-users";

import rentImage from "../../images/rent-image.png";
import projectImage from "../../images/project-image.png";

export default function McvGuideLearnMore() {
  const [activeTab, setActiveTab] = React.useState(-1);

  return (
    <>
      <Section className="!pb-8 lg:!pb-14">
        <Container>
          <div className="mb-6 lg:mb-8">
            <h2 className="mb-3 font-display text-2xl font-extralight text-s2-dark-blue lg:mb-4 lg:text-center lg:text-4xl uppercase">
              Conoce más <span className="font-semibold">información</span>
            </h2>
            <p className="text-sm text-s2-gray-600 lg:text-center lg:text-base">
              Entérate de todo lo que puedes hacer, escoge el tipo de usuario
              que eres y comienza a ser parte de Mi Coninsa Virtual:
            </p>
          </div>

          <div className="flex flex-col justify-center gap-4 lg:flex-row">
            <ImageCard
              image={rentImage}
              slipped
              variant="compact"
              active={activeTab === 0}
              onClick={() =>
                activeTab !== 0 ? setActiveTab(0) : setActiveTab(-1)
              }
            >
              <div>
                Eres usuario de{" "}
                <span className="block font-extrabold">Arrendamientos</span>
              </div>
            </ImageCard>

            <div className="hidden lg:block">
              <ImageCard
                image={projectImage}
                slipped
                variant="compact"
                active={activeTab === 1}
                onClick={() =>
                  activeTab !== 1 ? setActiveTab(1) : setActiveTab(-1)
                }
              >
                <div>
                  Eres usuario de{" "}
                  <span className="block font-extrabold">Proyectos</span>
                </div>
              </ImageCard>
            </div>
          </div>
        </Container>
      </Section>

      {activeTab === 0 && <ForAbrUsers />}

      <Container>
        <div className="mb-8 block lg:hidden">
          <ImageCard
            image={projectImage}
            slipped
            variant="compact"
            active={activeTab === 1}
            onClick={() =>
              activeTab !== 1 ? setActiveTab(1) : setActiveTab(-1)
            }
          >
            <div>
              Eres usuario de{" "}
              <span className="block font-extrabold">Proyectos</span>
            </div>
          </ImageCard>
        </div>
      </Container>
      {activeTab === 1 && <ForCbrUsers />}
    </>
  );
}
