import React from "react";

import Container from "@coninsa-s2/container";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "@coninsa-s2/slider/styles.css";
import NumberCard from "@coninsa-s2/number-card";

function ListCard() {
  const sliderOptions = {
    arrows: false,
    gap: "2.5rem",
    perPage: 4,
    perMove: 1,
    pagination: false,
    breakpoints: {
      640: {
        arrows: false,
        gap: "2rem",
        perPage: 1,
        padding: { left: 0, right: "5rem" },
        pagination: true,
      },
    },
  };

  return (
    <Container>
      <div className="rounded-lg bg-white p-6 shadow-lg lg:p-8">
        <h2 className="mb-8 text-xl leading-6 text-co-dark-cyan md:text-3xl md:leading-8">
          <span className="block font-bold">Conoce el paso a paso</span> para
          referir a tus amigos o familiares
        </h2>

        <Splide options={sliderOptions} className="s2-slider s2-slider--light">
          <SplideSlide>
            <NumberCard number={1} className="pb-10" withArrow>
              <p>
                Sitienes algún amigo, familiar o conocido que esté buscando un{" "}
                <span className="font-bold">
                  inmueble en arriendo o tenga uno para arrendar cuéntales
                </span>
                , refiérelo con nosotros y gana.
              </p>
            </NumberCard>
          </SplideSlide>
          <SplideSlide>
            <NumberCard number={2} className="pb-10" withArrow>
              <p>
                <span className="font-bold">
                  Ingresa tus datos y los de la persona que refieres
                </span>
                . Es importante que identifiques y diligencies si tu referido es
                un arrendatario o un propietario.
              </p>
            </NumberCard>
          </SplideSlide>
          <SplideSlide>
            <NumberCard number={3} className="pb-10" withArrow>
              <p>
                <span className="font-bold">
                  Verificaremos que tu referido no se encuentre activo en
                  nuestros inmuebles{" "}
                </span>
                y así pueda ser contactado por uno de nuestros consultores.
              </p>
            </NumberCard>
          </SplideSlide>
          <SplideSlide>
            <NumberCard number={4} className="pb-10">
              <p>
                <span className="font-bold">Si tu referido es válido</span>, uno
                de nuestros consultores se comunicará contigo para confirmar tu
                beneficio.
              </p>
            </NumberCard>
          </SplideSlide>
        </Splide>
      </div>
    </Container>
  );
}

export default ListCard;
