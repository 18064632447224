import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "@coninsa-s2/slider/styles.css";

import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import * as BubbleCard from "@coninsa-s2/bubble-card";
import SpeechBubble from "@coninsa-s2/speech-bubble";
import Button from "@coninsa-s2/button";
import IconButton from "@coninsa-s2/icon-button";

import allyManagementBanner from "@coninsa-site/sustainability/assets/images/social/ally-management-banner.png";
import allyManagementMobileBanner from "@coninsa-site/sustainability/assets/images/social/ally-management-mobile-banner.png";
import pdfIcon from "@coninsa-site/sustainability/assets/images/social/pdf.svg";
import sheetIcon from "@coninsa-site/sustainability/assets/images/social/sheet.svg";
import checkIcon from "@coninsa-site/sustainability/assets/images/social/check.svg";
import LeftArrowIcon from "@coninsa-site/sustainability/assets/icons/left-arrow.inline.svg";

import contractorsImage from "@coninsa-site/sustainability/assets/images/social/contractors.png";
import diversityImage from "@coninsa-site/sustainability/assets/images/social/diversity.png";
import collaboratorImage from "@coninsa-site/sustainability/assets/images/social/collaborator.png";
import customerExperienceImage from "@coninsa-site/sustainability/assets/images/social/customer-experience.png";

const pillars = [
  {
    id: "pillar-1",
    url: "/corporativo/sostenibilidad/sostenibilidad-social/desarrollo-de-contratistas-y-proveedores/requisitos-empresas-contratistas",
    icon: sheetIcon,
    tag: "Requisitos generales para",
    title: "Las Empresas Contratistas",
  },
  {
    id: "pillar-2",
    // url: "/corporativo/sostenibilidad/sostenibilidad-social/desarrollo-de-contratistas-y-proveedores/requisitos-seleccion-contratistas",
    url: "/corporativo/sostenibilidad/sostenibilidad-social/desarrollo-de-contratistas-y-proveedores/#",
    icon: checkIcon,
    tag: "Requerimientos de",
    title: "Selección para Contratistas",
  },
];

const data = [
  {
    id: "ps-01",
    url: "/corporativo/sostenibilidad/sostenibilidad-social/desarrollo-de-contratistas-y-proveedores",
    image: contractorsImage,
    title: "Desarrollo de Contratistas y Proveedores",
  },
  {
    id: "ps-02",
    url: "/corporativo/sostenibilidad/sostenibilidad-social/diversidad-e-inclusion",
    image: diversityImage,
    title: "Diversidad e Inclusión",
  },
  {
    id: "ps-03",
    url: "/corporativo/sostenibilidad/sostenibilidad-social/bienestar-del-colaborador",
    image: collaboratorImage,
    title: "Bienestar del Coladorador",
  },
  {
    id: "ps-04",
    url: "/corporativo/sostenibilidad/sostenibilidad-social/experiencia-del-cliente",
    image: customerExperienceImage,
    title: "Experiencia del Cliente",
  },
];

export default function AllyManagementPage() {
  const sliderOptions = {
    arrows: false,
    gap: 12,
    perPage: 3,
    padding: { left: 0, right: "70px" },
    pagination: false,
    breakpoints: {
      640: {
        arrows: false,
        gap: 12,
        perPage: 1,
        padding: { left: 0, right: "5rem" },
        pagination: true,
      },
    },
  };

  return (
    <div>
      <Section tint="dark-blue" className="mb-5 !py-5 lg:mb-8">
        <Container>
          <div className="items-center gap-4 lg:flex">
            <IconButton
              href="/corporativo/sostenibilidad/sostenibilidad-social"
              className="mb-5 lg:mb-0"
            >
              <LeftArrowIcon />
              Regresar
            </IconButton>
            <div className="text-2xl font-bold text-white">
              Sostenibilidad Social
            </div>
          </div>
        </Container>
      </Section>
      <Container className="mb-10">
        <div className="">
          <div className="flex justify-center">
            <img
              src={allyManagementMobileBanner}
              alt=""
              className="absolute block lg:hidden"
            />
          </div>

          <img
            src={allyManagementBanner}
            alt=""
            className="absolute hidden lg:block"
          />

          <div className="relative z-10 pt-56 lg:pt-10">
            <Container>
              <h1 className="text-2xl font-extrabold text-white lg:w-[430px] lg:text-center lg:text-4xl">
                Gestión de{" "}
                <span className="block text-center font-handwritting text-7xl font-medium text-s2-lime lg:text-end lg:text-8xl">
                  Aliados
                </span>
              </h1>
            </Container>
          </div>
        </div>
      </Container>

      <Section tint="ligther-gray">
        <Container>
          <div className="grid-cols-12 justify-between gap-8 lg:grid">
            <div className="col-span-7 mb-6">
              <div className="prose mt-8 text-balance rounded-[4px] border-l-4 border-s2-lime pl-4 text-s2-gray-600 lg:mt-0 lg:border-l-8 lg:pl-5 lg:text-lg">
                <p>
                  En Coninsa entendemos que una gestión eficaz de contratistas y
                  proveedores es clave para nuestro éxito y nuestra integridad
                  como organización. No se trata solo de cumplir con los
                  estándares legales, sino de asegurar la calidad, eficiencia y
                  reputación en cada proyecto que emprendemos.
                </p>
                <p>
                  Nuestro enfoque en compras y contratación se centra en
                  garantizar un suministro y servicios legales, eficientes y
                  oportunos. Buscamos equilibrar el costo con el beneficio,
                  teniendo en cuenta las realidades económicas locales en las
                  que operamos.
                </p>
                <p>
                  Los valores corporativos de Coninsa son la columna vertebral
                  de nuestras acciones en todos los niveles, tanto interna como
                  externamente. Nuestros procesos de abastecimiento están
                  guiados por principios fundamentales: transparencia, buena fe,
                  calidad, costo, oportunidad, mitigación de riesgos, selección
                  objetiva y origen de los insumos.
                </p>
                <p>
                  En Coninsa, cada decisión de contratación y adquisición
                  refleja nuestro compromiso con la excelencia y la
                  responsabilidad. Estamos dedicados a construir relaciones
                  sólidas y éticas con nuestros socios comerciales, porque
                  sabemos que juntos podemos alcanzar nuestros objetivos de
                  manera sostenible.
                </p>
              </div>
            </div>
            <div className="col-span-5 self-start bg-s2-dark-blue px-4 py-10 lg:px-14 lg:py-16">
              <div className="mb-4 text-balance text-white">
                Con este manual, queremos aportarle beneficios a esa búsqueda
                integral de{" "}
                <span className="font-bold">“Construimos Bienestar”</span>.
              </div>
              <a
                className="flex gap-2 rounded-lg bg-white p-6 text-s2-dark-blue shadow-[0px_3px_6px_#00000029] hover:bg-s2-mint hover:text-white lg:inline-flex lg:gap-4"
                href="#"
              >
                <img src={pdfIcon} alt="PDF icon" />
                <div>
                  <div className="mb-2 text-balance text-lg !leading-5 lg:text-xl">
                    Manual de
                    <span className="block font-bold">
                      Gestión de Contratistas
                    </span>
                  </div>
                  <p className="text-s2-gray-600">Archivo PDF - 1200 Kb</p>
                </div>
              </a>
            </div>
          </div>
        </Container>
      </Section>

      <Section>
        <Container>
          <div className="mb-6 flex grid-cols-3 flex-col gap-4 lg:grid lg:items-center">
            <h2 className="mb-5 text-3xl font-extrabold text-s2-lime lg:mb-0 lg:text-center lg:text-5xl">
              Pilares{" "}
              <span className="block font-handwritting text-6xl font-medium text-s2-dark-blue lg:text-7xl">
                Principales
              </span>
            </h2>
            {pillars.map((item) => (
              <div
                key={item.id}
                className="rounded-lg bg-white px-5 py-7 shadow-[0px_3px_6px_#00000029] lg:px-9 lg:py-10"
              >
                <img
                  src={item.icon}
                  alt="Pillar icon"
                  className="mb-2 h-14 w-14 rounded-full bg-white object-contain p-2 shadow-[0px_3px_6px_#00000029] lg:mb-4 lg:h-20 lg:w-20 lg:p-4"
                />
                <div className="mb-5 lg:mb-7">
                  <div className="text-lg text-s2-gray-600 lg:text-xl">
                    {item.tag}
                  </div>
                  <h3 className="text-xl font-bold !leading-4 text-s2-dark-blue lg:text-2xl">
                    {item.title}
                  </h3>
                </div>
                <Button inverted href={item.url}>
                  Más información
                </Button>
              </div>
            ))}
          </div>
        </Container>
      </Section>

      <Section className="relative -mt-16 lg:-mt-20">
        <Container>
          <SpeechBubble
            tint="mint"
            className="mb-12 px-9 py-2 text-xl font-bold lg:px-10 lg:text-2xl"
          >
            Social
          </SpeechBubble>
          <div className="items-center justify-between gap-4 lg:flex">
            <h2 className="font-display text-4xl font-extralight uppercase text-s2-teal lg:text-5xl">
              Prácticas{" "}
              <span className="block font-medium text-s2-dark-blue">
                sostenibles
              </span>
            </h2>

            <Splide
              options={sliderOptions}
              className="s2-slider s2-slider--lg mt-5"
            >
              {data.map((item) => (
                <SplideSlide key={item.id}>
                  <BubbleCard.Root image={item.image} className="h-64 lg:h-72">
                    <BubbleCard.Content className="min-h-24 bg-s2-teal">
                      <h3 className="text-center text-lg font-bold text-white lg:text-xl">
                        {item.title}
                      </h3>
                    </BubbleCard.Content>
                    <BubbleCard.Anchor href={item.url} />
                  </BubbleCard.Root>
                </SplideSlide>
              ))}
            </Splide>
          </div>
        </Container>
      </Section>
    </div>
  );
}
