import React from "react";
import Layout from "layout/default/Layout";

import EmployeeWellbeingPage from "@coninsa-site/sustainability/src/pages/sustainability-social/pages/employee-wellbeing"

function EmployeeWellbeing() {
  return (
    <Layout>
      <EmployeeWellbeingPage />
    </Layout>
  )
}

export default EmployeeWellbeing;
