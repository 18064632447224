import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "@coninsa-s2/slider/styles.css";

import Banner from "@coninsa-s2/banner";
import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import * as BubbleCard from "@coninsa-s2/bubble-card";
import OurRecognitions from "../../../sections/our-recognitions";
import IconButton from "@coninsa-s2/icon-button";

import sustainabilityEconomicBanner from "@coninsa-site/sustainability/assets/images/economic/banner.png";
import sustainabilityEconomicMobileBanner from "@coninsa-site/sustainability/assets/images/economic/mobile-banner.png";
import economiPerformanceImage from "@coninsa-site/sustainability/assets/images/economic/economic-performance.png";
import digitalTransformImage from "@coninsa-site/sustainability/assets/images/economic/digital-transform.png";
import LeftArrowIcon from "@coninsa-site/sustainability/assets/icons/left-arrow.inline.svg";

const data = [
  {
    id: "se-01",
    url: "/corporativo/sostenibilidad/sostenibilidad-economica/desempeno-economico",
    picture: economiPerformanceImage,
    title: "Desempeño económico",
  },
  {
    id: "se-02",
    url: "/corporativo/sostenibilidad/sostenibilidad-economica/transformacion-digital",
    picture: digitalTransformImage,
    title: "Transformación digital, procesos e innovación",
  },
];

export default function SustainabilityEconomicPage() {
  const sliderOptions = {
    arrows: false,
    gap: 12,
    perPage: 2,
    padding: { left: "20rem", right: "20rem" },
    pagination: false,
    breakpoints: {
      640: {
        arrows: false,
        gap: 12,
        perPage: 1,
        padding: { left: 0, right: "5rem" },
        pagination: true,
      },
    },
  };

  return (
    <div>
      <Banner
        imageUrl={sustainabilityEconomicBanner}
        imageUrlMobile={sustainabilityEconomicMobileBanner}
      >
        <Container>
          <IconButton
            href="/corporativo/sostenibilidad"
            className="relative -mt-5 mb-4 lg:-mt-4 lg:mb-8"
          >
            <LeftArrowIcon />
            Regresar a sostenibilidad
          </IconButton>
          <h1 className="text-3xl font-extrabold text-white lg:w-[470px] lg:text-5xl">
            Sostenibilidad{" "}
            <span className="block text-center font-handwritting text-7xl font-medium text-s2-teal lg:text-end lg:text-9xl">
              Económica
            </span>
          </h1>
        </Container>
      </Banner>
      <Section tint="ligther-gray">
        <Container>
          <div className="mb-16 text-s2-gray-600 lg:text-lg">
            <span className="font-bold">
              En Coninsa somos conscientes que nuestras operaciones generan
              impactos en el entorno
            </span>
            , por esta razón, estamos comprometidos en la construcción de
            bienestar a través de la consolidación de la sostenibilidad, por eso
            hemos creado nuestra{" "}
            <span className="font-bold">Dirección de Sostenibilidad</span>{" "}
            enfocada en la creación de valor para nuestras partes interesadas
            considerando el crecimiento económico, el bienestar de las personas
            y la protección del ambiente.
          </div>

          <Splide
            options={sliderOptions}
            className="s2-slider s2-slider--lg mt-5"
          >
            {data.map((item) => (
              <SplideSlide key={item.id}>
                <BubbleCard.Root image={item.picture} className="h-64 lg:h-72">
                  <BubbleCard.Content className="min-h-24 bg-s2-green">
                    <h2 className="text-center text-lg font-bold lg:text-[22px]">
                      {item.title}
                    </h2>
                  </BubbleCard.Content>
                  <BubbleCard.Anchor href={item.url} />
                </BubbleCard.Root>
              </SplideSlide>
            ))}
          </Splide>
        </Container>
      </Section>
      <OurRecognitions />
    </div>
  );
}
