import React, { useEffect, useState } from "react";
import clsx from "clsx";

import CloseIcon from "@coninsa-s2/icon/assets/close.inline.svg";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "@coninsa-s2/slider/styles.css";

import "./styles.css";

type Advertisment = {
  id: string;
  image: { url: string };
  url: { uri: string };
};

interface AdsPushDownProps {
  items: Array<Advertisment>;
}

const AdsPushDown: React.FC<AdsPushDownProps> = ({ variant, items }) => {
  const [hidden, setState] = useState(items.length === 0);

  const options = {
    arrowPath:
      "M9.392,28.344h21.7l-7.467,7.467a1.392,1.392,0,1,0,1.969,1.968l9.843-9.843h0a1.4,1.4,0,0,0,.173-.212c.023-.035.04-.073.06-.11a1.394,1.394,0,0,0,.068-.13,1.238,1.238,0,0,0,.045-.144c.011-.039.026-.076.034-.115a1.391,1.391,0,0,0,.027-.273h0s0,0,0,0a1.4,1.4,0,0,0-.027-.269c-.008-.042-.024-.08-.036-.121a1.292,1.292,0,0,0-.043-.138,1.441,1.441,0,0,0-.072-.138c-.019-.034-.034-.069-.055-.1a1.371,1.371,0,0,0-.174-.214L25.59,16.124a1.392,1.392,0,1,0-1.969,1.969l7.467,7.467H9.392a1.392,1.392,0,1,0,0,2.784Z",
    arrows: items.length > 1,
    type: "fade",
    perPage: 1,
    interval: 5000,
    autoplay: true,
    pagination: true,
    rewind: true,
    breakpoints: {
      640: {
        arrows: false,
        perPage: 1,
        pagination: false,
      },
    },
  };

  return (
    <div
      className={clsx("s2-ads", {
        "s2-ads--hidden": hidden,
        [`s2-ads--${variant}`]: variant,
      })}
    >
      <div className="s2-ads__container">
        <button className="s2-ads__close" onClick={() => setState(true)}>
          <CloseIcon />
          Cerrar
        </button>

        <Splide
          options={options}
          className="s2-slider s2-slider--sm s2-slider--default s2-ads__slider"
        >
          {items.map(({ id, image, url }) => (
            <SplideSlide key={id}>
              <a
                style={{ display: "flex", justifyContent: "center" }}
                href={url?.uri}
                target="_blank"
              >
                <img src={image?.url} />
              </a>
            </SplideSlide>
          ))}
        </Splide>
      </div>
    </div>
  );
};

export default AdsPushDown;
