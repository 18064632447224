import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "@coninsa-s2/slider/styles.css";

import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import Heading from "@coninsa-s2/heading";
import Title from "@coninsa-s2/title";
import ImageCard from "@coninsa-s2/image-card";

import rec24Image from "@coninsa-site/we-are-coninsa/assets/images/rec-2024.png";
import rec24SecondImage from "../../assets/images/rec-2024-ii.jpg";
import rec24ThirdImage from "../../assets/images/rec-2024-iii.jpeg";
import rec24FourthImage from "../../assets/images/rec-2024-iv.jpg";
import rec23FirstImage from "@coninsa-site/we-are-coninsa/assets/images/rec-2023.png";
import rec23SecondImage from "../../assets/images/rec-s3.png";
import rec23ThirdImage from "../../assets/images/rec-s4.png";
import rec23FourthImage from "../../assets/images/rec-s5.png";

const data = [
  {
    id: "rec-s1",
    photo: rec24Image,
    year: "2024",
    desc: `Premio <strong>RSE Camacol Antioquia</strong> en la categoría <strong>Mejor práctica ambiental</strong> por el proyecto Gobernanza Ambiental y Legal.`,
  },
  {
    id: "rec-s2",
    photo: rec24SecondImage,
    year: "2024",
    desc: `Premio <strong>RSE Camacol Atlántico</strong> en la categoría <strong>Mejor Práctica Sociolaboral</strong> con el proyecto Escuelas Osteomusculares.`,
  },
  {
    id: "rec-s3",
    photo: rec24ThirdImage,
    year: "2024",
    desc: `Premio <strong>RSE Camacol Nacional</strong> en la categoría <strong>Práctica Ambiental</strong> por el proyecto Gobernanza Ambiental y Legal.`,
  },
  {
    id: "rec-s4",
    photo: rec24FourthImage,
    year: "2024",
    desc: `Reconocimiento <strong>Sello de Sostenibilidad</strong> en la categoría <strong>AA</strong> otorgado al proyecto Montiara.`,
  },
  {
    id: "rec-s5",
    photo: rec23FirstImage,
    year: "2023",
    desc: `Obtuvimos el primer puesto en la categoría de diseño <strong>“Otros usos”</strong> en el premio <strong>EXCELENCIA BIM Colombia 2023</strong>, con nuestro proyecto: “Centro Administrativo de Envigado”.`,
  },
  {
    id: "rec-s6",
    photo: rec23SecondImage,
    year: "2023",
    desc: `En el marco del <strong>Acuerdo Empresarial de Sostenibilidad y Transparencia</strong>, CAMACOL Bogotá y Cundinamarca, distinguió nuestra participación en la construcción del reporte de sostenibilidad del sector construcción.`,
  },
  {
    id: "rec-s7",
    photo: rec23ThirdImage,
    year: "2023",
    desc: `La obra Ponteverdi recibió el <strong>Sello de Sostenibilidad de Corantioquia</strong> en la categoría A.`,
  },
  {
    id: "rec-s8",
    photo: rec23FourthImage,
    year: "2023",
    desc: `ARGOS como proveedor y aliado, destacó nuestra participación en su programa <strong>“Sacos Verdes”</strong>, con el que logramos retornar más del 88% de los sacos de cemento que adquirimos durante el 2023.`,
  },
];

export default function OurRecognitions() {
  const sliderOptions = {
    arrowPath:
      "M9.392,28.344h21.7l-7.467,7.467a1.392,1.392,0,1,0,1.969,1.968l9.843-9.843h0a1.4,1.4,0,0,0,.173-.212c.023-.035.04-.073.06-.11a1.394,1.394,0,0,0,.068-.13,1.238,1.238,0,0,0,.045-.144c.011-.039.026-.076.034-.115a1.391,1.391,0,0,0,.027-.273h0s0,0,0,0a1.4,1.4,0,0,0-.027-.269c-.008-.042-.024-.08-.036-.121a1.292,1.292,0,0,0-.043-.138,1.441,1.441,0,0,0-.072-.138c-.019-.034-.034-.069-.055-.1a1.371,1.371,0,0,0-.174-.214L25.59,16.124a1.392,1.392,0,1,0-1.969,1.969l7.467,7.467H9.392a1.392,1.392,0,1,0,0,2.784Z",
    arrows: true,
    gap: 0,
    padding: { left: 0, right: "60px" },
    perPage: 4,
    perMove: 1,
    pagination: false,
    breakpoints: {
      640: {
        arrows: false,
        gap: 0,
        perPage: 1,
        padding: { left: 0, right: "6rem" },
        pagination: true,
      },
    },
  };

  return (
    <Section>
      <Container className="pb-6 lg:pb-20">
        <Heading
          as="h2"
          size="4xl"
          className="mb-8 text-center !font-extrabold !text-s2-lime lg:mb-16"
        >
          Nuestros{" "}
          <span className="block font-handwritting text-5xl !font-medium !leading-7 text-s2-dark-blue lg:text-8xl lg:!leading-10">
            Reconocimientos
          </span>
        </Heading>

        <Splide
          options={sliderOptions}
          className="s2-slider s2-slider--lg s2-slider--lg__arrows-right"
        >
          {data.map((item) => (
            <SplideSlide key={item.id}>
              <div className="h-full px-2 pb-4 pt-2">
                <ImageCard
                  image={item.photo}
                  classic
                  className="!h-[430px] lg:!h-[400px]"
                >
                  <Title tint="teal" size="lg" className="mb-3 !font-bold">
                    {item.year}
                  </Title>
                  <div
                    className="text-sm"
                    dangerouslySetInnerHTML={{ __html: item.desc }}
                  />
                </ImageCard>
              </div>
            </SplideSlide>
          ))}
        </Splide>
      </Container>
    </Section>
  );
}
