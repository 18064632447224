import React from "react";
import { graphql } from "gatsby";
import Layout from "../../layout/default/Layout";
import { Helmet } from "react-helmet";
import { Router, navigate } from "@reach/router";

import { SearchRentalHousingPage } from "@coninsa-site/property";
import FloatingWhatsappBtn from "modules/home/components/FloatingWhatsappBtn";

export function capitalizeFirstLetter(str) {
  return str[0].toUpperCase() + str.slice(1);
}

function Landing({ data, location }) {
  const cityName = location.pathname.match(/^\/arrendamientos\/vivienda\/ciudad\/*/)
    ? location.pathname.replace('/arrendamientos/vivienda/ciudad/', '').split('/')[0]
    : null;

  const title = cityName
    ? `Arrendamiento de inmuebles vivienda en ${capitalizeFirstLetter(cityName)} | Coninsa`
    : `Arrendamiento de inmuebles vivienda en Colombia | Coninsa`;

  const description = cityName
    ? `Descubre la mejor selección de viviendas en arrendamiento en ${capitalizeFirstLetter(cityName)} con Coninsa. Encuentra apartamentos y casas en alquiler en las mejores ubicaciones, con opciones que se adaptan a todas tus necesidades y presupuesto. Confía en nuestra experiencia y servicio para encontrar tu próximo hogar.`
    : `Descubre la mejor selección de viviendas en arrendamiento en Colombia con Coninsa. Encuentra apartamentos y casas en alquiler en las mejores ubicaciones, con opciones que se adaptan a todas tus necesidades y presupuesto. Confía en nuestra experiencia y servicio para encontrar tu próximo hogar.`

  return (
    <Layout
      headerAds={data?.drupal?.ads?.header}
      popupAds={data?.drupal?.ads?.popUp}
      footerAds={data?.drupal?.ads?.footer}
    >
      <Helmet>
        <title>{title}</title>
        <meta
          name="description"
          content={description}
        />
        <link
          rel="canonical"
          href={`https://www.coninsa.co${location.pathname}`}
        />
      </Helmet>

      <FloatingWhatsappBtn path="https://api.whatsapp.com/send?phone=573014726883&text=Hola!" />

      <Router basepath="/arrendamientos/vivienda">
        <SearchRentalHousingPage path="*" navigate={navigate} />
      </Router>
    </Layout>
  );
}

export const query = graphql`
  query ApartmentPageQuery {
    drupal {
      ads: getAdsByUrl(path: "/arrendamientos/vivienda") {
        header {
          id: nid
          image: fieldImage {
            url
          }
          url: fieldUrl {
            uri
            title
          }
        }
        popUp {
          id: nid
          image: fieldImage {
            url
          }
          url: fieldUrl {
            uri
            title
          }
        }
        footer {
          id: nid
          image: fieldImage {
            url
          }
          url: fieldUrl {
            uri
            title
          }
        }
      }
    }
  }
`;

export default Landing;
