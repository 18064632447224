import React from "react";

import Banner from "@coninsa-s2/banner";
import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import IconButton from "@coninsa-s2/icon-button";

import contributionBanner from "@coninsa-site/sustainability/assets/images/contribution/contribution-banner.png";
import contributionMobileBanner from "@coninsa-site/sustainability/assets/images/contribution/contribution-mobile-banner.png";
import odsImage from "@coninsa-site/sustainability/assets/images/contribution/ods.png";
import healthImg from "@coninsa-site/sustainability/assets/images/contribution/health.png";
import educationImg from "@coninsa-site/sustainability/assets/images/contribution/education.png";
import equalityImg from "@coninsa-site/sustainability/assets/images/contribution/equality.png";
import cleanWaterImg from "@coninsa-site/sustainability/assets/images/contribution/clean-water.png";
import energyImg from "@coninsa-site/sustainability/assets/images/contribution/energy.png";
import jobImg from "@coninsa-site/sustainability/assets/images/contribution/job.png";
import industryImg from "@coninsa-site/sustainability/assets/images/contribution/industry.png";
import citiesImg from "@coninsa-site/sustainability/assets/images/contribution/cities.png";
import productionImg from "@coninsa-site/sustainability/assets/images/contribution/production.png";
import LeftArrowIcon from "@coninsa-site/sustainability/assets/icons/left-arrow.inline.svg";

const data = [
  {
    id: "c-01",
    image: healthImg,
  },
  {
    id: "c-02",
    image: educationImg,
  },
  {
    id: "c-03",
    image: equalityImg,
  },
  {
    id: "c-04",
    image: cleanWaterImg,
  },
  {
    id: "c-05",
    image: energyImg,
  },
  {
    id: "c-06",
    image: jobImg,
  },
  {
    id: "c-07",
    image: industryImg,
  },
  {
    id: "c-08",
    image: citiesImg,
  },
  {
    id: "c-09",
    image: productionImg,
  },
];

export default function SustainabilityContributionPage() {
  return (
    <div>
      <Banner
        imageUrl={contributionBanner}
        imageUrlMobile={contributionMobileBanner}
      >
        <Container>
          <IconButton
            href="/corporativo/sostenibilidad"
            className="relative -mt-4 mb-4 lg:mb-8"
          >
            <LeftArrowIcon />
            Regresar a sostenibilidad
          </IconButton>
          <h1 className="text-3xl font-extrabold text-white lg:w-[470px] lg:text-5xl">
            Nuestro{" "}
            <span className="block text-center font-handwritting text-6xl font-medium !leading-3 text-s2-dark-blue lg:text-end lg:text-9xl lg:!leading-none">
              aporte{" "}
            </span>
            <span className="block pt-8 text-center leading-3 lg:pt-14 lg:text-end">
              a los ODS
            </span>
          </h1>
        </Container>
      </Banner>

      <Section tint="ligther-gray">
        <Container>
          <div className="mb-10 text-balance text-center text-s2-gray-600 lg:mb-16 lg:text-lg">
            La estrategia de sostenibilidad se alinea con los
            <span className="font-bold">
              Objetivos de Desarrollo Sostenible (ODS)
            </span>
            , contribuyendo activamente a alcanzar sus metas. Nos concentramos
            en orientar nuestras acciones hacia el desarrollo de un futuro lleno
            de esperanza para las futuras generaciones.
          </div>

          <div className="grid-cols-2 gap-4 lg:grid">
            <div className="mb-8 lg:mb-0">
              <img
                src={odsImage}
                alt=""
                className="rounded-[10px] bg-white p-8 shadow-[0px_3px_6px_#00000029] lg:p-10"
              />
            </div>
            <div className="mx-auto text-center lg:text-left">
              <h2 className="mb-5 inline-block rounded-lg bg-s2-dark-blue px-5 py-2 text-2xl font-bold text-white">
                Objetivos priorizados
              </h2>
              <div className="mx-auto flex flex-wrap justify-center gap-4 lg:grid lg:grid-cols-4">
                {data.map((item) => (
                  <img key={item.id} src={item.image} alt="" />
                ))}
              </div>
            </div>
          </div>
        </Container>
      </Section>

      <Section>
        <Container>
          <h3 className="mb-8 font-bold text-s2-gray-600 lg:mb-16 lg:w-[602px] lg:text-lg">
            Algunos de los programas a través de los cuales se abordan las metas
            de estos objetivos priorizados son:
          </h3>

          <div className="h-auto grid-cols-3 gap-6 pb-20 lg:grid lg:h-[600px]">
            <div className="h-[440px] border-l-2 border-s2-teal pl-8">
              <div className="mb-6">
                <div className="relative flex items-center gap-4">
                  <div className="absolute -left-11 bottom-0 right-0 top-0 h-6 w-6 rounded-full border-[3px] border-s2-teal bg-s2-lime" />
                  <h4 className="relative -mt-1 text-xl font-bold text-s2-lime lg:text-2xl">
                    Salud y bienestar
                  </h4>
                </div>

                <ul className="ml-5 list-outside list-disc text-s2-gray-600 marker:text-s2-teal lg:text-lg">
                  <li>Programa de bienestar ergonómico</li>
                  <li>Programa de bienestar mental</li>
                  <li>Programa de estilos de vida saludables</li>
                  <li>Ruta saludable</li>
                  <li>Programa + para ti: + calidad de vida</li>
                  <li>Flexitrabajo</li>
                  <li>Programa + para ti: + salud</li>
                  <li>Sello de liderazgo</li>
                </ul>
              </div>
              <div className="mb-6">
                <div className="relative flex items-center gap-4">
                  <div className="absolute -left-11 h-6 w-6 rounded-full border-[3px] border-s2-teal bg-s2-lime" />
                  <h4 className="text-xl font-bold text-s2-lime lg:text-2xl">
                    Educación de calidad
                  </h4>
                </div>

                <ul className="ml-5 list-outside list-disc text-s2-gray-600 marker:text-s2-teal lg:text-lg">
                  <li>Programa + para ti: + educación</li>
                  <li>Programa obras escuela</li>
                  <li>Coninplan</li>
                </ul>
              </div>
              <div>
                <div className="relative flex items-center gap-4">
                  <div className="absolute -left-11 h-6 w-6 rounded-full border-[3px] border-s2-teal bg-s2-lime" />
                  <h4 className="text-xl font-bold text-s2-lime lg:text-2xl">
                    Igualdad de género
                  </h4>
                </div>

                <ul className="ml-5 list-outside list-disc text-s2-gray-600 marker:text-s2-teal lg:text-lg">
                  <li>
                    Buenas prácticas orientadas a la diversidad e inclusión
                  </li>
                </ul>
              </div>
            </div>

            <div className="h-[530px] lg:h-auto">
              <div className="h-[700px] border-l-2 border-s2-teal pl-8 lg:h-[400px]">
                <div className="mb-6">
                  <div className="relative flex items-center gap-4">
                    <div className="absolute -left-11 bottom-0 right-0 top-0 h-6 w-6 rounded-full border-[3px] border-s2-teal bg-s2-lime" />
                    <h4 className="relative -mt-1 text-xl font-bold text-s2-lime lg:text-2xl">
                      Agua limpia y saneamiento
                    </h4>
                  </div>
                  <ul className="ml-5 list-outside list-disc text-s2-gray-600 marker:text-s2-teal lg:text-lg">
                    <li>Programa de gestión integral del recurso hídrico</li>
                  </ul>
                </div>
                <div className="mb-6">
                  <div className="relative flex items-center gap-4">
                    <div className="absolute -left-11 bottom-0 right-0 top-0 h-6 w-6 rounded-full border-[3px] border-s2-teal bg-s2-lime" />
                    <h4 className="relative -mt-1 text-xl font-bold text-s2-lime lg:text-2xl">
                      <span className="block font-light leading-4">
                        Energía asequible
                      </span>{" "}
                      y no contaminante
                    </h4>
                  </div>

                  <ul className="ml-5 list-outside list-disc text-s2-gray-600 marker:text-s2-teal lg:text-lg">
                    <li>Coninsa + Verde</li>
                  </ul>
                </div>
                <div className="mb-6">
                  <div className="relative flex items-center gap-4">
                    <div className="absolute -left-11 bottom-0 right-0 top-0 h-6 w-6 rounded-full border-[3px] border-s2-teal bg-s2-lime" />
                    <h4 className="relative -mt-1 text-xl font-bold text-s2-lime lg:text-2xl">
                      <span className="block font-light leading-4">
                        Trabajo decente y{" "}
                      </span>{" "}
                      crecimiento económico
                    </h4>
                  </div>
                  <ul className="ml-5 list-outside list-disc text-s2-gray-600 marker:text-s2-teal lg:text-lg">
                    <li>Flexitrabajo</li>
                    <li>Conexión Coninsa</li>
                    <li>Programas de gestión TAR</li>
                    <li>Reconversión de mano de obra</li>
                  </ul>
                </div>
                <div>
                  <div className="relative flex items-center gap-4">
                    <div className="absolute -left-11 bottom-0 right-0 top-0 h-6 w-6 rounded-full border-[3px] border-s2-teal bg-s2-lime" />
                    <h4 className="relative -mt-1 text-xl font-bold text-s2-lime lg:text-2xl">
                      <span className="block font-light leading-4">
                        Industria, innovación{" "}
                      </span>{" "}
                      e infraestructura
                    </h4>
                  </div>

                  <ul className="ml-5 list-outside list-disc text-s2-gray-600 marker:text-s2-teal lg:text-lg">
                    <li>BIM</li>
                    <li>Gestión documental CAD</li>
                    <li>Transformación digital</li>
                    <li>Caja de herramientas</li>
                    <li>ADN comercial</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="h-[430px] lg:h-auto">
              <div className="h-[180px] border-l-2 border-s2-teal pb-32 pl-8 lg:h-[200px]">
                <div className="mb-6">
                  <div className="relative flex items-center gap-4">
                    <div className="absolute -left-11 bottom-0 right-0 top-0 h-6 w-6 rounded-full border-[3px] border-s2-teal bg-s2-lime" />
                    <h4 className="relative -mt-1 text-xl font-bold text-s2-lime lg:text-2xl">
                      <span className="block font-light leading-4">
                        Ciudades y{" "}
                      </span>
                      comunidades sostenibles
                    </h4>
                  </div>

                  <ul className="ml-5 list-outside list-disc text-s2-gray-600 marker:text-s2-teal lg:text-lg">
                    <li>Coninsa + Verde</li>
                    <li>Coninplan</li>
                    <li>
                      Programa de reconocimientos de proveedores y contratistas
                    </li>
                  </ul>
                </div>
                <div>
                  <div className="relative flex items-center gap-4">
                    <div className="absolute -left-11 bottom-0 right-0 top-0 h-6 w-6 rounded-full border-[3px] border-s2-teal bg-s2-lime" />
                    <h4 className="relative -mt-1 text-xl font-bold text-s2-lime lg:text-2xl">
                      <span className="block font-light leading-4">
                        Producción y{" "}
                      </span>{" "}
                      consumo sostenible
                    </h4>
                  </div>

                  <ul className="ml-5 list-outside list-disc text-s2-gray-600 marker:text-s2-teal lg:text-lg">
                    <li>Coninsa + Verde</li>
                    <li>Coninsa circular</li>
                    <li>Sistematización selección BPMS</li>
                    <li>Programa de gestión integral de residuos sólidos</li>
                    <li>Programa 8 desperdicios</li>
                    <li>Programa cero papel</li>
                    <li>Programa sacos verdes</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Section>
    </div>
  );
}
