import React from "react";

import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import Banner from "@coninsa-s2/banner";
import DialogBox from "@coninsa-s2/dialog-box";
import Heading from "@coninsa-s2/heading";

import ConinsaTimeline from "./src/timeline";
import OurPortfolio from "./src/our-portfolio";
import BuildingWellbeing from "./src/building-wellbeing";
import OurValues from "./src/our-values";
import OurRecognitions from "./src/our-recognitions";
import Members from "./src/members";
import Certifications from "./src/certifications";

import consignmentImage from "./assets/images/banner.png";
import consignmentMobileImage from "./assets/images/mobile-banner.png";
import mapImage from "./assets/images/colombia-map.png";

function WeAreConinsa() {
  return (
    <>
      <Banner
        imageUrl={consignmentImage}
        imageUrlMobile={consignmentMobileImage}
      >
        <Container>
          <div className="text-white text-center lg:text-left">
            <h1 className="text-2xl font-extrabold lg:mb-3 lg:text-5xl">
              <span className="lg:pl-7">Construimos Bienestar,</span>
              <DialogBox
                tint="lime"
                className="!px-6 lg:!w-[640px] text-center"
              >
                <div className="text-3xl text-s2-dark-blue lg:text-6xl">
                  transformándonos <br /> en un ecosistema
                </div>
              </DialogBox>
            </h1>

            <p className="text-left mt-2 pl-10 text-xl font-medium leading-5 lg:mt-4 md:pl-7 lg:text-4xl lg:leading-10">
              que brinda soluciones <br /> integrales para el hábitat.
            </p>
          </div>
        </Container>
      </Banner>

      <ConinsaTimeline />
      <OurPortfolio />
      <Section className="bg-gradient-to-t from-lime-50 !pb-0">
        <Container>
          <div className="lg:grid grid-cols-2 gap-6 items-center flex flex-col-reverse">
            <img src={mapImage} alt="Colombia map" />
            <div className="mx-auto">
              <Heading
                as="h2"
                size="4xl"
                condensed
                className="mb-5 !font-light"
              >
                Nuestra{" "}
                <span className="font-semibold text-s2-teal">Cobertura</span>
                <span className="block text-lg lg:text-3xl font-sans text-s2-gray-600 !font-extrabold">
                  A través del país
                </span>
              </Heading>

              <p>
                Contamos con presencia en Antioquia, Cartagena, Barranquilla,
                Santa Marta, Bogotá y sus alrededores, lo que nos permite
                atender de manera integral, clientes en todas las líneas de
                negocios.
              </p>
            </div>
          </div>
        </Container>
      </Section>
      <BuildingWellbeing />
      <OurValues />
      <OurRecognitions />
      <Members />
      <Certifications />
    </>
  );
}

export default WeAreConinsa;
