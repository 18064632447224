import React from "react";

import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import Heading from "@coninsa-s2/heading";
import Card from "@coninsa-s2/card";
import Button from "@coninsa-s2/button";
import Title from "@coninsa-s2/title";

import ArrowRightIcon from "../../home/icons/arrow-right.inline.svg";

const data = [
  {
    id: "project-card-1",
    subtitle: "Servicios de",
    title: "arrendamientos y venta de usados",
    description:
      "Encuentra tu inmueble ideal de comercio o vivienda más de 2000 inmuebles para conocer.",
    url: "/arrendamientos",
    tint: "lime",
  },

  {
    id: "project-card-2",
    subtitle: "Portafolio de",
    title: "proyectos nuevos",
    description:
      "Explora nuestra sección de proyectos y encuentra el lugar ideal para vivir o invertir.",
    url: "/proyectos",
    tint: "green",
  },
  {
    id: "project-card-3",
    subtitle: "Servicios de diseño",
    title: "Arquitectónico y Técnico",
    description:
      "Nuestros servicios van desde el diseño urbanístico hasta el arquitectónico y de interiorismo.",
    url: "/arquitectura",
    tint: "teal",
  },

  {
    id: "project-card-4",
    subtitle: "Servicios de construcción",
    title: "de edificaciones e infraestructura",
    description:
      "Ofrecemos soluciones innovadoras y competitivas como respuesta a sus necesidades.",
    url: "/construccion",
    tint: "dark-blue",
  },
];

function OurPortfolio() {
  return (
    <Section>
      <Container>
        <div className="mb-8 lg:text-center">
          <Heading as="h2" size="4xl" condensed className="mb-5 !font-light">
            Nuestro{" "}
            <span className="font-semibold text-s2-teal">Portafolio</span>
          </Heading>
          <p className="text-balance lg:px-48">
            <span className="font-bold">
              La Compañía cuenta con un portafolio de servicios de arquitectura,
              ingeniería, construcción y bienes raíces, único en el sector, que
              le permite entregar a los clientes soluciones integrales y a la
              medida
            </span>
            , desde la construcción, el diseño técnico y arquitectónico, venta,
            arrendamiento y operación de inmuebles o patrimonios. Se encuentra
            en los sectores de vivienda, comercio, industria, institucional e
            infraestructura.
          </p>
        </div>

        <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
          {data?.map((item) => (
            <Card key={item.id} tint={item.tint}>
              <Title tint={item.tint} size="2xl" condensed>
                {item?.subtitle} <br />
                <span className="is-highlighted">{item?.title}</span>
              </Title>
              <p>{item?.description}</p>
              <Button href={item?.url} target="_blank">
                Conoce más
              </Button>
              <a href={item?.url} target="_blank">
                <ArrowRightIcon className="s2-card__content-icon" />
              </a>
            </Card>
          ))}
        </div>
      </Container>
    </Section>
  );
}

export default OurPortfolio;
