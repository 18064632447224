import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import PropTypes from "prop-types";
import { Box, Input, Button, Checkbox, Label } from "theme-ui";
import "react-intl-tel-input-18/dist/main.css";
import parsePhoneNumber from "libphonenumber-js";
import IntlPhone from "@coninsa-s2/intl-phone";

import { Icon } from "ui";
import HabeasData from "component-v2/HabeasData";

function ProjectContactForm({ zohoDetail }) {
  const [lat, setLat] = useState();
  const [lon, setLon] = useState();
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({ defaultValues: { phone: "" } });

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setLat(position.coords.latitude);
      setLon(position.coords.longitude);
    });
  }, []);

  const onSubmit = async (data) => {
    const info = {
      data: [
        {
          First_Name: data.name,
          Last_Name: data.last_name,
          Owner: { id: zohoDetail?.owner },
          Mobile: data.phone,
          Email: data.email,
          Regional: zohoDetail?.regional,
          Origen_de_Informaci_n: "Digital",
          Estrategia_de_Campa_a: "Portal",
          Medio_Publicitario: "www.coninsa.co",
          Tipo_de_Canal: "Digital",
          Tipo_de_Contacto: "Otros",
          Layout: { id: 2528071000000091055 },
          Proyecto_de_interes: { id: zohoDetail?.interestedProject },
        },
      ],
    };

    return new Promise((resolve, reject) => {
      axios.post("https://admindrupal.coninsa.co/projects/contact-form", info);

      axios
        .post("https://api.coninsa.co/api/v2/zohocrm-create-lead", info)
        .then((response) => {
          reset();
          resolve(response);
          window.location.replace(
            `/gracias?destination=${window.location.pathname}`
          );
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  };

  const baseStyles = {
    input: {
      fontSize: [1, 0],
      mb: "xsmall",
      py: [2, 1],
    },
  };

  const phoneStyles = {
    mb: "xsmall",
    ".allow-dropdown.intl-tel-input": { display: "block" },
    input: {
      width: "100%",
      border: "1px solid #d0d1d1",
      borderRadius: "4px",
      lineHeight: "inherit",
    },
  };

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)} mt={4}>
      <Box
        sx={{
          color: "neutral",
          fontFamily: "heading",
          fontWeight: "heading",
          mb: "small",
          textTransform: "uppercase",
        }}
      >
        Contáctame
      </Box>

      <Box sx={baseStyles} mb="xsmall" register={register}>
        <Input {...register("name")} id="name" placeholder="Nombres" required />
        <Input
          {...register("last_name")}
          id="last_name"
          placeholder="Apellidos"
          required
        />

        <Box sx={ phoneStyles }>
          <Controller
            control={control}
            name="phone"
            rules={{
              validate: (value) =>
                parsePhoneNumber(value)?.isValid() ||
                "Número de teléfono inválido",
            }}
            render={({ field: { onChange, value } }) => (
              <IntlPhone
                id="phone"
                placeholder="Número de contacto"
                defaultCountry={"co"}
                onChange={onChange}
                required
                value={value}
              />
            )}
          />

          {errors?.phone && (
            <p className="text-xs text-s2-red text-left mt-1">{errors?.phone?.message}</p>
          )}
        </Box>

        <Input
          {...register("email")}
          id="email"
          placeholder="Correo electrónico"
          required
        />

        <HabeasData />

        <Label>
          <Checkbox {...register("accept_conditions")} required />
          <Box sx={{ fontSize: [1, 0], textAlign: "left" }}>
            <span>Autorizo el tratamiento y la </span>
            <Box
              as="a"
              href="https://www.coninsa.co/politica-de-tratamiento-de-datos-personales-de-coninsa-ramon-h-sa"
              sx={{ color: "primary", textDecoration: "none" }}
              target="_blank"
            >
              política de datos personales
            </Box>
          </Box>
        </Label>
      </Box>

      <Button
        sx={{
          bg: "accent",
          color: "white",
          mt: "xsmall",
          py: ["small", "xsmall"],
          width: "100%",
        }}
        type="submit"
        variant="sharing"
      >
        <Icon name="fi-phone" sx={{ mr: "10px", verticalAlign: "middle" }} />
        Recibir una llamada
      </Button>
    </Box>
  );
}

ProjectContactForm.propTypes = {
  buildingPath: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default ProjectContactForm;
