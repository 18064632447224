import React from "react";

import Banner from "@coninsa-s2/banner";
import Container from "@coninsa-s2/container";
import DialogBox from "@coninsa-s2/dialog-box";

import cookiePolicyImage from "../images/banner.png";
import cookiePolicyMobileImage from "../images/mobile-banner.png";

export default function CookiePolicyBanner() {
  return (
    <Banner
      imageUrl={cookiePolicyImage}
      imageUrlMobile={cookiePolicyMobileImage}
      className="flex items-start lg:items-center lg:pl-16"
    >
      <Container>
        <h1 className="text-white">
          <span className="pb-3 text-3xl font-semibold lg:text-5xl block">
            Conoce nuestra{" "}
          </span>

          <DialogBox tint="teal" className="!pr-16">
            <span className="text-4xl font-extrabold leading-7 lg:text-6xl">
              Política de <br /> cookies
            </span>
          </DialogBox>
        </h1>
      </Container>
    </Banner>
  );
}
