import { gql } from "@apollo/client";

export const PROPERTY_FRAGMENT = gql`
  fragment BuildingPropertyTeaser on BuildingProperty {
    __typename
    code
    propertyType: fieldPropertyType {
      entity {
        name
      }
    }
    serviceType: fieldServiceType
    location: fieldLocation {
      entity {
        name
        parent {
          entity {
            name
            parent {
              entity {
                name
              }
            }
          }
        }
      }
    }
    images: fieldImages {
      derivative(style: IMAGENESDETALLE) {
        url
      }
      alt
      title
    }
    habiImages: externalImages
    sourceProvider: fieldSourceProvider
    leaseCanon: leaseValue
    saleCanon: saleValue
    area: fieldArea
    status: fieldIsNew
    stratum: fieldStratum
    bedrooms: fieldBedrooms
    bathrooms: fieldBathrooms
    propertyDescription: fieldDescripcionInmueble {
      value
    }
    features: fieldFeatures {
      entity {
        name
        parent {
          entity {
            name
          }
        }
      }
    }
    state: fieldState {
      entity {
        id: tid
        name
        ... on TaxonomyTermBuildingState {
          machineName: fieldMachineName
          variant: fieldVariant
        }
      }
    }
    project {
      id
      code
      projectFeatures: fieldProjectFeatures {
        entity {
          name
        }
      }
      projectImages: fieldImages {
        derivative(style: LARGE96) {
          url
        }
        alt
      }
    }
  }
`;

export const GET_PROPERTY_BY_CODE = gql`
  query BuildingByCode($code: String!) {
    building: buildingByCode(code: $code) {
      id: entityId
      url: entityUrl {
        path
      }
      propertyUrl: entityUrl {
        path
      }
      headerAds: ads(type: "header") {
        id: nid
        image: fieldImage {
          url
        }
        url: fieldUrl {
          uri
          title
        }
      }
      popUpAds: ads(type: "pop_up") {
        id: nid
        image: fieldImage {
          url
        }
        url: fieldUrl {
          uri
          title
        }
      }
      footerAds: ads(type: "footer") {
        id: nid
        image: fieldImage {
          url
        }
        url: fieldUrl {
          uri
          title
        }
      }
      ...BuildingPropertyTeaser

      ... on BuildingProperty {
        __typename
        projectCode: fieldProjectCode
        fairPrice: fieldFairPrice
        scratchPrices: fieldScratchPrices
        fairText: fieldFairText
        adminValue: fieldAdministrationValue
        propertyValue: propertyValuation
        related: relatedContent {
          results {
            ... on BuildingProperty {
              id
              images: fieldImages {
                alt
                derivative(style: IMAGECARD) {
                  url
                }
              }
              url: entityUrl {
                path
              }
              propertyType: fieldPropertyType {
                entity {
                  name
                }
              }
              serviceType: fieldServiceType
              code
              area: fieldArea
              relatedLocation: fieldLocation {
                entity {
                  name
                  parent {
                    entity {
                      name
                      parent {
                        entity {
                          name
                        }
                      }
                    }
                  }
                }
              }
              leaseValue
              state: fieldState {
                entity {
                  id: tid
                  name
                  ... on TaxonomyTermBuildingState {
                    machineName: fieldMachineName
                    variant: fieldVariant
                  }
                }
              }
            }
          }
        }
        latLon: fieldLatLong {
          value
          geoType
          lat
          lon
          left
          top
          right
          bottom
          geohash
          latlon
        }
      }
    }
  }
  ${PROPERTY_FRAGMENT}
`;

export const GET_PROPERTY_SHEET_BY_CODE = gql`
  query BuildingByCode($code: String!) {
    building: buildingByCode(code: $code) {
      id: entityId
      url: entityUrl {
        path
      }
      propertyUrl: entityUrl {
        path
      }
      ...BuildingPropertyTeaser
    }
  }
  ${PROPERTY_FRAGMENT}
`;
