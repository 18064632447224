import React from "react";

import referPropertyImage from "../../images/refer-property-banner.png";
import referPropertyImageMobile from "../../images/refer-property-mobile.png";

function ReferPropertyBanner() {
  return (
    <div className="grid grid-cols-1 bg-s2-dark-blue lg:grid-cols-2">
      <img
        src={referPropertyImage}
        className="hidden w-full object-cover lg:block"
      />
      <img src={referPropertyImageMobile} className="w-full lg:hidden" />

      <div className="flex flex-col justify-center px-4 py-4 lg:px-0 lg:py-8 xl:w-[40vw]">
        <div className="lg:max-w-md">
          <h1 className="mb-4 text-xl font-extrabold !leading-none text-white md:text-2xl xl:text-4xl">
            Tus amigos y familiares merecen las mejores recomendaciones de
            inversión{" "}
            <span className="text-co-teal">
              cuéntales acerca de nuestros servicios de inmuebles en arriendo o
              venta de usados.
            </span>
          </h1>

          <div className="text-white lg:text-xl">
            ¡Refiérelo con nosotros y gana!
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReferPropertyBanner;
