import React from "react";
import Layout from "layout/default/Layout";

import EconomicPerformancePage from "@coninsa-site/sustainability/src/pages/sustainability-economic/pages/economic-performance"

function EconomicPerformance() {
  return (
    <Layout>
      <EconomicPerformancePage />
    </Layout>
  )
}

export default EconomicPerformance;
