import React from "react";
import Layout from "layout/default/Layout";

import HumanRightsPage from "@coninsa-site/sustainability/src/pages/corporate-governance/pages/human-rights"

function HumanRights() {
  return (
    <Layout>
      <HumanRightsPage />
    </Layout>
  )
}

export default HumanRights;
