import React from "react";
import clsx from "clsx";

import "./styles.css";

interface RateCardProps {
  title: string;
  tag?: string;
  rate?: string;
  children: React.ReactNode;

  tint?: "teal" | "blue" | "lime" | "green" | "mint";
  className?: string;
}

const RateCard: React.FC<RateCardProps> = ({
  title,
  tag,
  rate,
  children,

  tint = "teal",
  className,
  ...props
}) => {
  const dynamicClasses = {
    [`s2-rate-card--${tint}`]: tint,
  };

  return (
    <div className={clsx("s2-rate-card", dynamicClasses, className)} {...props}>
      <div className="s2-rate-card__title">{title}</div>
      <div className="s2-rate-card__container">
        <span className="s2-rate-card__tag">{tag}</span>
        <div className="s2-rate-card__rate">{rate}</div>
        <div className="s2-rate-card__content">{children}</div>
      </div>
    </div>
  );
};

export default RateCard;
