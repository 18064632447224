import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { Box, Input, Label, Button, Select, Checkbox } from 'theme-ui';
import IntlTelInput from 'react-intl-tel-input';

function ContactForm() {
  const { register, handleSubmit, watch } = useForm();
  const [phone, setPhone] = useState();
  const [paymentLink, setPaymentLink] = useState('');
  const [availableApartments, setAvailableApartments] = useState([]);
  const [projects, setProjects] = useState([]); // Lista de proyectos únicos
  const [selectedProject, setSelectedProject] = useState(''); // Proyecto seleccionado
  const [filteredApartments, setFilteredApartments] = useState([]); // Apartamentos filtrados
  const [amount, setAmount] = useState("1000000"); // Valor predeterminado


  const phoneStyles = {
    mb: ['xsmall', 'none'],
    '.allow-dropdown.intl-tel-input': { display: 'block' },
    input: {
      width: '100%',
      border: '1px solid #d0d1d1',
      borderRadius: '4px',
      lineHeight: 'inherit',
    },
  };

  useEffect(() => {
    const fetchAvailableApartments = async () => {
      try {
        const response = await axios.get('https://filavirtual.coninsa.co/api/fila/available-apartments');
        const apartments = response.data;

        setAvailableApartments(apartments);

        // Obtener los proyectos únicos
        const uniqueProjects = [...new Set(apartments.map(apartment => apartment.proyecto))];
        setProjects(uniqueProjects);
      } catch (error) {
        console.error('Error al cargar los apartamentos disponibles:', error);
      }
    };

    fetchAvailableApartments();
  }, []);

  // Filtrar apartamentos según el proyecto seleccionado
  useEffect(() => {
    if (selectedProject) {
      setFilteredApartments(availableApartments.filter(apartment => apartment.proyecto === selectedProject));
    } else {
      setFilteredApartments([]);
    }
  }, [selectedProject, availableApartments]);

  // Actualizar monto basado en la selección del apartamento
  const selectedApartmentID = watch("apartamento");

  useEffect(() => {
    if (selectedApartmentID) {
      const selectedApartment = filteredApartments.find(apartment => apartment.inmuebleID === selectedApartmentID);
      setAmount(selectedApartment?.ubicacion === 1 ? "1000000" : "2000000");
    } else {
      setAmount("1000000"); // Valor predeterminado
    }
  }, [selectedApartmentID, filteredApartments]);  

  

  const onSubmit = async data => {
    const { name, last_name, email, cedula, apartamento, amount } = data;

    try {
      const inmuebleID = `${apartamento}`;
      const paymentData = {
        quantity: 1,
        onePayment: true,
        amount,
        currency: "COP",
        id: 0,
        description: `Cobro reserva ${apartamento} ${name} ${last_name}, CC ${cedula}, Tel: ${phone}, ${email}`,
        title: `Reserva ${apartamento}`,
        typeSell: "2",
        tax: "0",
        email
      };

      const loginResponse = await axios.post(
        'https://apify.epayco.co/login/mail',
        {},
        {
          headers: {
            Authorization: 'Basic YW5kcmVzMjMxMjRAZ21haWwuY29tOkFuZHJlczIz',
          },
        }
      );

      const token = loginResponse.data.token;

      const paymentResponse = await axios.post(
        'https://apify.epayco.co/collection/link/create',
        paymentData,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (paymentResponse.data.success) {
        const link = paymentResponse.data.data.routeLink;
        setPaymentLink(link);

        axios.post('https://filavirtual.coninsa.co/api/fila/enviar-sms', {
          numero: phone,
          mensaje: `Hola ${name}, ya puedes finalizar el pago de tu reserva en el siguiente link: ${link} `,
        });

        await axios.post('https://filavirtual.coninsa.co/api/fila/save-transaction', {
          inmuebleID,
          details: { paymentLink: link }
        });
      } else {
        console.error('Error al generar el enlace de pago.');
      }
    } catch (error) {
      console.error('Error en el proceso de autenticación o generación de pago:', error);
    }
  };

  return (
    <Box as='form' onSubmit={handleSubmit(onSubmit)}>
      <Box sx={phoneStyles}>
        <Label htmlFor='phone'>Celular</Label>
        <IntlTelInput
          id='phone'
          onPhoneNumberChange={(_, phoneNumber, country) => {
            const dialCode = country.dialCode;
            const withoutDial = phoneNumber.replace(new RegExp(`\\+${dialCode}`), '');
            setPhone(`+${dialCode}${withoutDial}`);
          }}
          defaultCountry={'co'}
          telInputProps={{ required: true }}
        />
      </Box>
      <Box>
        <Label htmlFor='name'>Nombres</Label>
        <Input {...register('name')} id='name' required />
      </Box>
      <Box>
        <Label htmlFor='last_name'>Apellidos</Label>
        <Input {...register('last_name')} id='last_name' required />
      </Box>
      <Box>
        <Label htmlFor='email'>Correo Electrónico</Label>
        <Input {...register('email')} id='email' required />
      </Box>
      <Box>
        <Label htmlFor='cedula'>Cédula</Label>
        <Input {...register('cedula')} id='cedula' required />
      </Box>

      {/* Selección de Proyecto */}
      <Box>
        <Label htmlFor='proyecto'>Proyecto</Label>
        <Select
          id='proyecto'
          value={selectedProject}
          onChange={(e) => setSelectedProject(e.target.value)}
          required
        >
          <option value="" disabled>Selecciona un proyecto</option>
          {projects.map((project, index) => (
            <option key={index} value={project}>{project}</option>
          ))}
        </Select>
      </Box>

      {/* Selección de Apartamento */}
      <Box>
        <Label htmlFor='apartamento'>Apartamento</Label>
        <Select {...register('apartamento')} id='apartamento' required defaultValue="" disabled={!selectedProject}>
          <option value="" disabled>Selecciona un apartamento</option>
          {filteredApartments
            .sort((a, b) => parseInt(a.numero) - parseInt(b.numero)) // Ordenar de menor a mayor
            .map((apartment) => (
              <option key={apartment.inmuebleID} value={apartment.inmuebleID}>
                {`Unidad ${apartment.numero} - ${apartment.areaTotal} m² - ${apartment.precio} COP`}
              </option>
            ))}
        </Select>
      </Box>


      {/* Monto de la Transacción */}
      <Box mt={4}>
        <Label>Monto de la Transacción</Label>
        <Box sx={{ fontWeight: 'bold', fontSize: '18px', mt: 2 }}>
          {Number(amount || "1000000").toLocaleString("es-CO")} COP
        </Box>

        {/* Campo oculto para enviar el monto en el formulario */}
        <input type="hidden" {...register('amount')} value={amount} />
      </Box>


      {/* Botón de envío */}    
      <Box>
        <Button sx={{ bg: 'secondary', borderRadius: '7px', color: 'white', mt: 'xsmall', py: '10px', width: '100%' }} type='submit'>
          Generar Link de Pago
        </Button>
      </Box>

      {paymentLink && (
        <Box mt={4}>
          <p>Enlace de pago generado:</p>
          <a href={paymentLink} target='_blank' rel='noopener noreferrer'>{paymentLink}</a>
        </Box>
      )}
    </Box>
  );
}

export default ContactForm;