import React from "react";

import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import Heading from "@coninsa-s2/heading";
import ContactCard from "@coninsa-s2/contact-card";
import Button from "@coninsa-s2/button";

import "./styles.css";

import mcvImage from "../../../images/mcv.png";

import data from "./data.js";

export default function () {
  return (
    <Section tint="ligther-gray">
      <Container>
        <div className="s2-mcv">
          <img className="s2-mcv__image" src={mcvImage} />
          <div>
            <Heading as="h2" tint="teal" size="3xl" condensed>
              Disfruta de <br />
              <span className="is-highlighted">MI CONINSA VIRTUAL</span>
            </Heading>
            <div className="my-6">
              <p className="s2-mcv__text leading-5">
                Una plataforma <strong>exclusiva</strong> de nuestra Compañía{" "}
                donde podrás realizar consultas y transacciones, como:
              </p>
              {data?.map((item) => (
                <ContactCard
                  medium
                  icon={item?.icon}
                  label={item?.subtitle}
                  highlighted={item?.title}
                />
              ))}
            </div>
            <Button href="https://miconinsavirtual.coninsa.co/" target="_blank">
              Ir a Mi Coninsa Virtual
            </Button>
          </div>
        </div>
      </Container>
    </Section>
  );
}
