import React, { useState } from 'react'
import axios from 'axios'
import { Box, Input, Button, Label, Select } from 'theme-ui'

import HabeasData from './HabeasData'

function ContactForm() {
  const [phone, setPhone] = useState()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [leadData, setLeadData] = useState([])
  const [selectedProject, setSelectedProject] = useState(null)
  const [successMessage, setSuccessMessage] = useState('') // Nuevo estado

  const onChange = (_, phoneNumber, country) => {
    const dialCode = country.dialCode
    const expression = `\+${dialCode}`
    const regex = new RegExp(expression)
    const withoutDial = phoneNumber.replace(regex, '')
    const internationalNumber = `+${country.dialCode}${withoutDial}`
    setPhone(internationalNumber)
  }

  const searchLead = async () => {
    if (!phone) {
      setError('Por favor, ingresa un número de celular válido.')
      return
    }

    setLoading(true)
    setError(null)
    setLeadData([])

    try {
      const response = await axios.get(
        `https://api.coninsa.co/api/v2/zohocrm-search-lead_number/${phone}`,
        {
          headers: {
            Authorization: 'Bearer 1000.82fe45d71c0a792be44c0211ab203691.181a2cdc599e5af2759255ad1f0e4823',
          },
        }
      )

      const filteredData = response.data.data.filter(
        (item) => item.Layout?.id === "2528071000000091055"
      )

      setLeadData(filteredData)

      if (filteredData.length === 0) {
        setError('No se encontraron registros válidos con el Layout especificado.')
      }
    } catch (err) {
      setError('Error al buscar el número de celular. Por favor, intenta de nuevo más tarde.')
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  const submitProjectSelection = async () => {
    if (!selectedProject) return

    try {
      let data = JSON.stringify({
        data: [
          {
            id: selectedProject,
            Acepto_Pol_tica_de_Tratamiento_de_Datos: true,
            Acepta_Pol_tica_Tratamiento_de_Datos: "Si",
          },
        ],
      });

      let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: 'https://api.coninsa.co/api/v2/zohocrm-create-lead',
        headers: {
          'Content-Type': 'application/json',
          'Cookie': 'PHPSESSID=4u8ibqbfk2b1g9as4br04mba6j'
        },
        data: data,
      };

      const response = await axios.request(config);

      console.log(response.data)
      setSuccessMessage('Gracias por aceptar nuestra política de tratamiento de datos.') // Actualiza el estado con el mensaje
    } catch (err) {
      setError('Error al enviar la respuesta. Por favor, intenta de nuevo más tarde.')
      console.error(err)
    }
  }

  const CallStyles = {
    bg: ['secondary', 'secondary'],
    borderRadius: '7px',
    color: 'white',
    fontSize: 1,
    fontWeight: 'bold',
    mt: 'xsmall',
    py: '10px',
    textTransform: 'uppercase',
    width: '100%',
    '&:hover': {
      bg: 'green-3',
    },
  }

  const phoneStyles = {
    mb: ['xsmall', 'none'],
    '.allow-dropdown.intl-tel-input': { display: 'block' },
    input: {
      width: '100%',
      border: '1px solid #d0d1d1',
      borderRadius: '4px',
      lineHeight: 'inherit',
    },
  }

  return (
    <Box>
      <Box as='form' onSubmit={(e) => e.preventDefault()}>
        <Box>
          <Box sx={phoneStyles}>
            <Input
              id='phone'
              placeholder='Número de contacto'
              onChange={(e) => setPhone(e.target.value)}
              required
            />
            <Label>
              Digite el número sin el indicativo y sin espacios.
            </Label>
          </Box>

          <Button sx={CallStyles} onClick={searchLead} disabled={loading}>
            {loading ? 'Buscando...' : 'Buscar'}
          </Button>

          {error && <Box sx={{ color: 'red', mt: 'small' }}>{error}</Box>}
          {leadData.length > 0 && (
            <Box sx={{ mt: 'small', color: 'green' }}>
              <HabeasData />
              <Label htmlFor="project">Seleccione un proyecto:</Label>
              <Select
                id="project"
                onChange={(e) => setSelectedProject(e.target.value)}
                defaultValue=""
              >
                <option value="" disabled>Seleccione una opción</option>
                {leadData.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.Proyecto_Survey || 'Sin Nombre'}
                  </option>
                ))}
              </Select>
              <Button
                sx={CallStyles}
                onClick={submitProjectSelection}
                disabled={!selectedProject}
              >
                Aceptar
              </Button>
            </Box>
          )}
          {successMessage && <Box sx={{ color: 'green', mt: 'medium' }}>{successMessage}</Box>} {/* Muestra el mensaje */}
        </Box>
      </Box>
    </Box>
  )
}

export default ContactForm