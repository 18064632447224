import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../../layout/default/Layout";
import { graphql } from "gatsby";

import { LandingPage } from "@coninsa-site/property";
import FloatingWhatsappBtn from "modules/home/components/FloatingWhatsappBtn";

function Landing({ data }) {
  const properties = data?.drupal?.searchProperties?.documents || [];

  return (
    <>
    {/* Google Tag Manager (head) */}
    <Helmet>
    <script>
      {`
        (function(w,d,s,l,i){
          w[l]=w[l]||[];
          w[l].push({'gtm.start': new Date().getTime(), event:'gtm.js'});
          var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),
              dl=l!='dataLayer'?'&l='+l:'';
          j.async=true;
          j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
          f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-KJGZ9DB');
      `}
    </script>
  </Helmet>
    <Layout
      headerAds={data?.drupal?.ads?.header}
      popupAds={data?.drupal?.ads?.popUp}
      footerAds={data?.drupal?.ads?.footer}
    >
      {/* Google Tag Manager (noscript) - dentro del body */}
      <noscript>
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-KJGZ9DB"
            height="0"
            width="0"
            style={{ display: "none", visibility: "hidden" }}
          ></iframe>
        </noscript>
        
      <FloatingWhatsappBtn path="https://api.whatsapp.com/send?phone=573014726883&text=Hola!" />
      <LandingPage properties={properties} />
    </Layout>
   </>
  );
}

export const query = graphql`
  query PropertiesHomePageQuery {
    drupal {
      ads: getAdsByUrl(path: "/arrendamientos") {
        header {
          id: nid
          image: fieldImage {
            url
          }
          url: fieldUrl {
            uri
            title
          }
        }
        popUp {
          id: nid
          image: fieldImage {
            url
          }
          url: fieldUrl {
            uri
            title
          }
        }
        footer {
          id: nid
          image: fieldImage {
            url
          }
          url: fieldUrl {
            uri
            title
          }
        }
      }

      # Search properties
      searchProperties: searchAPISearch(
        index_id: "search_properties"
        conditions: [
          { name: "field_service_type", value: "AR", operator: "=" }
          { name: "status", value: "1", operator: "=" }
          { name: "field_publish", value: "1", operator: "=" }
          { name: "string_location", value: "!", operator: "<>" }
          { name: "field_lease_value", value: "0", operator: "<>" }
          { name: "field_everyone_else", value: "1", operator: "=" }
        ]
        range: { offset: 0, limit: 8 }
      ) {
        result_count
        documents {
          ... on Drupal_SearchPropertiesDoc {
            id: property_id
            url
            imageUrl: building_image
            images: slider_images
            propertyType: property_type
            serviceType: field_service_type
            code
            area: field_area
            location: ngram_location
            price: field_lease_value
            state
            bedrooms: field_bedrooms
            bathrooms: field_bathrooms
          }
        }
      }
    }
  }
`;

export default Landing;
