import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "@coninsa-s2/slider/styles.css";

import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import * as BubbleCard from "@coninsa-s2/bubble-card";
import SpeechBubble from "@coninsa-s2/speech-bubble";
import IconButton from "@coninsa-s2/icon-button";

import digitalTransformBanner from "@coninsa-site/sustainability/assets/images/economic/digital-transform-banner.png";
import digitalTransformMobileBanner from "@coninsa-site/sustainability/assets/images/economic/digital-transform-mobile-banner.png";
import LeftArrowIcon from "@coninsa-site/sustainability/assets/icons/left-arrow.inline.svg";
import coninsaProcess from "@coninsa-site/sustainability/assets/images/economic/coninsa-process.png";

import economicPerformanceImage from "@coninsa-site/sustainability/assets/images/economic/economic-performance.png";

const data = [
  {
    id: "ps-01",
    url: "/corporativo/sostenibilidad/sostenibilidad-economica/desempeno-economico",
    image: economicPerformanceImage,
    title: "Desempeño económico",
  },
];

export default function DigitalTransformPage() {
  const sliderOptions = {
    arrows: false,
    gap: 12,
    perPage: 1,
    padding: { left: "6rem", right: 0 },
    pagination: false,
    breakpoints: {
      640: {
        arrows: false,
        perPage: 1,
        padding: { left: "2rem", right: "2rem" },
        pagination: true,
      },
    },
  };

  return (
    <div>
      <Section tint="mint" className="mb-5 !py-5 lg:mb-8">
        <Container>
          <div className="items-center gap-4 lg:flex">
            <IconButton
              href="/corporativo/sostenibilidad/sostenibilidad-economica"
              className="mb-5 lg:mb-0"
            >
              <LeftArrowIcon />
              Regresar
            </IconButton>
            <div className="text-2xl font-bold text-white">
              Sostenibilidad Económica
            </div>
          </div>
        </Container>
      </Section>
      <Container className="mb-10">
        <div className="">
          <div className="flex justify-center">
            <img
              src={digitalTransformMobileBanner}
              alt=""
              className="absolute block lg:hidden"
            />
          </div>

          <img
            src={digitalTransformBanner}
            alt=""
            className="absolute hidden lg:block"
          />

          <div className="relative z-10 pt-56 lg:pt-10">
            <Container>
              <h1 className="text-xl font-extrabold text-white lg:w-[556px] lg:text-center lg:text-4xl">
                <span className="block text-center font-handwritting text-5xl font-medium text-s2-dark-blue lg:text-end lg:text-8xl">
                  Transformación{" "}
                </span>
                digital, procesos e innovación
              </h1>
            </Container>
          </div>
        </div>
      </Container>

      <Section tint="ligther-gray">
        <Container className="mb-6">
          <div className="grid-cols-12 justify-between gap-8 lg:grid">
            <div className="col-span-7 mb-6">
              <div className="prose mt-8 text-balance rounded-[4px] border-l-4 border-s2-lime pl-4 text-s2-gray-600 lg:mt-0 lg:border-l-8 lg:pl-5 lg:text-lg">
                <p>
                  En el ámbito de la tecnología, los procesos y la innovación,
                  lideramos la dirección y facilitación de la adaptación de
                  procesos. Nos encargamos de explorar, crear y evolucionar
                  modelos de negocio alineados con la estrategia de la empresa.
                  Nuestro enfoque se centra en la estandarización,
                  automatización, apropiación y garantía de calidad, con el fin
                  de impulsar la competitividad sostenible y mejorar la
                  propuesta de valor ofrecida a nuestros clientes.
                </p>
                <p>
                  Hemos establecido objetivos, indicadores y desafíos a corto,
                  mediano y largo plazo, siempre con un enfoque centrado en el
                  cliente. Promovemos una mentalidad digital entre nuestros
                  colaboradores, impulsamos la estandarización y simplificación
                  de procesos, fomentamos el trabajo por procesos y mantenemos
                  una constante vigilancia tecnológica para estar al tanto de
                  las últimas tendencias. Evitamos desarrollos internos que no
                  sean necesarios y nos posicionamos como elementos relevantes
                  en el ecosistema de innovación digital.
                </p>
              </div>
            </div>
            <div className="col-span-5 self-start">
              <div className="rounded-xl bg-white p-7 shadow-[0px_3px_6px_#00000029]">
                <img src={coninsaProcess} alt="Coninsa process" />
              </div>
              <div className="mx-8 rounded-b-2xl bg-s2-mint px-8 py-5 text-center text-xl font-bold text-white lg:mx-20 lg:px-8 lg:py-6 lg:text-2xl">
                Nuestro proceso
              </div>
            </div>
          </div>
        </Container>
      </Section>

      <Section className="relative -mt-16 lg:-mt-20">
        <Container>
          <SpeechBubble
            tint="mint"
            className="mb-12 px-9 py-2 text-xl font-bold lg:px-10 lg:text-2xl"
          >
            Económica
          </SpeechBubble>
          <div className="items-center justify-start gap-4 lg:flex">
            <h2 className="font-display text-4xl font-extralight uppercase text-s2-teal lg:text-5xl">
              Prácticas{" "}
              <span className="block font-medium text-s2-dark-blue">
                sostenibles
              </span>
            </h2>

            <Splide
              options={sliderOptions}
              className="s2-slider s2-slider--lg mt-5"
            >
              {data.map((item) => (
                <SplideSlide key={item.id}>
                  <BubbleCard.Root image={item.image} className="h-64 lg:h-72">
                    <BubbleCard.Content className="min-h-24 bg-s2-green">
                      <h3 className="text-center text-lg font-bold leading-5 text-white lg:text-[22px]">
                        {item.title}
                      </h3>
                    </BubbleCard.Content>
                    <BubbleCard.Anchor href={item.url} />
                  </BubbleCard.Root>
                </SplideSlide>
              ))}
            </Splide>
          </div>
        </Container>
      </Section>
    </div>
  );
}
