import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../../layout/default/Layout";
import Consignment from "@coninsa-site/consignment";

export default function Landing() {
  return (
    <>
      {/* Google Tag Manager (head) */}
      <Helmet>
        <script>
          {`
            (function(w,d,s,l,i){
              w[l]=w[l]||[];
              w[l].push({'gtm.start': new Date().getTime(), event:'gtm.js'});
              var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),
                  dl=l!='dataLayer'?'&l='+l:'';
              j.async=true;
              j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
              f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-MD3HLM6');
          `}
        </script>
      </Helmet>

      <Layout>
        {/* Google Tag Manager (noscript) - dentro del body */}
        <noscript>
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-MD3HLM6"
            height="0"
            width="0"
            style={{ display: "none", visibility: "hidden" }}
          ></iframe>
        </noscript>

        <Consignment />
      </Layout>
    </>
  );
}
