import { graphql } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { Box } from "theme-ui";

// import Section from "@coninsa-s2/section";
import Tag from "@coninsa-s2/tag";
import Heading from "@coninsa-s2/heading";
import Title from "@coninsa-s2/title";
import Text from "@coninsa-s2/text";
import FeatureItem from "@coninsa-s2/feature-item";

import { Section } from "ui";
import Card from "modules/search-building/components/Card";
import PropertyBanner from "modules/search-building/slices/PropertyBanner";
import PropertyDetails from "modules/search-building/slices/PropertyDetails";
import PropertyInfo from "modules/search-building/components/PropertyInfo";
import PropertyMap from "modules/search-building/components/PropertyMap";
import BuildingHeader from "../modules/search-building/components/BuildingHeader";
import BuildingFloatingBar from "../modules/search-building/components/BuildingFloatingBar";
import Layout from "../layout/default/Layout";
import PropertyProjectGallery from "modules/search-building/slices/PropertyProjectGallery";

import QuickAccess from "@coninsa-site/property/src/sections/quick-access";

import RelatedProperties from "../modules/search-building/slices/RelatedProperties";
import {
  formatted,
  getNormalizerServiceType,
  formatPrice,
} from "../modules/search-building/utils";

const getFeaturesLabel = (parent = []) => parent[0]?.entity?.name;

const getFeatureText = (feature = {}) => {
  const plainFeature = [
    getFeaturesLabel(feature.entity.parent),
    feature.entity.name,
  ]
    .filter((item) => Boolean(item))
    .join(": ");

  return plainFeature;
};

function PropertyTemplate({ data, ...props }) {
  const info = data?.drupal?.entity;
  const property = info;

  const buildingPath = info?.propertyUrl?.path;
  const code = info?.code;
  const garage = info?.totalGarage;
  const projectCode = info?.projectCode;
  const propertyType = info?.propertyType?.entity?.name;
  const serviceType = info?.serviceType;
  const rawLeaseCanon = info?.leaseCanon;
  const rawSaleCanon = info?.saleCanon;
  const rawFairPrice = info?.fairPrice;
  const scratchPrices = info?.scratchPrices;
  const fairText = info?.fairText;
  const area = info?.area;
  const status = info?.status ? "Nuevo" : "Usado";
  const stratum = info?.stratum;
  const bedrooms = info?.bedrooms;
  const bathrooms = info?.bathrooms;
  const adminValue = info?.adminValue;
  const propertyValue = info?.propertyValue;
  const latLon = info?.latLon;
  const state = info?.state?.entity;
  const propertyDescription = info?.propertyDescription?.value;
  const location = [
    info?.location?.entity?.parent[0]?.entity?.parent[0]?.entity?.name,
    info?.location?.entity?.parent[0]?.entity?.name,
    info?.location?.entity?.name,
  ]
    .filter((text) => Boolean(text))
    .join(", ");

  const canonical = `https://www.coninsa.co${buildingPath}/`;
  const alternativeLocation = info?.alternativeLocation;
  const region = [
    info?.location?.entity?.parent[0]?.entity?.parent[0]?.entity?.name,
  ];
  const properties = info?.related?.results;
  const features = info?.features.map((feature) => getFeatureText(feature));
  const projectFeatures = info?.project?.projectFeatures.map((projectFeature) =>
    getFeatureText(projectFeature)
  );
  const projectImages = info?.project?.projectImages || [];
  const leaseCanon = rawLeaseCanon;
  const saleCanon = rawSaleCanon;
  const canon = serviceType === "AR" ? leaseCanon : saleCanon;
  const fairPrice = formatPrice(rawFairPrice);
  const service = getNormalizerServiceType(serviceType);
  const propertyTitle = `${propertyType} en ${service} en ${
    Boolean(location) ? location : alternativeLocation
  }`;
  const description = `codigo: ${code} ${propertyTitle} Area: ${area}  Baños: ${bathrooms} Alcobas: ${bedrooms}  Estado: ${status} Estrato: ${stratum} Garajes: ${garage} URL: ${canonical}`;
  const titleSEO = `${code} ${propertyType} en ${service} Area: ${area} Estado: ${status}`;
  const bg = "#EE8E00";

  let propertyImages = [];

  const images = info?.images || [];

  if (images.length > 0) {
    propertyImages = images;
  }

  // Process HABI images.
  const sourceProvider = info?.sourceProvider;

  if (sourceProvider && sourceProvider === "nuwwe") {
    const habiImages = info?.habiImages || [];
    if (habiImages.length > 0) {
      propertyImages = habiImages.map((url) => ({
        alt: "",
        derivative: { url },
        title: "",
      }));
    }
  }

  const whatsappLeaseMsg = `Hola, quiero compartir contigo estos inmuebles de Coninsa Ramón H., que creo te interesarán.
  Haz clic en el link del inmueble para conocerlo y/o contactarte con el Asesor. ${propertyType} ${service},
  ${area} mts, ${formatted(bedrooms)} alcoba(s), ${formatted(
    bathrooms
  )} baños, en ${location}. Código: ${code} Canon de arrendamiento: ${leaseCanon}
   https://www.coninsa.co${buildingPath}`;

  const whatsappSaleMsg = `Hola, quiero compartir contigo estos inmuebles de Coninsa Ramón H., que creo te interesarán.
   Haz clic en el link del inmueble para conocerlo y/o contactarte con el Asesor. Cuarto Útil/Depósito venta ${area} mts en ${location}. Código: ${code}, precio: ${saleCanon},
   alcobas: ${formatted(bedrooms)}, Baños: ${formatted(
    bathrooms
  )}. https://www.coninsa.co${buildingPath}`;

  function getNormalizedWhatsappMsg() {
    if (serviceType === "AR") {
      return whatsappLeaseMsg;
    }

    if (serviceType === "VE") {
      return whatsappSaleMsg;
    }

    return whatsappSaleMsg;
  }

  const whatsappMsg = getNormalizedWhatsappMsg();

  return (
    <Layout
      footerAds={info?.footerAds}
      headerAds={info?.headerAds}
      popupAds={info?.popUpAds}
    >
      <Helmet>
        <title>{titleSEO} | Coninsa</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={canonical} />
      </Helmet>

      <Box {...props} mt={["xsmall", "small"]}>
        <div>
          <BuildingFloatingBar
            canon={canon}
            code={code}
            path={buildingPath}
            region={region[0]}
            serviceType={serviceType}
            title={propertyTitle}
          />
        </div>

        <Section>
          <PropertyBanner images={propertyImages} title={propertyTitle} />

          {/* <Card>
              <PropertyDetails
                adminValue={adminValue}
                area={area}
                bathrooms={bathrooms}
                bedrooms={bedrooms}
                canon={canon}
                code={code}
                fairPrice={fairPrice}
                fairText={fairText}
                leaseCanon={leaseCanon}
                propertyType={propertyType}
                propertyValue={propertyValue}
                saleCanon={saleCanon}
                serviceType={serviceType}
                scratchPrices={scratchPrices}
                status={status}
                stratum={stratum}
                garage={garage}
              />
            </Card> */}

          {/* <BuildingHeader
            title={propertyTitle}
            eyebrow={service}
            bg={bg}
            whatsappMsg={whatsappMsg}
          /> */}
          <div className="grid grid-cols-1 gap-8 pt-5 md:grid md:grid-cols-6 md:gap-12 md:py-6">
            <div className="md:col-span-4">
              <div className="flex items-center gap-3 lg:gap-4">
                {state?.name && <Tag tint={state?.variant}>{state?.name}</Tag>}
                <a
                  href="#similar"
                  className="flex items-center gap-1 rounded-sm py-2 text-sm font-semibold text-s2-teal hover:underline lg:text-base"
                >
                  Inmuebles similares
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 24 24"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M13.1202 17.0228L8.92129 14.7324C8.19135 15.5125 7.15261 16 6 16C3.79086 16 2 14.2091 2 12C2 9.79086 3.79086 8 6 8C7.15255 8 8.19125 8.48746 8.92118 9.26746L13.1202 6.97713C13.0417 6.66441 13 6.33707 13 6C13 3.79086 14.7909 2 17 2C19.2091 2 21 3.79086 21 6C21 8.20914 19.2091 10 17 10C15.8474 10 14.8087 9.51251 14.0787 8.73246L9.87977 11.0228C9.9583 11.3355 10 11.6629 10 12C10 12.3371 9.95831 12.6644 9.87981 12.9771L14.0788 15.2675C14.8087 14.4875 15.8474 14 17 14C19.2091 14 21 15.7909 21 18C21 20.2091 19.2091 22 17 22C14.7909 22 13 20.2091 13 18C13 17.6629 13.0417 17.3355 13.1202 17.0228ZM6 14C7.10457 14 8 13.1046 8 12C8 10.8954 7.10457 10 6 10C4.89543 10 4 10.8954 4 12C4 13.1046 4.89543 14 6 14ZM17 8C18.1046 8 19 7.10457 19 6C19 4.89543 18.1046 4 17 4C15.8954 4 15 4.89543 15 6C15 7.10457 15.8954 8 17 8ZM17 20C18.1046 20 19 19.1046 19 18C19 16.8954 18.1046 16 17 16C15.8954 16 15 16.8954 15 18C15 19.1046 15.8954 20 17 20Z"></path>
                  </svg>
                </a>
              </div>

              <Heading
                as="h1"
                className="s2-heading--black s2-heading--xl mb-6 mt-2"
              >
                {propertyTitle}
              </Heading>

              <div className="flex items-center justify-between md:block">
                <Title
                  tint="dark-blue"
                  size="xl"
                  className="!font-bold md:mb-6"
                >
                  $ {parseInt(canon).toLocaleString("es-CO")}
                </Title>

                <Tag className={"s2-tag--gray s2-tag--rounded"}>
                  Código: {code}
                </Tag>
              </div>

              <div className="grid grid-cols-3 flex-wrap gap-4 py-7 md:flex md:justify-between md:gap-9 md:py-9">
                {area && (
                  <FeatureItem icon="area" label="Area">
                    <strong>
                      {area} m<sup>2</sup>
                    </strong>
                  </FeatureItem>
                )}

                {bedrooms > 0 && (
                  <FeatureItem icon="bed" label="Habitaciones">
                    <strong>{bedrooms}</strong>
                  </FeatureItem>
                )}

                {bathrooms > 0 && (
                  <FeatureItem icon="bath" label="Baños">
                    <strong>{bathrooms}</strong>
                  </FeatureItem>
                )}

                <FeatureItem icon="stratum" label="Estrato">
                  <strong>{stratum}</strong>
                </FeatureItem>

                <FeatureItem icon="status" label="Estado">
                  <strong>{status}</strong>
                </FeatureItem>

                {garage > 0 && (
                  <FeatureItem icon="car" label="Parqueadero">
                    <strong>{garage}</strong>
                  </FeatureItem>
                )}
              </div>

              <div className="mb-8 md:mb-12">
                <Heading
                  as="h2"
                  tint="black"
                  size="lg"
                  className="mb-3 !font-semibold md:mb-4"
                >
                  Descripción del inmueble
                </Heading>
                {propertyDescription && (
                  <p
                    className="mb-3 text-sm md:text-base"
                    dangerouslySetInnerHTML={{ __html: propertyDescription }}
                  />
                )}
                <p className="text-xs">
                  La clasificación del estrato es potestativo del municipio, el
                  anunciante no puede comprometerse con una clasificación
                  determinada del estrato el cual queda definido en el momento
                  de recibo de la obra, también pueden existir cambios y/o
                  ajustes en el mismo en cualquier momento por cambio de
                  reglamentación o del POT del municipio.
                </p>
              </div>

              {features?.length > 0 && (
                <div className="mb-8 md:mb-12">
                  <Heading
                    as="h2"
                    tint="black"
                    size="lg"
                    className="mb-4 !font-semibold"
                  >
                    Características del inmueble
                  </Heading>

                  <Text>
                    <ul className="columns-2 md:columns-3">
                      {features.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                  </Text>
                </div>
              )}

              {projectFeatures?.length > 0 && (
                <div className="mb-8 md:mb-12">
                  <Heading
                    as="h2"
                    tint="black"
                    size="lg"
                    className="mb-4 !font-semibold"
                  >
                    Características del proyecto
                  </Heading>

                  <Text>
                    <ul className="columns-2 md:columns-3">
                      {projectFeatures.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                  </Text>
                </div>
              )}

              {projectImages?.length > 0 && (
                <div className="md:mb-12">
                  <Heading
                    as="h2"
                    tint="black"
                    size="lg"
                    className="mb-4 !font-semibold"
                  >
                    Galería del proyecto
                  </Heading>

                  <PropertyProjectGallery images={projectImages} />
                </div>
              )}

              {latLon?.latlon !== "0,0" && (
                <div>
                  <Heading
                    as="h2"
                    tint="black"
                    size="lg"
                    className="mb-4 !font-semibold"
                  >
                    Ubicación
                  </Heading>
                  <PropertyMap id="ubicacion" latLon={latLon} />
                </div>
              )}
            </div>
            <PropertyInfo
              canon={canon}
              code={code}
              path={buildingPath}
              propertyType={propertyType}
              region={region[0]}
              serviceType={serviceType}
              className="hidden md:col-span-2 md:block"
            />
          </div>
        </Section>
        <RelatedProperties properties={properties} id="similar" />
        <QuickAccess />
      </Box>
    </Layout>
  );
}

PropertyTemplate.propTypes = {
  data: PropTypes.oneOf({}).isRequired,
  path: PropTypes.string.isRequired,
};

export default PropertyTemplate;

export const pageQuery = graphql`
  query property($id: String!) {
    drupal {
      entity: buildingById(id: $id) {
        id
        propertyUrl: entityUrl {
          path
        }
        headerAds: ads(type: "header") {
          id: nid
          image: fieldImage {
            url
          }
          url: fieldUrl {
            uri
            title
          }
        }
        popUpAds: ads(type: "pop_up") {
          id: nid
          image: fieldImage {
            url
          }
          url: fieldUrl {
            uri
            title
          }
        }
        footerAds: ads(type: "footer") {
          id: nid
          image: fieldImage {
            url
          }
          url: fieldUrl {
            uri
            title
          }
        }

        ...BuildingPropertySheet

        ... on Drupal_BuildingProperty {
          __typename
          projectCode: fieldProjectCode
          fairPrice: fieldFairPrice
          totalGarage: fieldGarages
          scratchPrices: fieldScratchPrices
          fairText: fieldFairText
          adminValue: fieldAdministrationValue
          propertyValue: propertyValuation

          related: relatedContent {
            results {
              ... on Drupal_BuildingProperty {
                id

                images: fieldImages {
                  alt
                  derivative(style: IMAGECARD) {
                    url
                  }
                }
                url: entityUrl {
                  path
                }
                propertyType: fieldPropertyType {
                  entity {
                    name
                  }
                }
                area: fieldArea
                status: fieldIsNew
                stratum: fieldStratum
                bedrooms: fieldBedrooms
                bathrooms: fieldBathrooms
                serviceType: fieldServiceType
                code
                relatedLocation: fieldLocation {
                  entity {
                    name
                    parent {
                      entity {
                        name
                        parent {
                          entity {
                            name
                          }
                        }
                      }
                    }
                  }
                }
                leaseValue
                saleValue
                state: fieldState {
                  entity {
                    id: tid
                    name
                    ... on Drupal_TaxonomyTermBuildingState {
                      machineName: fieldMachineName
                      variant: fieldVariant
                    }
                  }
                }
              }
            }
          }
          latLon: fieldLatLong {
            value
            geoType
            lat
            lon
            left
            top
            right
            bottom
            geohash
            latlon
          }
        }
      }
    }
  }
`;
