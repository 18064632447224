import React from "react";

import Heading from "@coninsa-s2/heading";
import Title from "@coninsa-s2/title";
import Section from "@coninsa-s2/section";
import Shape from "@coninsa-s2/shape";
import CtaCard from "@coninsa-s2/cta-card";
import Splide from "@coninsa-ui/react-splide";

import Container from "@coninsa-s2/container";

import items from "./data";

function QuickAccess() {
  const slideOptions = {
    paddingRight: "100px",
    itemsToShow: 3,
  };

  return (
    <Section tint='white'>
      <Container className='grid grid-cols-1 gap-6 md:grid-cols-4 md:items-center'>
        <div>
          <Heading as="h2" size="2xl" condensed className='mb-4 uppercase'>
            <span className="is-highlighted">Accesos rápidos</span>
          </Heading>

          <p>
            Paga tus facturas, consigna un inmueble para arrendamiento o venta y resuelve tus dudas.
          </p>
        </div>
        <div className="md:col-span-3">
          <Splide options={slideOptions}>
            {items.map((item) => (
              <div key={item.id} className="flex p-2">
                <CtaCard tint={item.tint} href={item.url}>
                  <Shape variant="circle" tint={item.tint}>
                    <img src={item.icon} alt="Icon" />
                  </Shape>
                  <Title size="md">{item.title}</Title>
                  <p className="text-sm">{item.description}</p>
                </CtaCard>
              </div>
            ))}
          </Splide>
        </div>
      </Container>
    </Section>
  );
}

export default QuickAccess;
