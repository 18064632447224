import React from 'react'
import IframeResizer from 'iframe-resizer-react'

import Layout from '../../layout/default/Layout'

import bannerImage from '../../images/generic-banner.png'
import integralManagementImg from '../../images/team-medellin.jpg'
import sagrilaftImg from '../../images/team-medellin-two.jpg'
import businessEthicsImg from '../../images/admin-bogota.jpg'
import saleServiceIcon from '../../assets/icons/co-after-sale-service.svg'

const SITE_URL = 'https://admindrupal.coninsa.co'

function BussinessEthic() {
  const showBodyCard = bodyCardId => event => {
    const bodyElements = document.querySelectorAll('.tab-body')
    const tabElements = document.querySelectorAll('.tab-label[data-active]')

    bodyElements.forEach(element => {
      element.classList.add('hidden')
    })

    tabElements.forEach(element => {
      delete element.dataset.active
    })

    const buttonElement = event.currentTarget

    buttonElement.dataset.active = true

    // Look for body card element.
    const bodyElement = document.querySelector(bodyCardId)

    if (bodyElement) {
      bodyElement.classList.remove('hidden')
    }
  }

  return (
    <Layout>
      <div
        style={{ backgroundImage: `url(${bannerImage})` }}
        className='bg-contain bg-no-repeat md:bg-cover px-5 py-12 md:px-0 md:py-44'
      >
        <div className='container'>
          <h1 className='font-display text-coninsa-blue-900 font-medium text-xl md:text-4xl uppercase'>
            GOBIERNO CORPORATIVO
          </h1>
        </div>
      </div>

      <div className='bg-coninsa-gray-300 '>
        <div className='container font-normal px-5 md:px-0 py-7 md:py-14 text-sm md:text-base'>
          <p className='mb-6'>
            En Coninsa vivimos nuestros valores corporativos: Honestidad,
            Confiabilidad, Respeto, Compromiso y Actitud de Servicio. Además,
            cumplimos las normas a través del empleo de canales de comunicación y
            políticas como:
          </p>

          <div className='block md:flex gap-5 items-start mb-10'>
            <img
              className='w-full h-full md:w-80 md:h-48 mb-5 md:mb-0'
              src={integralManagementImg}
            />
            <div className='text-justify'>
              <h3 className='font-bold text-lg md:text-2xl text-coninsa-green-500 mb-5'>
                Política de Gestión Integral
              </h3>
              <p className='mb-4'>
                En Coninsa buscamos la plena satisfacción de las partes interesadas a
                través de una gestión progresiva enfocada en la calidad de nuestros
                productos y servicios, la responsabilidad social y la búsqueda de un
                desarrollo sostenible.
              </p>
              <p className='mb-4'>
                Ejecutamos satisfactoriamente obras civiles, edificaciones públicas y
                privadas.
              </p>
              <p className='mb-4'>
                Esto lo logramos mediante el cumplimento de los compromisos pactados,
                los plazos estipulados, presupuestos, especificaciones y requisitos
                legales; fomentando espacios de trabajo seguros a partir de buenas
                prácticas que prevengan la contaminación del medio ambiente,
                accidentes de trabajo, enfermedades laborales y daños a la propiedad.
              </p>
              <p className='mb-4'>
                Contamos con el Talento Humano profesional y competente, y con los
                Proveedores calificados y comprometidos, que trabajan de acuerdo con
                los procesos establecidos, bajo la metodología del aprendizaje y el
                mejoramiento continuo.
              </p>
              <p className='mb-4'>
                Esto nos permite cumplir con responsabilidad la Política Integral,
                teniendo presente la rentabilidad de los Accionistas.
              </p>
            </div>
          </div>

          <div className='block md:flex gap-5 items-start mb-10'>
            <img
              className='w-full h-full md:w-80 md:h-48 mb-5 md:mb-0'
              src={sagrilaftImg}
            />

            <div className='text-justify'>
              <h3 className='font-bold text-lg md:text-2xl text-coninsa-green-500 mb-5'>
                SAGRILAFT
              </h3>
              <a
                className='block font-bold mb-5 text-coninsa-blue-300 hover:underline'
                href='https://d2xt7650da4t25.cloudfront.net/s3fs-public/2025-03/Manual%20SAGRILAFT%202024%20para%20aprobacion%20de%20la%20JD.pdf'
                target='_blank'
              >
                Descargar el Manual de SAGRILAFT
              </a>
              <p className='mb-4'>
                Consciente del impacto económico, social y ambiental que tiene el
                desarrollo del sector de la construcción y la prestación de los
                servicios inmobiliarios y las consecuencias que tendría el permitir
                el acceso de alguna de las Contrapartes personas naturales o
                jurídicas en el giro normal de sus negocios, sin el debido
                autocontrol y gestión del riesgo integral LA/FT/ FPADM (SAGRILAFT);
                Coninsa. S.A. tiene dentro de su gobierno corporativo, la premisa de
                que sus actividades se realicen en el marco de la responsabilidad
                social, ética y legal, en correspondencia con sus valores
                corporativos. Dentro de ese compromiso, la Junta Directiva, sus
                Accionistas, los administradores y sus colaboradores, entienden la
                obligación de gestionar el riesgo LA/FT/ FPADM en todas y cada una de
                las prácticas y procedimientos comerciales y financieros de los
                procesos y de exigir el mismo estándar de calidad a nuestras
                Contrapartes internas y externas, a través de su conocimiento, de la
                rigurosidad en el cumplimiento de los requisitos de su vinculación y
                del seguimiento de la ejecución de sus obligaciones durante su
                permanencia; de esta manera ha venido convirtiendo la cultura de la
                prevención del riesgo LA/FT/ FPADM en parte de su ADN de valores y su
                comportamiento social y comercial.
              </p>
              <p className='mb-4'>
                Lo invitamos a conocer los conceptos básicos y las políticas del
                SAGRILAFT, que sirven de apoyo a todos los responsables del
                autocontrol y gestión del riesgo integral LA/FT/ FPADM en Coninsa
                Estas establecen las condiciones que deben cumplir la Junta
                Directiva, sus Accionistas, los colaboradores y nuestros socios de
                negocios, inversionistas, clientes y proveedores, para establecer
                relaciones con la Compañía.
              </p>
              <h4 className='font-semibold mb-4'>CONCEPTOS BASICOS DEL SAGRILAFT</h4>
              <p className='mb-4'>
                SAGRILAFT: Sistema de Autocontrol y Gestión del Riesgo Integral de
                Lavado de Activos, Financiación del Terrorismo y la Financiación de
                la Proliferación de Armas de Destrucción Masiva.
              </p>
              <p className='mb-4'>
                El LAVADO DE ACTIVOS, es el conjunto de actividades encaminadas a
                ocultar el origen ilícito o a dar apariencia de legalidad a recursos
                obtenidos producto de la ejecución de actividades ilícitas.
              </p>
              <p className='mb-4'>
                El lavo de activos se da a través de tres etapas, colocación, los
                activos que se obtienen ilegalmente se introducen en la economía, en
                cualquiera de sus sectores, como pueden ser el financiero,
                cooperativo, asegurador, bursátil, real , cambiario, comercio
                exterior, comercio y servicios,{' '}
                <span className='underline'>inmobiliarios</span>, agrícola y
                ganadero, minero, etc. ; ocultamiento o transformación, los activos
                se movilizan en la economía y complican el rastreo de su procedencia,
                cambiando de ubicación, modificando su origen, su destino o
                transformando su naturaleza e integración, los activos se integran a
                la economía como recursos o bienes legítimos a través de operaciones
                financieras o económicas.
              </p>
              <p className='mb-4 underline'>
                No obstante, lo anterior, los bienes de origen ilícito nunca pierden
                su origen ilegal, pudiendo ser en cualquier momento objeto de
                extinción de dominio.
              </p>
              <h4 className='font-semibold mb-4'>EXTINCIÓN DE DOMINIO</h4>
              <p className='mb-4'>
                La extinción de dominio en{' '}
                <a
                  className='font-bold text-coninsa-blue-300 hover:underline'
                  href='https://es.wikipedia.org/wiki/Colombia'
                  target='_blank'
                >
                  Colombia
                </a>{' '}
                es un mecanismo jurídico definido por la{' '}
                <a
                  className='font-bold text-coninsa-blue-300 hover:underline'
                  href='https://es.wikipedia.org/w/index.php?title=Ley_1708&action=edit&redlink=1'
                  target='_blank'
                >
                  Ley 1708
                </a>{' '}
                del{' '}
                <a
                  className='font-bold text-coninsa-blue-300 hover:underline'
                  href='https://es.wikipedia.org/wiki/20_de_enero'
                  target='_blank'
                >
                  20 de enero
                </a>{' '}
                de{' '}
                <a
                  className='font-bold text-coninsa-blue-300 hover:underline'
                  href='https://es.wikipedia.org/wiki/2014'
                  target='_blank'
                >
                  2014
                </a>
                , donde establece y permite el embargo de bienes privados que hayan
                sido utilizados o destinados para actividades ilícitas.
              </p>
              <p className='mb-4'>
                La FINANCIACIÓN DEL TERRORISMO, es el conjunto de actividades
                encaminadas a canalizar recursos ilícitos para promover, sufragar o
                patrocinar individuos, grupos o actividades terroristas.
              </p>
              <p className='mb-4'>
                Las actividades terroristas pueden ser financiadas con recursos de
                origen lícito o ilícito, siendo su mayor fuente de financiación las
                actividades delictivas del tráfico de drogas, personas y armas y la
                extorsión y el secuestro.
              </p>
              <p className='mb-4'>
                La financiación del terrorismo se da también a través de tres etapas;
                la recaudación, que consiste en la búsqueda de fuentes de
                financiación de recursos legales e ilegales por parte de las
                organizaciones terroristas; la disposición, que busca proveer los
                recursos a la organización terrorista para la etapa de utilización.
              </p>
              <h4 className='font-semibold mb-4 text-center'>
                RELACIÓN ENTRE EL LAVO DE ACTIVOS Y LA FINANCIACIÓN DEL TERRORISMO
              </h4>
              <p className='mb-4'>
                El lavado de activos es la mayor fuente de recursos para financiar
                las actividades terroristas.
              </p>
              <p className='mb-4'>
                FINANCIAMIENTO DE LA PROLIFERACIÓN DE ARMAS DE DESTRUCCIÓN MASIVA O
                FPADM: es todo acto que provea fondos o utilice servicios
                financieros, en todo o en parte, para la fabricación, adquisición,
                posesión, desarrollo, exportación, trasiego de material,
                fraccionamiento, transporte, transferencia, depósito o uso de armas
                nucleares, químicas o biológicas, sus medios de lanzamiento y otros
                materiales relacionados (incluyendo tecnologías y bienes de uso dual
                para propósitos ilegítimos) en contravención de las leyes nacionales
                u obligaciones internacionales, cuando esto último sea aplicable.
              </p>
              <h4 className='font-semibold mb-4 text-center'>
                RELACIÓN ENTRE EL LAVADO DE ACTIVOS Y LA CORRUPCIÓN
              </h4>
              <p className='mb-4'>
                Definida como el mal uso del poder o de confianza encomendado para
                obtener beneficios privados o particulares, en detrimento del interés
                colectivo y realizado a través de ofrecer o solicitar, entregar o
                recibir, bienes en dinero o en especie, en servicios o beneficios, a
                cambio de acciones, decisiones u omisiones; es considerado como un
                delito fuente grave del delito penal de Lavado de Activos.
              </p>
              <p className='mb-4'>
                Debido a que Coninsa presta sus servicios a terceros y en especial a
                empresas o instituciones públicas, tiene como uno de los medios para
                prevenir el riesgo LA/FT/FPADM y la corrupción, LA LÍNEA DE
                TRANSPARENCIA.
              </p>
              <p className='mb-4'>
                RIESGO LA/FT/FPADM: es la posibilidad de pérdida o daño que puede
                sufrir una Empresa por su propensión a ser utilizada directamente o a
                través de sus operaciones como instrumento para el Lavado de Activos
                y/o canalización de recursos hacia la realización de actividades
                terroristas o el Financiamiento de la Proliferación de Armas de
                Destrucción Masiva, o cuando se pretenda el ocultamiento de Activos
                provenientes de dichas actividades.
              </p>
              <p className='mb-4'>
                RIESGO DE CONTAGIO: Es la posibilidad de pérdida que la Compañía
                puede sufrir, directa o indirectamente, por una acción o experiencia
                de una Contraparte.
              </p>
              <p className='mb-4'>
                RIESGO LEGAL: es la posibilidad de pérdida en que incurre la Compañía
                al ser sancionada u obligada a indemnizar daños como resultado del
                incumplimiento de normas o regulaciones y obligaciones contractuales.
                Surge también como consecuencia de fallas en los contratos y
                transacciones, derivadas de actuaciones malintencionadas, negligencia
                o actos involuntarios que afectan la formalización o ejecución de
                contratos o transacciones.
              </p>
              <p className='mb-4'>
                RIESGO OPERATIVO: es la posibilidad de incurrir en pérdidas por
                deficiencias, fallas o inadecuaciones, en el recurso humano, los
                procesos, la tecnología, la infraestructura o por la ocurrencia de
                acontecimientos externos.
              </p>
              <p className='mb-4'>
                RIESGO REPUTACIONAL: es la posibilidad de pérdida en que podría
                incurrir la Compañía por desprestigio, mala imagen, publicidad
                negativa, cierta o no, respecto de la organización y sus prácticas de
                negocios, que cause pérdida de clientes, disminución de ingresos o
                procesos judiciales.
              </p>
              <p className='mb-4'>
                CONTRAPARTE: es cualquier persona natural o jurídica con la que la
                Empresa tenga vínculos comerciales, de negocios, contractuales o
                jurídicos de cualquier orden.
              </p>
              <p className='mb-4'>
                CONTRAPARTES INTERNAS: Accionistas, miembros de junta directiva y
                colaboradores.
              </p>
              <p className='mb-4'>
                CONTRAPARTES EXTERNAS: Socios de negocios, inversionistas, clientes y
                proveedores.
              </p>
              <p className='mb-4'>
                BENEFICIARIO FINAL: es la(s) persona(s) natural(es) que finalmente
                posee(n) o controla(n) a un cliente o a la persona natural en cuyo
                nombre se realiza una transacción. Incluye también a la(s) persona(s)
                que ejerzan el control efectivo y/o final, directa o indirectamente,
                sobre una persona jurídica u otra estructura sin personería jurídica.
                Son Beneficiarios Finales de la persona jurídica los siguientes:
              </p>
              <p className='mb-4'>
                Persona natural que, actuando individual o conjuntamente, ejerza
                control sobre la persona jurídica, en los términos del artículo 260 y
                siguientes del Código de Comercio; o Persona natural que, actuando
                individual o conjuntamente, sea titular, directa o indirectamente,
                del cinco por ciento (5%) o más del capital o los derechos de voto de
                la persona jurídica, y/o se beneficie en un cinco por ciento (5%) o
                más de los rendimientos, utilidades o Activos de la persona jurídica.
              </p>
              <p className='mb-4'>
                Cuando no se identifique alguna persona natural en los casos
                anteriores, la persona natural que ostente el cargo de representante
                legal, salvo que exista una persona natural que ostente una mayor
                autoridad en relación con las funciones de gestión o dirección de la
                persona jurídica.
              </p>
              <p className='mb-4'>
                Son Beneficiarios Finales de un contrato fiduciario, de una
                estructura sin personería jurídica o de una estructura jurídica
                similar, las siguientes personas naturales que ostenten la calidad
                de: Fiduciante(s), fideicomitente(s), constituyente(s) o puesto
                similar o equivalente; comité fiduciario, comité financiero o puesto
                similar o equivalente; fideicomisario(s), beneficiario(s) o
                beneficiarios condicionados; y cualquier otra persona natural que
                ejerza el control efectivo y/o final, o que tenga derecho a gozar y/o
                disponer de los activos, beneficios, resultados o utilidades.
              </p>
              <p className='mb-4'>
                PEP: significa personas expuestas políticamente, es decir, son los
                servidores públicos de cualquier sistema de nomenclatura y
                clasificación de empleos de la administración pública nacional y
                territorial, cuando en los cargos que ocupen, tengan en las funciones
                del área a la que pertenecen o en las de la ficha del empleo que
                ocupan, bajo su responsabilidad directa o por delegación, la
                dirección general, de formulación de políticas institucionales y de
                adopción de planes, programas y proyectos, el manejo directo de
                bienes, dineros o valores del Estado. Estos pueden ser a través de
                ordenación de gasto, contratación pública, gerencia de proyectos de
                inversión, pagos, liquidaciones, administración de bienes muebles e
                inmuebles. Incluye también a las PEP Extranjeras y las PEP de
                Organizaciones Internacionales.
              </p>
              <p className='mb-4'>
                DEBIDA DILIGENCIA: es el proceso mediante el cual la Compañía adopta
                medidas para el conocimiento de la Contraparte, de su negocio,
                operaciones, Productos y el volumen de sus transacciones.
              </p>
              <p className='mb-4'>
                DEBIDA DILIGENCIA INTENSIFICADA: es el proceso mediante el cual la
                Compañía adopta medidas adicionales y con mayor intensidad para el
                conocimiento de la Contraparte, de su negocio, operaciones, Productos
                y el volumen de sus transacciones.
              </p>
              <p className='mb-4'>
                OPERACIÓN INUSUAL: es la operación cuya cuantía o características no
                guardan relación con la actividad económica ordinaria o normal de la
                Contraparte o, que por su número, cantidad o características no se
                enmarca dentro de las pautas de normalidad o prácticas ordinarias de
                los negocios en un sector, en una industria o con una clase de
                Contraparte.
              </p>
              <p className='mb-4'>
                OPERACIÓN SOSPECHOSA: es la Operación Inusual que, además, de acuerdo
                con los usos y costumbres de la actividad de que se trate, no ha
                podido ser razonablemente justificada. Este tipo de operaciones
                incluye las operaciones intentadas o rechazadas que contengan
                características que les otorguen el carácter de sospechosas.
              </p>
              <p className='mb-4'>
                ROS: Es el reporte de Operaciones Sospechosas. Es aquella operación
                que por su número, cantidad o características no se enmarca dentro
                del sistema y prácticas normales del negocio, de una industria o de
                un sector determinado y, además que de acuerdo con los usos y
                costumbres de la actividad que se trate, no ha podido ser
                razonablemente justificada. Su reporte requerirá de los soportes de
                la información sensible y de las transacciones efectuadas con este
                carácter.
              </p>
              <h4 className='font-semibold mb-4'>
                PRINCIPALES POLÍTICAS Y PROCEDIMIENTOS DE SAGRILAFT
              </h4>
              <p className='mb-4'>
                En general los accionistas, socios de negocios, miembros de Junta
                Directiva y empleados, asumirán el compromiso de cumplir y hacer
                cumplir los lineamientos de estas políticas de prevención y
                administración del riego LA/FT/FPADM en concordancia con las
                definiciones éticas, de transparencia y de buen Gobierno de Coninsa.
                Lo anterior significa que la prevención del riesgo LA/FT/FPADM, por
                el impacto que podría tener en la Compañía, debe anteponerse al logro
                de los objetivos o metas económicas y se considera como parte de la
                estrategia de sustentabilidad y sostenibilidad futura de la misma.
              </p>
              <p className='mb-4'>
                Los Colaboradores responsables dentro de los procesos vincular y/o
                administrar las Contrapartes, deberán responder por la función de
                conocerlas, hacerles seguimiento y realizar los reportes de los
                hallazgos de operaciones inusuales o sospechosa encontradas,
                considerándose una falta grave el no hacerlo con la debida
                oportunidad. Este reporte se hará mediante comunicación escrita
                dirigida al oficial de cumplimiento con el mayor detalle posible de
                información y adjuntando los soportes pertinentes.
              </p>
              <p className='mb-4'>
                Las condiciones mínimas de conocimiento de las Contrapartes deberán
                incluir en su debida diligencia la siguiente información:
              </p>
              <ul className='list-disc list-outside ml-7 mb-4 space-y-4 marker:text-coninsa-yellow-300'>
                <li>
                  Identificar a la Contraparte y verificar su identidad utilizando
                  documentos, datos o información confiable, de fuentes
                  independientes.
                </li>
                <li>
                  Identificar al Beneficiario final de la Contraparte y tomar medidas
                  Razonables para verificar su identidad.
                </li>
                <li>
                  Tratándose de Personas Jurídicas, se deben tomar medidas razonables
                  para conocer la estructura de su propiedad con el fin de obtener el
                  nombre y el número de identificación de los beneficiarios finales,
                  haciendo uso de las herramientas de que disponga. Las medidas
                  tomadas deben ser proporcionales al nivel del riesgo y su
                  materialidad o complejidad inducida por la estructura de
                  titularidad de la sociedad mercantil o la naturaleza de los
                  asociados mayoritarios.
                </li>
                <li>
                  Entender, y cuando corresponda, obtener información sobre el
                  propósito y el carácter que se pretende dar a la relación
                  comercial. Realizar una Debida Diligencia continua de la relación
                  comercial y examinar las transacciones llevadas a cabo a lo largo
                  de esa relación para asegurar que las transacciones que se realicen
                  sean consistentes con el conocimiento que tiene la Empresa Obligada
                  sobre la Contraparte, su actividad comercial y el perfil de riesgo,
                  incluyendo, cuando sea necesario, la fuente de los fondos.
                </li>
              </ul>
              <p className='mb-4'>
                Las relaciones y/o compromisos con Contrapartes, personas naturales o
                jurídicas, escritas o verbales se deberán realizar una vez se haya
                efectuado la debida diligencia de conocimiento y control previo
                LA/FT/FPADM.
              </p>
              <p className='mb-4'>
                IDENTIFICACIÓN Y REPORTE DE OPERACIONES INUSUALES Y SOSPECHOSAS:
                Todas las Contrapartes internas, están obligadas a reportar este tipo
                de operaciones en el menor tiempo posible al Oficial de Cumplimiento,
                por lo que se difundirán las tipologías con el fin de que se puedan
                detectar e identificar en adición a todas aquellas que surjan del
                giro ordinario de las actividades en cada uno de los procesos.
              </p>
              <p className='mb-4'>
                SANCIONES: El incumplimiento u omisión de las políticas establecidas
                en este manual por parte de los empleados se considerará falta grave
                y deberá ser incluido como tal dentro del régimen de los procesos
                disciplinarios de la Compañía, en especial las definidas en el
                contrato como prohibiciones especiales. Las demás contrapartes
                estarán sujetas a las condiciones establecidas contractualmente con
                sus respectivas consecuencias judiciales administrativas y penales.
              </p>
            </div>
          </div>

          <div className='block md:flex gap-5 items-start mb-10'>
            <img
              className='w-full h-full md:w-80 md:h-48 mb-5 md:mb-0'
              src={businessEthicsImg}
            />
            <div>
              <h3 className='font-bold text-lg md:text-2xl text-coninsa-green-500 mb-5'>
                Ética y transparencia empresarial
              </h3>
              <p className='text-justify mb-2 md:mb-0'>
                CONINSA tiene dentro de su gobierno la premisa de que sus actividades
                se realicen en el marco de la responsabilidad social, ética y legal,
                en correspondencia con sus valores corporativos de excelencia,
                confiabilidad, experiencia comercial diferenciadora y trabajo en
                equipo. Dentro de ese compromiso, la Junta Directiva, entiende la
                necesidad de establecer principios claros que orienten la comprensión
                y puesta en práctica de los comportamientos éticos y de transparencia
                que deben guiar nuestra manera de actuar, cumpliendo con el propósito
                superior de “Construir Bienestar”. Esperamos que este Programa de
                Transparencia y Ética Empresarial sea acogido y puesto en práctica
                con el compromiso de transparencia por cada uno de los colaboradores,
                como un pacto de honor y como parte del sello de liderazgo, con el
                cual queremos que nos identifiquen nuestras partes de interés, por el
                ejemplo que damos en el hacer de nuestro diario vivir. “Somos todos
                construyendo valores y defendiendo la responsabilidad ética y legal”.
                CONINSA fue, es y será un referente de profesionalismo,
                confiabilidad, transparencia, responsabilidad social y empresarial,
                al servicio de nuestros clientes, colaboradores y de la sociedad que
                nos acoge”.
              </p>
              <p className='font-semibold mb-8 text-end'>
                Alejandra Zapata Castaño{' '}
                <span className='block leading-4'>Presidenta</span>
              </p>

              <a
                className='block font-bold mb-5 text-coninsa-blue-300 hover:underline'
                href='https://d2xt7650da4t25.cloudfront.net/s3fs-public/2025-03/Manual%20de%20PTEE%20Coninsa%202024%20para%20aprobacion%20de%20la%20JD.pdf'
                target='_blank'
              >
                Descargar el Manual de Transparencia y Ética Empresarial (PTEE)
                Coninsa S.A.
              </a>
              <h3 className='font-bold text-lg md:text-2xl text-coninsa-green-500 mb-5'>
                Línea de transparencia y ética empresarial
              </h3>
              <p className='text-justify'>
                Este canal ha sido creado para que los Miembros de Junta Directiva,
                Accionistas, Socios de negocios, Colaboradores, Proveedores,
                Contratistas, Clientes y el Público en General, puedan reportar
                anónimamente hechos reales o potenciales de corrupción,
                irregularidades, hechos incorrectos, delictivos o cualquier otro tipo
                de situaciones que afecten la Compañía. Éste es un canal donde la
                información recibida tiene un tratamiento bajo parámetros estrictos
                de confidencialidad, anonimato y respeto. Las personas que se
                contacten permanecerán en el anonimato y bajo ninguna circunstancia
                se revelará la identidad de las mismas sin su consentimiento. Los
                temas que se podrán tratar o denunciar a través de la Línea Ética y
                de Transparencia de Coninsa hacen referencia a las infracciones al
                Programa de Transparencia y Ética Empresarial (PTEE), al Código de
                Buen Gobierno y al SAGRILAFT (Sistema de Autocontrol y Gestión del
                Riesgo Integral del Lavado de Activos, Financiación del Terrorismo y
                la Proliferación de Armas de Destrucción Masiva). Muy
                específicamente, son todos aquellos delitos, operaciones intentadas o
                sospechosas relacionadas con el conflicto de intereses, soborno
                transnacional, cohecho, tráfico de influencias, estafa, fraude,
                lavado de activos, financiación del terrorismo y de la proliferación
                de armas de destrucción masiva, violación a los sistemas y a la
                seguridad de la información y cualquier tipo de conducta que vaya en
                contra de los valores, la misión, visión y buenas prácticas
                establecidas por la Compañía. A través de este canal también podrás
                reportar situaciones internas al Comité de Convivencia Laboral, el
                cual tiene por objeto prevenir las conductas de aparente acoso
                laboral y atenderlas en caso de presentarse. El Comité procurará
                promover relaciones laborales propicias para la salud mental y el
                respeto a la dignidad de los colaboradores, mediante estrategias de
                promoción, prevención e intervención para la resolución de
                conflictos.{' '}
                <span className='font-bold'>
                  Se debe tener en cuenta que los temas comerciales relacionados con
                  la prestación de los servicios y la calidad y garantía de los
                  productos serán atendidos por los canales de atención al cliente
                </span>
                .
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='container py-4 md:py-8'>
        <div className='py-6'>
          <div className='flex flex-col md:flex-row gap-4 mb-4 md:justify-center px-5 py-3'>
            <button
              className='tab-label bg-neutral-white flex cursor-pointer flex-col items-center rounded-lg border-none
                px-5 py-4 text-coninsa-blue-900 shadow-[0px_1px_4px_#00000029]
                hover:bg-coninsa-gray-300 data-[active=true]:bg-coninsa-blue-300 data-[active=true]:text-white'
              onClick={showBodyCard('#one')}
            >
              <outline-icon class='mb-3' src={saleServiceIcon} />
              Formulario de delitos o alertas de <br />
              <strong className='font-semibold'>
                operaciones sospechosas o intentadas
              </strong>
            </button>

            <button
              className='min-w-[336px] tab-label bg-neutral-white flex cursor-pointer flex-col items-center rounded-lg border-none
                px-5 py-4 text-coninsa-blue-900 shadow-[0px_1px_4px_#00000029]
                hover:bg-coninsa-gray-300 data-[active=true]:bg-coninsa-blue-300 data-[active=true]:text-white'
              onClick={showBodyCard('#two')}
            >
              <outline-icon class='mb-3' src={saleServiceIcon} />
              Formulario de comité de
              <br />
              <strong className='font-semibold'>convivencia laboral</strong>
            </button>
          </div>
        </div>

        <div id='one' className='tab-body hidden'>
          <IframeResizer
            className='w-full'
            src={`${SITE_URL}/form/suspicious-attempted-crimes`}
          ></IframeResizer>
        </div>

        <div id='two' className='tab-body hidden'>
          <IframeResizer
            className='w-full'
            src={`${SITE_URL}/form/labor-coexistence-committee`}
          ></IframeResizer>
        </div>
      </div>
    </Layout>
  )
}

export default BussinessEthic
