import React from "react";

import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import Card from "@coninsa-s2/card";
import Title from "@coninsa-s2/title";
import Button from "@coninsa-s2/button";

import data from "./data.js";

import ArrowRightIcon from "../../../../home/icons/arrow-right.inline.svg";

export default function () {
  return (
    <Section tint="gray">
      <Container>
        <div className="grid gap-4 md:grid-cols-1 lg:grid-cols-2">
          {data?.map((item) => (
            <Card
              key={item.id}
              tint={item.tint}
              imageUrl={item.imageUrl}
              featured
            >
              <img src={item?.icon} className="s2-card__icon" />
              <Title tint={item.tint} size="2xl" condensed>
                {item?.subtitle}
                <span className="is-highlighted"> {item?.title}</span>
              </Title>
              <p>{item?.description}</p>
              <div className="s2-card__content-btn">
                <Button href={item?.url} target="_blank">
                  Conoce más
                </Button>
                <a href={item?.url} target="_blank">
                  <ArrowRightIcon className="s2-card__content-icon" />
                </a>
              </div>
            </Card>
          ))}
        </div>
      </Container>
    </Section>
  );
}
