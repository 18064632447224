import React from "react";
import Layout from "layout/default/Layout";

import SustainabilityEconomicPage from "@coninsa-site/sustainability/src/pages/sustainability-economic/pages"

function SustainabilityEconomic() {
  return (
    <Layout>
      <SustainabilityEconomicPage />
    </Layout>
  )
}

export default SustainabilityEconomic;
