import React from "react";

import "./styles.css";

interface SortSelectProps {
  children: React.ReactNode;
  onChange: (Event) => void;
}

const SortSelect: React.FC<SortSelectProps> = ({ children, ...props }) => {
  return (
    <select className="s2-sort-select" {...props}>
      {children}
    </select>
  );
};

export default SortSelect;
