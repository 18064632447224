export const getRegional = (data) => {
  const regional = data?.advisorList ?? [];

  const regionalItems = regional.reduce((acc, item) => {
    if (!acc.includes(item.region)) {
      acc.push(item.region)
    }

    return acc
  }, [])

  return regionalItems;
};

export const getConsignors = (data, regional) => {
  const allConsignors = data?.advisorList ?? [];
  const consignors = allConsignors.filter((item) => {
    return item.region === regional
  })

  return consignors;
};

export const getEmailConsignor = (data, regional, name) => {
  const allConsignors = data?.advisorList ?? [];

  const consignor = allConsignors.find((item) => {
    return item?.region === regional && item?.name === name
  });

  return consignor?.email ?? "";
}
