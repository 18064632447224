import Landing from "./src/pages/landing";

import SustainabilityReports from "./src/pages/sustainability-reports";
import SustainabilityContribution from "./src/pages/sustainability-contribution";
import SustainabilityEnvironmental from "./src/pages/sustainability-environmental/pages";
import SustainabilitySocial from "./src/pages/sustainability-social/pages";
import CorporateGovernance from "./src/pages/corporate-governance/pages";
import SustainabilityEconomic from "./src/pages/sustainability-economic/pages";

export const LandingPage = Landing;

export const SustainabilityReportsPage = SustainabilityReports;
export const SustainabilityContributionPage = SustainabilityContribution;
export const SustainabilityEnvironmentalPage = SustainabilityEnvironmental;
export const SustainabilitySocialPage = SustainabilitySocial;
export const CorporateGovernancePage = CorporateGovernance;
export const SustainabilityEconomicPage = SustainabilityEconomic;
