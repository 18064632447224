import React from "react";
import Container from "@coninsa-s2/container";
import NumberCard from "@coninsa-s2/number-card";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "@coninsa-s2/slider/styles.css";

function ListCard() {
  const sliderOptions = {
    arrows: false,
    gap: "2.5rem",
    perPage: 4,
    perMove: 1,
    pagination: false,
    breakpoints: {
      640: {
        arrows: false,
        gap: "2rem",
        perPage: 1,
        padding: { left: 0, right: "5rem" },
        pagination: true,
      },
    },
  };

  return (
    <Container>
      <div className="rounded-lg bg-white p-6 shadow-lg lg:p-8">
        <h2 className="mb-8 text-xl leading-6 text-s2-dark-blue md:text-3xl md:leading-8">
          <span className="block font-bold">Conoce el paso a paso</span> para
          referir a tus amigos o familiares
        </h2>

        <Splide options={sliderOptions} className="s2-slider s2-slider--light">
          <SplideSlide>
            <NumberCard number={1} className="pb-10" withArrow tint="lime">
              <p>
                <span className="font-bold">
                  Cuéntales a tus amigos o familiares{" "}
                </span>
                sobre nuestros proyectos para que seleccionen el de su interés.
              </p>
            </NumberCard>
          </SplideSlide>
          <SplideSlide>
            <NumberCard number={2} className="pb-10" withArrow tint="lime">
              <p>
                <span className="font-bold">
                  Ingresa tus datos y los de la persona que refieres,{" "}
                </span>
                seleccionando el proyecto de interés.
              </p>
            </NumberCard>
          </SplideSlide>
          <SplideSlide>
            <NumberCard number={3} className="pb-10" withArrow tint="lime">
              <p>
                Verificaremos que tu referido{" "}
                <span className="font-bold">
                  no se encuentre activo en nuestros proyectos{" "}
                </span>
                y así pueda ser contactado por uno de nuestros consultores.
              </p>
            </NumberCard>
          </SplideSlide>
          <SplideSlide>
            <NumberCard number={4} className="pb-10" tint="lime">
              <p>
                <span className="font-bold">
                  Si tu referido realiza la compra de nuestro proyecto,{" "}
                </span>
                uno de nuestros consultores se comunicarán contigo para
                confirmar los beneficios.
              </p>
            </NumberCard>
          </SplideSlide>
        </Splide>
      </div>
    </Container>
  );
}

export default ListCard;
