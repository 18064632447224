import React, { useEffect, useState } from "react";
import Footsteps from "@coninsa-ui/react-footsteps";
import { CurrencyProvider } from "@coninsa-s2/currency";

import "./src/style.css";

import StepOne from "./src/StepOne";
import StepTwo from "./src/StepTwo";
import StepThree from "./src/StepThree";
import StepFour from "./src/StepFour";
import StepFive from "./src/StepFive";

import { getProject, T_zoho_detail } from "./src/api/zoho-api";
import quotationStorage from "./src/storage/quotation";

const QUOTATION_STEPS = [
  { label: 1, desc: "Datos de contacto" },
  { label: 2, desc: "Personaliza tu Inmueble" },
  { label: 3, desc: "Forma de pago" },
  { label: 4, desc: "Financiación" },
  { label: 5, desc: "Inmueble simulado" },
];

interface T_props {
  projectCode: string;
  propertyCode: string;

  zohoDetail: T_zoho_detail;
}

export default function Quotation({
  projectCode,
  propertyCode,
  zohoDetail,
}: T_props) {
  const [step, setStep] = useState(1);
  const [isReady, setReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasError, setError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const data = await getProject(projectCode);
        const selectedUnit = data.units.find(
          (unit) => unit.idInmuebleABR == propertyCode
        );

        if (selectedUnit) {
          quotationStorage.set({
            other_units: data.other_units,
            project: data.project,
            credit_parameters: data.credit_parameters,
            unit: selectedUnit,
            quote_concepts: data.quote_concepts,
          });

          setReady(true);
        } else {
          setError(true);
        }
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    };

    fetchData()
      // make sure to catch any error
      .catch(console.error);
  }, []);

  return (
    <CurrencyProvider>
      <div className="co-quotation">
        <div className="co-quotation__container">
          {!isReady && (
            <div>
              {loading && <p>Cargando ...</p>}
              {hasError && (
                <p className="mt-4 text-orange-700">
                  Ha ocurrido un error, vuelva a intentarlo más tarde.
                </p>
              )}
            </div>
          )}

          {isReady && (
            <div>
              <div>
                <Footsteps steps={QUOTATION_STEPS} currentStep={step} />

                <div>
                  {step === 1 && <StepOne nextCallback={() => setStep(2)} />}

                  {step === 2 && (
                    <StepTwo
                      backCallback={() => setStep(1)}
                      nextCallback={() => setStep(3)}
                    />
                  )}

                  {step === 3 && (
                    <StepThree
                      backCallback={() => setStep(2)}
                      nextCallback={() => setStep(4)}
                    />
                  )}

                  {step === 4 && (
                    <StepFour
                      backCallback={() => setStep(3)}
                      nextCallback={() => setStep(5)}
                    />
                  )}

                  {step === 5 && (
                    <StepFive
                      zohoDetail={zohoDetail}
                      backCallback={() => setStep(1)}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </CurrencyProvider>
  );
}
