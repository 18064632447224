import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import "react-intl-tel-input-18/dist/main.css";
import parsePhoneNumber from "libphonenumber-js";
import IntlPhone from "@coninsa-s2/intl-phone";

import { Icon } from "ui";
import Button from "@coninsa-s2/button";

import { Box, Input, Checkbox, Label } from "theme-ui";

import HabeasData from "component-v2/HabeasData";

type T_props = {
  code: string;
  path: string;
  canon: string;
  propertyType: string;
  region: string;
  serviceType: string;

  iconName?: string;
  buttonLabel?: string;
  buttonBgColor?: string;

  onSubmitForm: (property: object, data: object, type: string) => void;
  redirectType: "THANKS_PAGE" | "WHATSAPP_PAGE";
};

function BuildingContactForm({
  code,
  path,
  canon,
  propertyType,
  region,
  serviceType,

  iconName = "fi-phone",
  buttonLabel = "Recibir una llamada",
  buttonBgColor = "blue",

  onSubmitForm,
  redirectType,
}: T_props) {
  const [lat, setLat] = useState();
  const [lon, setLon] = useState();
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({ defaultValues: { phone: "" } });

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      // @ts-ignore
      setLat(position.coords.latitude);
      // @ts-ignore
      setLon(position.coords.longitude);
    });
  }, []);

  const onSubmit = async (data) => {
    await onSubmitForm(
      {
        region,
        propertyType,
        serviceType,
        code,
        canon,
      },
      data,
      redirectType
    );
  };

  const phoneStyles = {
    ".allow-dropdown.intl-tel-input": { display: "block" },
    input: {
      width: "100%",
      border: "1px solid #d0d1d1",
      borderRadius: "4px",
      lineHeight: "inherit",
      py: "12px",
    },
  };

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)}>
      <h4 className="font-bold mb-4">Contáctame</h4>

      <div className="flex flex-col gap-2">
        <Input {...register("name")} id="name" placeholder="Nombres" required />
        <Input
          {...register("last_name")}
          id="last_name"
          placeholder="Apellidos"
          required
        />
        <Box sx={phoneStyles}>
          <Controller
            control={control}
            name="phone"
            rules={{
              validate: (value) =>
                parsePhoneNumber(value)?.isValid() ||
                "Número de teléfono inválido",
            }}
            render={({ field: { onChange, value } }) => (
              <IntlPhone
                id="phone"
                placeholder="Número de contacto"
                defaultCountry={"co"}
                onChange={onChange}
                required
                value={value}
              />
            )}
          />

          {errors?.phone && (
            <p className="text-xs lg:text-sm text-s2-red text-left mt-1">
              {errors?.phone?.message}
            </p>
          )}
        </Box>
        <Input
          {...register("email")}
          id="email"
          placeholder="Correo electrónico"
          required
        />

        <HabeasData />

        <Label>
          <Checkbox {...register("accept_conditions")} required />
          <Box sx={{ fontSize: [1, 0], textAlign: "left" }}>
            <span>Autorizo el tratamiento y la </span>
            <Box
              as="a"
              href="https://www.coninsa.co/politica-de-tratamiento-de-datos-personales-de-coninsa-ramon-h-sa"
              sx={{ color: "primary", textDecoration: "none" }}
              target="_blank"
            >
              política de datos personales
            </Box>
          </Box>
        </Label>
      </div>

      <Button size="fluid" tint={buttonBgColor}>
        <Icon name={iconName} />
        {buttonLabel}
      </Button>
    </Box>
  );
}

export default BuildingContactForm;
