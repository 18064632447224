import React from "react";
import Layout from "layout/default/Layout";

import ClimateChangePage from "@coninsa-site/sustainability/src/pages/sustainability-environmental/pages/climate-change"

function ClimateChange() {
  return (
    <Layout>
      <ClimateChangePage />
    </Layout>
  )
}

export default ClimateChange;
