import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "@coninsa-s2/slider/styles.css";

import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import SpeechBubble from "@coninsa-s2/speech-bubble";
import Heading from "@coninsa-s2/heading";
import * as BubbleCard from "@coninsa-s2/bubble-card";
import Button from "@coninsa-s2/button";

import OurRecognitions from "../sections/our-recognitions";

import sustainabilityImage from "../../assets/images/banner-m.png";
import sustainabilityMobileImage from "../../assets/images/mobile-banner.png";
import sustainabilityModelImage from "../../assets/images/sustainability-model.png";
import trajectoryImage from "../../assets/images/trajectory-image.png";
import sustFirstImg from "../../assets/images/sust-first.png";
import sustSecondImg from "../../assets/images/sust-second.png";
import sustThirdImg from "../../assets/images/sust-third.png";
import sustFourthImg from "../../assets/images/sust-fourth.png";

import sheetIcon from "../../assets/icons/sheet.svg";
import fingerThumbIcon from "../../assets/icons/finger-thumb.svg";

const data = [
  {
    id: "s1",
    url: "/corporativo/sostenibilidad/gobierno-corporativo",
    picture: sustFirstImg,
    tag: "",
    title: "Gobierno Corporativo",
  },
  {
    id: "s2",
    url: "/corporativo/sostenibilidad/sostenibilidad-economica",
    picture: sustSecondImg,
    tag: "Sostenibilidad",
    title: "Económica",
  },
  {
    id: "s3",
    url: "/corporativo/sostenibilidad/sostenibilidad-social",
    picture: sustThirdImg,
    tag: "Sostenibilidad",
    title: "Social",
  },
  {
    id: "s4",
    url: "/corporativo/sostenibilidad/sostenibilidad-ambiental",
    picture: sustFourthImg,
    tag: "Sostenibilidad",
    title: "Ambiental",
  },
];

export default function SustainabilityPage() {
  const sliderOptions = {
    arrows: false,
    gap: 12,
    perPage: 4,
    pagination: false,
    breakpoints: {
      640: {
        arrows: false,
        gap: 12,
        perPage: 1,
        padding: { left: 0, right: "7rem" },
        pagination: true,
      },
    },
  };

  return (
    <div>
      <div className="grid grid-cols-1 bg-s2-lime lg:grid-cols-2">
        <img
          src={sustainabilityImage}
          className="hidden w-full object-cover lg:block"
        />
        <img src={sustainabilityMobileImage} className="w-full lg:hidden" />

        <div className="flex flex-col justify-center px-4 pb-28 pt-10 lg:px-0 lg:py-8 xl:w-[40vw]">
          <div className="lg:max-w-md">
            <div className="text-white lg:flex">
              <div>
                <h1 className="text-2xl font-extrabold lg:mb-3 lg:text-5xl">
                  Coninsa{" "}
                  <span className="block font-handwritting text-8xl font-normal text-s2-teal lg:text-9xl">
                    Sostenible
                  </span>
                </h1>

                <p className="mt-2 pl-10 text-end text-xl font-medium leading-5 md:pl-7 lg:mt-4 lg:mb-12 lg:text-4xl lg:leading-10">
                  ¡Construimos bienestar
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Section className="relative -mt-24 lg:-mt-[200px]">
        <Container>
          <div className="grid-cols-2 items-center rounded-lg bg-gradient-to-b from-white from-50% to-s2-gray-100 to-40% shadow-lg lg:grid lg:bg-gradient-to-r lg:from-50% lg:to-50%">
            <div className="mb-5 px-4 py-5 lg:px-16">
              <SpeechBubble className="mb-8 text-2xl font-bold">
                <h2>Nuestro objetivo</h2>
              </SpeechBubble>
              <div className="text-s2-gray-600 lg:text-lg">
                <p className="mb-4">
                  Es{" "}
                  <span className="font-bold text-s2-teal">
                    promover la Sostenibilidad
                  </span>{" "}
                  incorporando los principios de la inversión responsable,
                  contribuyendo con la construcción de bienestar y creando valor
                  para las partes interesadas.
                </p>
                <p>
                  Esto incluirá una adecuada gestión de los riesgos y
                  oportunidades ambientales, sociales y de gobierno corporativo
                  en los diferentes negocios de la compañía,{" "}
                  <span className="font-bold">
                    {" "}
                    así como una comunicación transparente de nuestra estrategia
                    de Sostenibilidad
                  </span>
                  .
                </p>
              </div>
            </div>
            <div className="relative items-center px-4 py-6 lg:flex lg:px-20 lg:py-5">
              <div className="flex items-center justify-center">
                <h3 className="absolute -top-8 rounded bg-s2-teal px-4 font-heading text-white lg:-left-[188px] lg:top-56 lg:-rotate-90 lg:text-xl">
                  Nuestro Modelo de Sostenibilidad
                </h3>
              </div>
              <div className="flex justify-center">
                <img
                  src={sustainabilityModelImage}
                  className="h-80 w-80 object-contain lg:h-auto lg:w-auto"
                />
              </div>
            </div>
          </div>
        </Container>
      </Section>

      <Section ptop="small">
        <Container>
          <div className="mb-8 lg:mb-12">
            <Heading
              as="h2"
              size="4xl"
              condensed
              className="mb-2 !font-medium lg:mb-4 lg:text-center"
            >
              <span className="block font-extralight text-s2-teal lg:inline-flex uppercase">
                Conoce sobre{" "}
              </span>{" "}
              nuestra trayectoria
            </Heading>
            <a
              href="https://www.youtube.com/watch?v=dqTdix8_hhM"
              target="_blank"
            >
              <img src={trajectoryImage} alt="" className="mx-auto" />
            </a>
          </div>

          <div>
            <div className="mb-10 text-balance text-center lg:text-lg">
              <span className="block font-bold text-s2-teal">
                Nuestra pasión por la Sostenibilidad impulsa cada decisión y
                acción que tomamos.{" "}
              </span>{" "}
              Distribuimos nuestros esfuerzos en cuatro pilares fundamentales
              para nuestra compañía y lograr alcanzar nuestro propósito superior
              Construimos Bienestar.
            </div>

            <Splide
              options={sliderOptions}
              className="s2-slider s2-slider--lg mt-5"
            >
              {data.map((item) => (
                <SplideSlide key={item.id}>
                  <BubbleCard.Root image={item.picture} className="h-60">
                    <BubbleCard.Content className="min-h-20 bg-s2-teal">
                      <div className="font-light lg:text-lg">{item?.tag}</div>
                      <h3 className="text-lg font-bold leading-4 lg:text-[22px]">
                        {item.title}
                      </h3>
                    </BubbleCard.Content>
                    <BubbleCard.Anchor href={item.url} />
                  </BubbleCard.Root>
                </SplideSlide>
              ))}
            </Splide>
          </div>
        </Container>
      </Section>

      <Section className="bg-gradient-to-b from-s2-lime from-50% to-s2-green to-40% lg:bg-gradient-to-r lg:from-50% lg:to-50%">
        <Container>
          <div className="grid-cols-2 items-center lg:grid">
            <div className="pb-4 lg:px-24 lg:pt-0">
              <img
                src={sheetIcon}
                alt=""
                className="mb-3 w-14 rounded-full bg-white p-3 lg:w-auto lg:p-4"
              />
              <div>
                <div className="text-lg text-white lg:text-xl">
                  Descarga nuestros
                </div>
                <Heading as="h3" size="xl" className="mb-4 !text-white lg:mb-6">
                  Reportes de Sostenibilidad
                </Heading>
                <Button
                  tint="white"
                  className="!text-s2-dark-blue hover:!text-s2-white"
                  href="/corporativo/sostenibilidad/reporte-de-sostenibilidad"
                >
                  Conocer reportes
                </Button>
              </div>
            </div>
            <div className="pt-20 lg:px-24 lg:pt-0">
              <img
                src={fingerThumbIcon}
                alt=""
                className="mb-3 w-14 rounded-full bg-white p-3 lg:w-auto lg:p-4"
              />
              <div>
                <div className="text-lg text-white lg:text-xl">
                  Conoce más sobre
                </div>
                <Heading as="h3" size="xl" className="mb-4 !text-white lg:mb-6">
                  Nuestro aporte a los Objetivos de Desarrollo Sostenible
                </Heading>
                <Button
                  tint="white"
                  className="!text-s2-dark-blue hover:!text-s2-white"
                  href="/corporativo/sostenibilidad/nuestro-aporte-a-ods"
                >
                  Más información
                </Button>
              </div>
            </div>
          </div>
        </Container>
      </Section>

      <OurRecognitions />
    </div>
  );
}
