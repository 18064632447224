import React from "react";
import Layout from "layout/default/Layout";

import AnticorruptionPracticesPage from "@coninsa-site/sustainability/src/pages/corporate-governance/pages/anticorruption-practices"

function AnticorruptionPractices() {
  return (
    <Layout>
      <AnticorruptionPracticesPage />
    </Layout>
  )
}

export default AnticorruptionPractices;
