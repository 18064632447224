import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../layout/default/Layout'
import ContactForm from '../component-v2/ContactformHabeasABR'

function BussinessEthic() {
  const showBodyCard = bodyCardId => event => {
    const bodyElements = document.querySelectorAll('.tab-body')
    const tabElements = document.querySelectorAll('.tab-label[data-active]')

    bodyElements.forEach(element => {
      element.classList.add('hidden')
    })

    tabElements.forEach(element => {
      delete element.dataset.active
    })

    const buttonElement = event.currentTarget

    buttonElement.dataset.active = true

    // Look for body card element.
    const bodyElement = document.querySelector(bodyCardId)

    if (bodyElement) {
      bodyElement.classList.remove('hidden')
    }
  }

  return (
    <Layout>
      <Helmet>
        <title>Aceptar politica de tratamiento de datos arrendamientos | Coninsa</title>
        <meta name="description" content="Revisa y acepta las políticas de tratamiento de datos personales en Arrendamientos Coninsa.co para asegurar la protección de tu información. Nuestro compromiso con la privacidad de nuestros clientes garantiza un manejo seguro y responsable de tus datos personales." />
        <link rel="canonical" href="https://www.coninsa.co/aceptar-politica-de-tratamiento-de-datos-proyectos/" />
      </Helmet>

      <div className='bg-coninsa-green-300 pb-10 pt-10 md:pt-16 md:pb-16 mt-10'>
          <div className='container px-5 md:px-0 text-center mb-8'>
            <h3 className='font-display text-white text-[28px] md:text-[33px]'>
              En el siguiente formulario
            </h3>
            <p className='text-white text-base md:text-lg mb-4'>
              podras aceptar la política de tratamiento de datos de www.coninsa.co
            </p>
            <div className='border border-solid border-white w-[302px] my-0 mx-auto'></div>
          </div>
      </div>
      
      <div className='bg-white relative pb-10 md:pb-16'>
          <div className='absolute bg-coninsa-green-300 h-48 left-0 right-0 top-0'></div>
          <div className='container relative'>
            <div className='px-5 md:px-40'>
              <div className=' bg-white rounded-lg opacity-100 p-6 md:p-10 shadow-[0px_3px_6px_#00000029]'>
                <p className='text-base md:text-lg text-center mb-4 font-medium'>
                  Por favor ingrese el número de contacto.
                </p>
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
    </Layout>
  )
}

export default BussinessEthic
