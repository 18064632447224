import React from "react";
import Layout from "layout/default/Layout";

import ContractorCompaniesPage from "@coninsa-site/sustainability/src/pages/sustainability-social/pages/contractor-companies"

function ContractorCompanies() {
  return (
    <Layout>
      <ContractorCompaniesPage />
    </Layout>
  )
}

export default ContractorCompanies;
