import React from "react";
import Layout from "layout/default/Layout";

import CorporateGovernancePage from "@coninsa-site/sustainability/src/pages/corporate-governance/pages/corporate-governance"

function CorporateGovernance() {
  return (
    <Layout>
      <CorporateGovernancePage />
    </Layout>
  )
}

export default CorporateGovernance;
