import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "@coninsa-s2/slider/styles.css";

import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import * as BubbleCard from "@coninsa-s2/bubble-card";
import SpeechBubble from "@coninsa-s2/speech-bubble";
import Button from "@coninsa-s2/button";
import IconButton from "@coninsa-s2/icon-button";

import constructionBanner from "@coninsa-site/sustainability/assets/images/environmental/construction-banner.png";
import constructionMobileBanner from "@coninsa-site/sustainability/assets/images/environmental/construction-mobile-banner.png";
import buildingImage from "@coninsa-site/sustainability/assets/images/environmental/building.png";
import mobileBuildingImage from "@coninsa-site/sustainability/assets/images/environmental/mobile-building.png";
import casaBrandImage from "@coninsa-site/sustainability/assets/images/environmental/casa.png";
import personImage from "@coninsa-site/sustainability/assets/images/environmental/clara-mesa.png";
import personMobileImage from "@coninsa-site/sustainability/assets/images/environmental/mobile-clara-mesa.png";
import climateChangeImage from "@coninsa-site/sustainability/assets/images/environmental/climate-change.png";
import biodiversityImage from "@coninsa-site/sustainability/assets/images/environmental/biodiversity.png";
import economyImage from "@coninsa-site/sustainability/assets/images/environmental/economy.png";
import LeftArrowIcon from "@coninsa-site/sustainability/assets/icons/left-arrow.inline.svg";

import laRiviereIcon from "@coninsa-site/sustainability/assets/images/environmental/la-riviere.png";
import civitaIcon from "@coninsa-site/sustainability/assets/images/environmental/civita.png";
import ferraraIcon from "@coninsa-site/sustainability/assets/images/environmental/ferrara.png";
import viewPointIcon from "@coninsa-site/sustainability/assets/images/environmental/view-point.png";
import montiaraIcon from "@coninsa-site/sustainability/assets/images/environmental/montiara.png";
import moccaIcon from "@coninsa-site/sustainability/assets/images/environmental/mocca.png";
import sueIcon from "@coninsa-site/sustainability/assets/images/environmental/sue.png";
import vinariIcon from "@coninsa-site/sustainability/assets/images/environmental/vinari.png";

const projects = [
  {
    id: "p-01",
    image: laRiviereIcon,
    name: "La Riviere",
  },
  {
    id: "p-02",
    image: civitaIcon,
    name: "Civita",
  },
  {
    id: "p-03",
    image: ferraraIcon,
    name: "Ferrara",
  },
  {
    id: "p-04",
    image: viewPointIcon,
    name: "View Point",
  },
  {
    id: "p-05",
    image: montiaraIcon,
    name: "Montiara",
  },
  {
    id: "p-06",
    image: moccaIcon,
    name: "Mocca",
  },
  {
    id: "p-07",
    image: sueIcon,
    name: "Sue Apartaments",
  },
  {
    id: "p-08",
    image: vinariIcon,
    name: "Vinari",
  },
];

const data = [
  {
    id: "ce-01",
    url: "/corporativo/sostenibilidad/sostenibilidad-ambiental/economia-circular",
    image: economyImage,
    title: "Economía Circular",
  },
  {
    id: "ce-02",
    url: "/corporativo/sostenibilidad/sostenibilidad-ambiental/cambio-climatico",
    image: climateChangeImage,
    title: "Cambio climático",
  },
  {
    id: "ce-03",
    url: "/corporativo/sostenibilidad/sostenibilidad-ambiental/biodiversidad",
    image: biodiversityImage,
    title: "Biodiversidad",
  },
];

export default function SustainableConstructionPage() {
  const sliderOptions = {
    arrows: false,
    gap: 12,
    perPage: 3,
    pagination: false,
    breakpoints: {
      640: {
        arrows: false,
        gap: 12,
        perPage: 1,
        padding: { left: 0, right: "6rem" },
        pagination: true,
      },
    },
  };

  return (
    <div>
      <Section tint="green" className="mb-5 !py-5 lg:mb-8">
        <Container>
          <div className="items-center gap-4 lg:flex">
            <IconButton
              href="/corporativo/sostenibilidad/sostenibilidad-ambiental"
              className="mb-5 lg:mb-0"
            >
              <LeftArrowIcon />
              Regresar
            </IconButton>
            <div className="text-2xl font-bold text-white">
              Sostenibilidad ambiental
            </div>
          </div>
        </Container>
      </Section>
      <Container className="mb-10">
        <div className="">
          <div className="flex justify-center">
            <img
              src={constructionMobileBanner}
              alt=""
              className="absolute block lg:hidden"
            />
          </div>

          <img
            src={constructionBanner}
            alt=""
            className="absolute hidden lg:block"
          />

          <div className="relative z-10 pt-56 lg:pt-10">
            <Container>
              <h1 className="text-center text-3xl font-extrabold text-white lg:w-[470px] lg:text-4xl">
                Construcción{" "}
                <span className="block font-handwritting text-7xl font-medium text-s2-lime lg:text-8xl">
                  Sostenible
                </span>
              </h1>
            </Container>
          </div>
        </div>
      </Container>

      <Section tint="ligther-gray">
        <Container>
          <div className="grid-cols-12 justify-between gap-8 lg:grid">
            <div className="col-span-7 mb-6 w-auto">
              <div className="prose mb-4 rounded-[4px] border-l-4 border-s2-lime pl-4 text-s2-gray-600 lg:mb-6 lg:border-l-8 lg:pl-5 lg:text-lg">
                <p>
                  Debido a los retos que tenemos como sociedad en materia de
                  conservación de los recursos naturales y la adaptación y
                  mitigación ante el cambio climático, la sostenibilidad cada
                  vez toma más fuerza en el sector constructor, buscando así
                  generar bienestar para las personas, cuidar el ambiente y ser
                  eficientes económicamente.
                </p>
                <p>
                  En la medida que se vienen incorporando criterios de
                  sostenibilidad en el diseño, construcción y operación de las
                  edificaciones, los consumidores vienen validando sus
                  beneficios asociados con la ecoeficiencia, el confort
                  habitacional y la reducción de costos en el uso de los
                  servicios públicos.
                </p>
                <p>
                  La creación de alianzas entre empresa, universidad y
                  organismos como <span className="font-bold">Camacol</span> y
                  el{" "}
                  <span className="font-bold">
                    Consejo Colombiano de Construcción Sostenible
                  </span>
                  , han permitido fortalecer todo el sector constructor,
                  evolucionado a nuevos productos inmobiliario con
                  características de sostenibilidad, muchos de estos con sellos
                  de construcción sostenible como{" "}
                  <span className="font-bold">EDGE, LEED, CASA</span>, entre
                  otros.
                </p>
                <p>
                  En el marco de su modelo de sostenibilidad Coninsa ha definido
                  la construcción sostenible como un aspecto relevante que
                  aporta al cumplimiento de nuestro propósito superior de
                  construir bienestar, logrando impactar de manera positiva, los
                  grupos de interés desde los aspectos ambiental, social y
                  económico. Lo anterior, toda vez que logra reducir el consumo
                  de recursos naturales en la fase de construcción y operación
                  de proyectos constructivos, garantizar espacios con
                  características de hábitat adecuadas, además de generar
                  réditos financieros en la construcción y operación de los
                  mismos.
                </p>
              </div>
            </div>
            <div className="col-span-5">
              <div className="flex justify-center">
                <img
                  src={mobileBuildingImage}
                  alt="Coninsa green icon"
                  className="z-50 block lg:hidden"
                />
                <img
                  src={buildingImage}
                  alt="Coninsa green icon"
                  className="z-50 hidden lg:block"
                />
              </div>
              <div className="relative mx-auto -mt-14 hidden gap-4 rounded-b-2xl bg-s2-mint px-8 pb-14 pt-24 text-2xl font-medium text-white lg:flex lg:w-[400px]">
                <div>
                  <div className="font-extrabold">Proyecto Vida Park</div>
                  <div className="text-balance">
                    Precertificado CASA Colombia
                  </div>
                </div>
                <div>
                  <img src={casaBrandImage} alt="CASA brand" />
                </div>
              </div>
            </div>
          </div>
        </Container>
        <div className="relative -mt-16 flex gap-3 rounded-2xl bg-s2-mint px-8 pb-6 pt-20 text-xl font-medium text-white lg:hidden">
          <div>
            <div className="font-extrabold">Proyecto Vida Park</div>
            <div className="text-balance">Precertificado CASA Colombia</div>
          </div>
          <div>
            <img src={casaBrandImage} alt="CASA brand" />
          </div>
        </div>
      </Section>

      <Section
        ptop="small"
        className="bg-gradient-to-b from-s2-lime from-50% to-s2-teal to-45% !pb-0 lg:bg-gradient-to-r lg:from-60% lg:to-40%"
      >
        <Container>
          <div className="grid-cols-12 gap-8 pb-14 pt-4 lg:grid lg:pb-0 lg:pt-0">
            <div className="items-center pb-20 lg:col-span-8 lg:flex lg:pb-0">
              <div className="flex items-center">
                <img
                  src={personMobileImage}
                  alt=""
                  className="z-40 block lg:hidden"
                />
                <img
                  src={personImage}
                  alt=""
                  className="z-40 hidden lg:block"
                />

                <SpeechBubble
                  tint="yellow"
                  size="large"
                  className="relative -ml-20 -mt-24 inline-block py-2 pl-7 pr-3 lg:-ml-36 lg:-mt-80 lg:px-16 lg:py-4"
                >
                  <h2>
                    <div className="text-sm font-bold lg:text-2xl">
                      Hablemos sobre las
                    </div>
                    <div className="text-[28px] font-extrabold leading-5 text-s2-teal lg:text-[40px] lg:leading-7">
                      certificaciones
                    </div>
                    <div className="text-sm lg:text-2xl">
                      en construcción sostenible
                    </div>
                  </h2>
                </SpeechBubble>
              </div>

              <div className="text-center lg:-ml-36 lg:pt-20">
                <div className="text-xl font-semibold text-s2-dark-blue">
                  Escucha el podcast con
                </div>
                <div className="text-4xl font-extrabold text-white">
                  Clara Mesa
                </div>
                <div className="mb-4 text-balance text-lg !leading-5 text-white lg:mb-8">
                  Dirección de Estructuración Técnica y Constructiva de
                  Proyectos.
                </div>
                <Button
                  tint="white"
                  className="!text-s2-dark-blue hover:!text-s2-white"
                  href="#"
                >
                  Escuchar podcast
                </Button>
              </div>
            </div>
            <div className="col-span-4">
              <div className="mb-8 hidden text-center text-s2-mint lg:block">
                <h3>
                  <div className="text-xl">Conoce nuestros proyectos </div>
                  <div className="text-2xl font-bold leading-5">
                    Con Precertificado EDGE
                  </div>
                </h3>
              </div>

              <div className="grid grid-cols-3 gap-4">
                {projects.map((item) => (
                  <img
                    key={item.id}
                    src={item.image}
                    alt={`Proyecto ${item.name}`}
                  />
                ))}
              </div>
            </div>
          </div>
        </Container>
      </Section>

      <Section className="relative -mt-16 lg:-mt-20">
        <Container>
          <SpeechBubble
            tint="mint"
            className="z-50 mb-12 px-9 py-2 text-xl font-bold lg:px-10 lg:py-2 lg:text-2xl"
          >
            Ambiental
          </SpeechBubble>
          <div className="items-center justify-between gap-4 lg:flex">
            <h2 className="font-display text-4xl font-extralight uppercase text-s2-teal lg:text-5xl">
              Prácticas{" "}
              <span className="block font-medium text-s2-dark-blue">
                sostenibles
              </span>
            </h2>

            <Splide
              options={sliderOptions}
              className="s2-slider s2-slider--lg mt-5"
            >
              {data.map((item) => (
                <SplideSlide key={item.id}>
                  <BubbleCard.Root image={item.image} className="h-64 lg:h-80">
                    <BubbleCard.Content className="min-h-24">
                      <h3 className="text-lg font-bold text-white lg:text-[22px]">
                        {item.title}
                      </h3>
                    </BubbleCard.Content>
                    <BubbleCard.Anchor href={item.url} />
                  </BubbleCard.Root>
                </SplideSlide>
              ))}
            </Splide>
          </div>
        </Container>
      </Section>
    </div>
  );
}
