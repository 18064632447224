import React from "react";
import Layout from "layout/default/Layout";

import DigitalTransformPage from "@coninsa-site/sustainability/src/pages/sustainability-economic/pages/digital-transform"

function DigitalTransform() {
  return (
    <Layout>
      <DigitalTransformPage />
    </Layout>
  )
}

export default DigitalTransform;
