import React from "react";

import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import Button from "@coninsa-s2/button";

import VideoImg from "../images/video-img.png";
import PersonIcon from "../icons/person.inline.svg";

export default function McvGuideIntro() {
  return (
    <Section>
      <Container>
        <div className="gap-24 lg:grid lg:grid-cols-2">
          <div className="mb-12 lg:mb-0">
            <h2 className="mb-4 text-2xl font-semibold leading-6 text-s2-dark-blue lg:mb-6">
              ¡Bienvenido a
              <span className="block text-3xl font-extrabold">
                {" "}
                Mi Coninsa Virtual!
              </span>
            </h2>

            <div className="mb-6 flex flex-col gap-5 text-sm text-s2-gray-600 lg:text-base">
              <p>
                <strong>Un lugar pensado para tu comodidad</strong>, en el que
                podrás realizar todas las consultas y transacciones relacionadas
                a tus inmuebles de arrendamientos o tus proyectos nuevos.
              </p>
              <p>
                Si eres cliente Coninsa podrás acceder desde cualquier lugar y a
                cualquier hora para consultar estados de cuenta, descargar
                certificados e incluso realizar pagos.
              </p>
              <p>
                Si aún no sabes como ingresar o regístrate te invitamos a ver el
                video o a seguir el paso a paso.
              </p>
            </div>

            <div className="rounded-lg bg-white px-8 py-7 text-center shadow-[0px_3px_6px_#00000029] lg:inline-block">
              <div className="mb-5 font-bold">Ingresar a tu cuenta</div>
              <Button
                href="https://miconinsavirtual.coninsa.co"
                target="_blank"
                className="flex items-center"
              >
                <PersonIcon />
                Ir a Mi Coninsa Virtual
              </Button>
            </div>
          </div>

          <div className="text-center">
            <div className="mx-3 -mb-4 rounded-lg bg-s2-gray-300 px-14 py-5 lg:inline-block">
              <div className="mb-3 font-bold text-s2-green lg:text-lg">
                Te invitamos a descubrir
                <span className="block font-display text-3xl text-s2-dark-blue lg:text-[34px]">
                  Mi Coninsa Virtual
                </span>
              </div>
              <p className="text-center text-sm leading-5 lg:text-base">
                A través de este video <br />
                especialmente preparado para ti.
              </p>
            </div>
            <a href="https://www.youtube.com/embed/b_utl21WCI4" target="_blank">
              <img src={VideoImg} />
            </a>
          </div>
        </div>
      </Container>
    </Section>
  );
}
