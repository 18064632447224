import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "@coninsa-s2/slider/styles.css";

import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import * as BubbleCard from "@coninsa-s2/bubble-card";
import SpeechBubble from "@coninsa-s2/speech-bubble";
import IconButton from "@coninsa-s2/icon-button";

import climateChangeBanner from "@coninsa-site/sustainability/assets/images/environmental/climate-change-banner.png";
import climateChangeMobileBanner from "@coninsa-site/sustainability/assets/images/environmental/climate-change-mobile-banner.png";
import workerImage from "@coninsa-site/sustainability/assets/images/environmental/worker.png";
import mobileWorkerImage from "@coninsa-site/sustainability/assets/images/environmental/mobile-worker.png";
import economyImage from "@coninsa-site/sustainability/assets/images/environmental/economy.png";
import biodiversityImage from "@coninsa-site/sustainability/assets/images/environmental/biodiversity.png";
import constructionImage from "@coninsa-site/sustainability/assets/images/environmental/construction.png";
import LeftArrowIcon from "@coninsa-site/sustainability/assets/icons/left-arrow.inline.svg";

const data = [
  {
    id: "cc-01",
    url: "/corporativo/sostenibilidad/sostenibilidad-ambiental/economia-circular",
    image: economyImage,
    title: "Economía Circular",
  },
  {
    id: "ce-02",
    url: "/corporativo/sostenibilidad/sostenibilidad-ambiental/construccion-sostenible",
    image: constructionImage,
    title: "Construcción sostenible",
  },
  {
    id: "ce-03",
    url: "/corporativo/sostenibilidad/sostenibilidad-ambiental/biodiversidad",
    image: biodiversityImage,
    title: "Biodiversidad",
  },
];

export default function ClimateChangePage() {
  const sliderOptions = {
    arrows: false,
    gap: 12,
    perPage: 3,
    pagination: false,
    breakpoints: {
      640: {
        arrows: false,
        gap: 12,
        perPage: 1,
        padding: { left: 0, right: "6rem" },
        pagination: true,
      },
    },
  };

  return (
    <div>
      <Section tint="green" className="mb-5 !py-5 lg:mb-8">
        <Container>
          <div className="items-center gap-4 lg:flex">
            <IconButton
              href="/corporativo/sostenibilidad/sostenibilidad-ambiental"
              className="mb-5 lg:mb-0"
            >
              <LeftArrowIcon />
              Regresar
            </IconButton>
            <div className="text-2xl font-bold text-white">
              Sostenibilidad ambiental
            </div>
          </div>
        </Container>
      </Section>
      <Container className="mb-10">
        <div className="">
          <div className="flex justify-center">
            <img
              src={climateChangeMobileBanner}
              alt=""
              className="absolute block lg:hidden"
            />
          </div>

          <img
            src={climateChangeBanner}
            alt=""
            className="absolute hidden lg:block"
          />

          <div className="relative z-10 pt-56 lg:pt-10">
            <Container>
              <h1 className="text-center text-3xl font-extrabold text-white lg:w-[470px] lg:text-4xl">
                Cambio{" "}
                <span className="block font-handwritting text-7xl font-medium text-s2-lime lg:text-8xl">
                  Climático
                </span>
              </h1>
            </Container>
          </div>
        </div>
      </Container>

      <Section tint="ligther-gray">
        <Container>
          <div className="grid-cols-12 justify-between gap-6 lg:grid">
            <div className="col-span-7 mb-6 w-auto">
              <div className="prose mb-4 rounded-[4px] border-l-4 border-s2-lime pl-4 text-s2-gray-600 lg:mb-6 lg:border-l-8 lg:pl-5 lg:text-lg">
                <p>
                  El sector de la construcción tiene un impacto significativo en
                  el cambio climático debido a las emisiones generadas a lo
                  largo de sus diversas actividades, que abarcan desde la
                  extracción de materiales, el uso de maquinaria pesada, la
                  generación de residuos, hasta la operación de las
                  edificaciones. Estas actividades demandan un alto consumo
                  energético, proveniente tanto de fuentes renovables como no
                  renovables.
                </p>
                <p>
                  Para apoyar la transición del país hacia una economía baja en
                  carbono, hemos implementado una estrategia centrada en la
                  construcción sostenible.{" "}
                  <span className="font-bold">
                    Esto implica diseñar edificaciones con un enfoque en la
                    reducción de impactos ambientales y la incorporación de
                    prácticas sostenibles a lo largo de todo su ciclo de vida.
                  </span>
                </p>
                <p>
                  En Coninsa, estamos comprometidos con este enfoque, y por ello
                  hemos integrado procesos de certificación en construcción
                  sostenible, como{" "}
                  <span className="font-bold">EDGE, LEED y Casa Colombia</span>,
                  en nuestros proyectos. Estas certificaciones aseguran que
                  durante el proceso constructivo se adopten prácticas
                  sostenibles, como la selección de materiales, la gestión
                  adecuada de residuos y el uso eficiente de los recursos.
                  Además, en la fase de operación, entregamos infraestructuras
                  diseñadas para que los usuarios finales puedan realizar un uso
                  sostenible de los recursos y mejorar su confort.
                </p>
                <p>
                  Adicionalmente, hemos implementado el{" "}
                  <span className="font-bold">“flexitrabajo”</span>, una
                  estrategia que no solo mejora la calidad de vida de nuestros
                  colaboradores, sino que también reduce el impacto ambiental al
                  disminuir las emisiones de carbono relacionadas con los
                  desplazamientos a nuestros centros de trabajo. Actualmente,
                  333 colaboradores se benefician de esta modalidad, y se ha
                  estimado que con esta práctica evitamos la emisión de
                  aproximadamente 109 toneladas de CO2 al año, contribuyendo así
                  a la mejora de la calidad del aire en la ciudad y al bienestar
                  de nuestros colaboradores.
                </p>
              </div>
            </div>
            <div className="col-span-5">
              <div className="h-72 lg:h-72">
                <div className="flex justify-center">
                  <img
                    src={mobileWorkerImage}
                    alt="Coninsa green icon"
                    className="z-50 block lg:hidden"
                  />
                  <img
                    src={workerImage}
                    alt="Coninsa green icon"
                    className="z-50 hidden lg:block"
                  />
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Section>

      <Section className="relative -mt-16 lg:-mt-20">
        <Container>
          <SpeechBubble
            tint="mint"
            className="mb-12 px-9 py-2 text-xl font-bold lg:px-10 lg:text-2xl"
          >
            Ambiental
          </SpeechBubble>
          <div className="items-center justify-between gap-4 lg:flex">
            <h2 className="font-display text-4xl font-extralight uppercase text-s2-teal lg:text-5xl">
              Prácticas{" "}
              <span className="block font-medium text-s2-dark-blue">
                sostenibles
              </span>
            </h2>

            <Splide
              options={sliderOptions}
              className="s2-slider s2-slider--lg mt-5"
            >
              {data.map((item) => (
                <SplideSlide key={item.id}>
                  <BubbleCard.Root image={item.image} className="h-64 lg:h-80">
                    <BubbleCard.Content className="min-h-24">
                      <h3 className="items-cnter flex text-lg font-bold text-white lg:text-[22px]">
                        {item.title}
                      </h3>
                    </BubbleCard.Content>
                    <BubbleCard.Anchor href={item.url} />
                  </BubbleCard.Root>
                </SplideSlide>
              ))}
            </Splide>
          </div>
        </Container>
      </Section>
    </div>
  );
}
