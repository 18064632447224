import React from "react";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { arrowPath } from "@coninsa-s2/slider";

import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import Heading from "@coninsa-s2/heading";
import DialogBox from "@coninsa-s2/dialog-box";
import IconCard from "@coninsa-s2/icon-card";
import Shape from "@coninsa-s2/shape";
import Title from "@coninsa-s2/title";

import data from "./data";

export default function () {
  const options = {
    arrowPath,
    arrows: true,
    gap: 0,
    padding: { left: 0, right: "100px" },
    perPage: 4,
    perMove: 1,
    pagination: false,
    breakpoints: {
      640: {
        arrows: false,
        gap: 0,
        perPage: 1,
        padding: { left: 0, right: "1.5rem" },
        pagination: true,
      },
    },
  };

  return (
    <Section tint="dark-blue">
      <Container>
        <Heading as="h2" tint="white" size="5xl" className="text-center">
          <span className="block mb-2">Nuestros </span>
          <DialogBox tint="lime" width="medium">
            <Title size="5xl" className="is-highlighted !text-s2-dark-blue">
              diferenciales
            </Title>
          </DialogBox>
        </Heading>
        <div className="grid grid-cols-1">
          <Splide options={options} className="s2-slider s2-slider--lg">
            {data?.map((item) => (
              <SplideSlide key={item.id}>
                <div key={item.id} className="px-2 pt-2 h-full pb-4">
                  <IconCard compact>
                    <Shape>
                      <img src={item?.icon} alt="Icon" />
                    </Shape>
                    <p
                      className="s2-icon-card__text"
                      dangerouslySetInnerHTML={{ __html: item?.description }}
                    ></p>
                  </IconCard>
                </div>
              </SplideSlide>
            ))}
          </Splide>
        </div>
      </Container>
    </Section>
  );
}
