import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

import FloatingWspButton from "modules/home/components/FloatingWspButton"
import Layout from "../layout/default/Layout";

import Home from "@coninsa-site/home";

function HomePage({ data }) {
  return (
    <Layout
      headerAds={data?.drupal?.ads?.header}
      popupAds={data?.drupal?.ads?.popUp}
      footerAds={data?.drupal?.ads?.footer}
    >
      <FloatingWspButton />

      <Helmet>
        <title>¡Descubre tu inmueble ideal de vivienda o comercio!</title>
        <meta
          name="description"
          content="Encuentra apartamentos, casas, locales, bodegas y oficinas en venta y arriendo en Bogotá, Medellín, Barranquilla y otras ciudades de Colombia. Servicios de Arquitectura, Diseño, Construcción y Bienes Raíces"
        />
        <link rel="canonical" href="https://www.coninsa.co" />
      </Helmet>

      <Home.Alpha />
    </Layout>
  );
}

export const query = graphql`
  query HomePageQuery {
    drupal {
      ads: getAdsByUrl(path: "/") {
        header {
          id: nid
          image: fieldImage {
            url
          }
          url: fieldUrl {
            uri
            title
          }
        }
        popUp {
          id: nid
          image: fieldImage {
            url
          }
          url: fieldUrl {
            uri
            title
          }
        }
        footer {
          id: nid
          image: fieldImage {
            url
          }
          url: fieldUrl {
            uri
            title
          }
        }
      }
    }
  }
`;

export default HomePage;
