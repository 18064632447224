import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from 'theme-ui'

import { Modal } from 'ui'
import Title from "@coninsa-s2/title";

function AmenitiesTable({ commonAreas, ...props }) {
  const [open, setOpen] = useState(false)
  const handler = () => setOpen(true)
  const closeHandler = () => {
    setOpen(false)
  }

  const isMoreThanTwelve = commonAreas.length > 12
  const firstElevenCommonAreas = commonAreas.slice(0, 11)

  const commonAreasStyles = {
    display: 'grid',
    gridTemplateColumns: ['repeat(2, 1fr)', 'repeat(3, 1fr)'],
    columnGap: '1rem',
    rowGap: '.4rem'
  }

  const buttonStyles = {
    bg: 'transparent',
    border: '1px solid',
    borderColor: '#ccc',
    borderRadius: '6px',
    color: 'accent',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: [0, 1],
    px: 'small',
    py: '1rem',
    height: '25px',
    width: '100px',

    '&:hover': {
      borderColor: 'accent'
    }
  }

  return (
    <Box mb='medium' {...props}>
      <Title className="mb-6 !text-lg !font-normal" condensed tint='blue'>Amenidades</Title>
      <Box sx={commonAreasStyles}>
        {!isMoreThanTwelve &&
          commonAreas.map(area => (
            <co-featured-item
              icon-url={area?.entity?.icon?.entity?.url}
              label={area?.entity?.name}
            />
          ))}

        {isMoreThanTwelve && (
          <>
            {firstElevenCommonAreas.map(area => (
              <co-featured-item
                icon-url={area?.entity?.icon?.entity?.url}
                label={area?.entity?.name}
              />
            ))}
            <Box as='button' sx={buttonStyles} onClick={handler}>
              Ver más
            </Box>

            <Modal
              sx={{ width: ['100%', '70vw'] }}
              open={open}
              onClose={closeHandler}
              variant='fullScreen'
              showCloseControl
              color='accent'
              pr='1rem'
              pb='1rem'
            >
              <Modal.Content variant='fullScreen'>
                <outline-heading
                  slot='title'
                  level='h6'
                  level-style='normal'
                  class='font-display text-lg text-coninsa-blue-900'
                >
                  Amenidades
                </outline-heading>
                <Box sx={commonAreasStyles}>
                  {commonAreas.map(area => (
                    <co-featured-item
                      icon-url={area?.entity?.icon?.entity?.url}
                      label={area?.entity?.name}
                    />
                  ))}
                </Box>
              </Modal.Content>
            </Modal>
          </>
        )}
      </Box>
    </Box>
  )
}

AmenitiesTable.defaultProps = {
  commonAreas: []
}

AmenitiesTable.propTypes = {
  commonAreas: PropTypes.arrayOf({})
}

export default AmenitiesTable
