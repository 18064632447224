import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box } from "theme-ui";

import Button from "@coninsa-s2/button";
import BuildingContactForm from "./BuildingContactForm";
import { sendZohoInfo } from "../api";

import { Icon, Modal } from "ui";
import {
  getNormalizerServiceType,
  getNormalizeRegionPhone,
} from "modules/search-building/utils";

function BuildingFloatingBar({
  canon,
  code,
  path,
  region,
  serviceType,
  propertyType,
  title,
}) {
  const [expanded, setExpanded] = useState(false);
  const [displayedForm, setDisplayedForm] = useState(false);

  const [state, setState] = useState(false);
  const handler = () => setState(true);
  const closeHandler = () => {
    setState(false);
  };

  const phoneAR = `3014726883`;
  const phoneVE = `3014726883`;
  const service = getNormalizerServiceType(serviceType);
  const regionPhone = getNormalizeRegionPhone(region);
  const phone = service === "AR" ? phoneAR : phoneVE;
  const whatsappPath = `https://api.whatsapp.com/send?phone=57${phone}&text=Hola! Los vi en coninsa.co, me gustaría obtener más información. Inmueble: ${code}`;

  const baseStyles = {
    bg: "white",
    display: "flex",
    gap: "medium",
    justifyContent: ["space-between", "space-evenly"],
    alignItems: ["flex-start", "center"],
    boxShadow: "0px -3px 6px #00000029",
    opacity: "1",
    px: ["medium", "large"],
    py: "18px",
    borderRadius: "10px 10px 0px 0px",
  };

  function handleDropshadowClick(event) {
    // Hide the dropshadow when the user clicks outside of it.
    if (event.target.classList.contains("s2-dropshadow")) {
      setExpanded(false);
    }
  }

  return (
    <div>
      {expanded && (
        <div
          className="s2-dropshadow fixed inset-0 z-50 h-full bg-[#00000080]"
          onClick={handleDropshadowClick}
        >
          <div className="absolute bottom-0 w-full rounded-tl-xl rounded-tr-xl bg-white p-4">
            <div className="mb-6 text-center text-sm font-bold">
              Más opciones
            </div>

            <div className="flex flex-col gap-4">
              {/* <Button
                href="/estudio-digital"
                target="_blank"
                variant="ghost"
                size="fluid"
              >
                <Icon name="fa-reg-file" />
                Iniciar mi estudio digital
              </Button> */}

              <Button
                size="fluid"
                inverted
                onClick={() => setDisplayedForm(!displayedForm)}
              >
                <Icon name="fa-calendar" />
                Agendar una cita
              </Button>

              {displayedForm && (
                <BuildingContactForm
                  code={code}
                  canon={canon}
                  path={path}
                  propertyType={propertyType}
                  region={region}
                  serviceType={serviceType}
                  onSubmitForm={sendZohoInfo}
                  redirectType="THANKS_PAGE"
                />
              )}
            </div>
          </div>
        </div>
      )}

      <div className="fixed bottom-0 left-0 right-0 z-40 block md:hidden">
        <Box sx={baseStyles}>
          <Button
            className="!text-base"
            size="fluid"
            tint="green"
            onClick={handler}
          >
            <Icon name="fa-whatsapp" />
            Contactar a un asesor
          </Button>

          <button
            className="text-3xl font-bold text-coninsa-blue-900"
            aria-label="Más opciones"
            onClick={() => setExpanded(true)}
          >
            ...
          </button>
        </Box>
      </div>

      <div className="fixed bottom-0 left-0 right-0 z-20 hidden md:block">
        <Box sx={baseStyles}>
          <div className="font-display text-[22px] font-medium text-coninsa-blue-900">
            {title}
          </div>
          <div className="flex items-center gap-1">
            <Icon name="bi-wallet" sx={{ fontSize: 5 }} />
            <div className="flex items-end">
              <div className="font-display text-2xl font-medium">
                ${parseInt(canon).toLocaleString("es-CO")}
              </div>
              <span className="font-display font-medium text-gray-400">
                /mes
              </span>
            </div>
          </div>

          <Button onClick={handler} tint="green">
            <Icon name="fa-whatsapp" />
            Contactar a un asesor
          </Button>
        </Box>
      </div>

      <Modal
        sx={{ width: ["100%", "500px"] }}
        open={state}
        onClose={closeHandler}
        variant="fullDark"
        showCloseControl={true}
      >
        <Modal.Content variant="fullDark">
          <div className="bg-s2-white p-8">
            <BuildingContactForm
              bg="green"
              iconName="fa-whatsapp"
              buttonLabel="Contactar a un asesor"
              code={code}
              canon={canon}
              path={path}
              propertyType={propertyType}
              region={region}
              serviceType={serviceType}
              onSubmitForm={sendZohoInfo}
              redirectType="WHATSAPP_PAGE"
            />
          </div>
        </Modal.Content>
      </Modal>
    </div>
  );
}

BuildingFloatingBar.propTypes = {
  canon: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  region: PropTypes.string.isRequired,
  serviceType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default BuildingFloatingBar;
